const { uid } = require("../lib/id");

class Base {
  constructor(type, document) {
    this.id = document?.id || uid();
    this._loading = document?._loading;
    this.version = document?.version;
    this["$type"] = type;
    this.attachments = document?.attachments;
    if (!document) this.updateFromDoc(document);
  }

  asDocument() {
    const json = JSON.parse(JSON.stringify(this));
    return json;
  }

  updateFromDoc(document) {
    Object.keys(document).forEach((key) => (this[key] = Base.buildObject(document[key])));
  }
}

Base.types = {};

Base.registerType = (type, Class) => {
  Base.types[type] = Class;
};

Base.buildObject = (document) => {
  if (Array.isArray(document)) return document.map((item) => Base.buildObject(item));
  else if (typeof document === "object" && document["$type"]) return new Base.types[document["$type"]](document);
  else return document;
};

module.exports = Base;
