import { Common, Label, Checkbox, ModalBody, Input } from "@lainco/react-toolbox";
import styled from "styled-components";
import React from "react";
import produce from "immer";
import { withCrudModal } from "../components/Modal";
import User from "../model/User";

export const roles = ["Administrator", "Manager", "Administrative", "Account Manager", "Project Leader"];
export const areas = ["ADM", "DEV-ARG", "DEV-US", "SOP-ARG", "SOP-US", "COM-ARG", "COM-US", "RRHH"];
export const labs = ["Quotations", "Cashflow"];

export function PureUserModalContent({ model, onChange }) {
  return (
    <ModalBody>
      <Common.Col rows="auto auto auto auto" gap="1em">
        <Common.Row cols="2fr 1fr 2em 1fr" gap="1em">
          <Label text="Name">{model.name}</Label>
          <Label text="Initials">
            <Input value={model.initials} onChange={(x) => onChange({ ...model, initials: x })} />
          </Label>
          <div />
          <Label text="Id">{model.id}</Label>
        </Common.Row>
        <Common.Row cols="2fr 1fr 2em 1fr" gap="1em">
          <Label text="Mail">{model.mail}</Label>
          <Label text={<span>&nbsp;</span>}>
            <Checkbox
              text="enabled"
              type="checkbox"
              checked={model.enabled}
              onChange={(x) => onChange && onChange({ ...model, enabled: x })}
            />
          </Label>
        </Common.Row>
        <Label text="Roles" display="block">
          {roles.map((x, i) => (
            <Chip
              key={i}
              selected={model.roles.indexOf(x) !== -1}
              onClick={() => {
                onChange &&
                  onChange({
                    ...model,
                    roles: produce(model.roles, (draft) => {
                      if (draft.indexOf(x) !== -1) draft.splice(draft.indexOf(x), 1);
                      else draft.push(x);
                    }),
                  });
              }}
            >
              {x}
            </Chip>
          ))}
        </Label>
        <Label text="Areas" display="block">
          {areas.map((x, i) => (
            <Chip
              key={i}
              selected={model.managerOf.indexOf(x) !== -1}
              onClick={() => {
                onChange &&
                  onChange({
                    ...model,
                    managerOf: produce(model.managerOf, (draft) => {
                      if (draft.indexOf(x) !== -1) draft.splice(draft.indexOf(x), 1);
                      else draft.push(x);
                    }),
                  });
              }}
            >
              {x}
            </Chip>
          ))}
        </Label>
        <Label text="Labs features" display="block">
          {labs.map((x, i) => (
            <Chip
              key={i}
              selected={model.labs && model.labs.indexOf(x) !== -1}
              onClick={() => {
                onChange &&
                  onChange({
                    ...model,
                    labs: produce(model.labs, (draft) => {
                      if (draft.indexOf(x) !== -1) draft.splice(draft.indexOf(x), 1);
                      else draft.push(x);
                    }),
                  });
              }}
            >
              {x}
            </Chip>
          ))}
        </Label>
      </Common.Col>
    </ModalBody>
  );
}

export default withCrudModal(PureUserModalContent, User, "lg", false);

const Chip = styled.div`
  display: inline-block;
  font-size: 0.6em;
  padding: 0.5em 1em;
  background: ${(props) => (props.selected ? "#8bc" : "#ccc")};
  color: ${(props) => (props.selected ? "#042" : "#999")};
  border-radius: 2em;
  margin-right: 0.5em;
  cursor: pointer;
`;
