import styled from "styled-components";

export const Section = ({ title, children }) => (
  <Frame>
    <Title>{title}</Title>
    {children}
  </Frame>
);

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  color: #555;
`;

const Frame = styled.div`
  padding: 1em;
  background: #f4f4f4;
  border-radius: 0.5em;
  display: grid;
  gap: 1em;
`;
