import BrowserPage from "../components/v2/BrowserPage";
import Quotation from "../presentation/Quotation";
import QuotationModal from "../modals/QuotationModal";
import { quotation } from "../model/status";
import Filter, { ButtonOptionFilter, SelectOptionFilter, TextFilter } from "../components/v2/Filter";
import { buildOptions } from "../lib/misc";

const searchText = (pattern, item) => {
  return pattern == null || pattern.trim() === "" || item.quotationId?.includes(pattern) || item.name?.includes(pattern);
};

export const QuotationsPage = () => {
  const mainStatus = buildOptions(["name"], ["In Pogress", "Closed", "All"]);

  return (
    <BrowserPage
      type={Quotation}
      Modal={QuotationModal}
      columns={(filter) => Quotation.metadata.columns}
      ninWidth="65em"
      canAdd={true}
      canEdit={true}
      itemsFilter={(x) => true}
      initialFilterState={{ text: null, "main-status": 1 }}
      filterFunction={(filter, item) => {
        return (
          searchText(filter.text, item) &&
          ((filter["main-status"] === 1 && !quotation[item.status].closed) ||
            (filter["main-status"] === 2 && quotation[item.status].closed) ||
            filter["main-status"] === 3)
        );
      }}
      Filter={
        <Filter fontSize="0.5em">
          <TextFilter header="Search" name="text" width="100%" />
          <ButtonOptionFilter header="Status" name="main-status" options={mainStatus} />
          <div />
        </Filter>
      }
      projectorsContext={{}}
    />
  );
};
