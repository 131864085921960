import { merge } from "../lib/misc";
import SellOrder from "../model/SellOrder";
import SellOrderMilestone from "../model/SellOrderMilestone";
import { addPresentation } from "./presentation";
import { hasRole } from "./User";

export default addPresentation(SellOrderMilestone, {
  canAdd: false,
  canEdit: true,
  canRemove: false,
  columns: [
    { title: "Customer", content: { name: "sellOrder.customer.code" } },
    {
      title: "Company",
      content: { projectors: (x) => merge(x?.sellOrder?.company?.code, x?.sellOrder?.beneficiary?.code) },
    },
    { title: "Beneficiary", content: { name: "sellOrder.beneficiary.code" } },
    { title: "Sell Order", content: { name: "sellOrder.name" } },
    { title: "Milestone", content: { name: "name" } },
    {
      title: "Status",
      align: "center",
      content: {
        projectors: (x) => x.getStatus(),
      },
    },
    { title: "Amount", align: "right", content: { projectors: (x) => x.getShownAmount() } },
  ],
  actions: [{ name: "Create & New", applies: (model, isNew) => isNew, code: "createAndNew" }],
  afterGet: (m) => {
    m.sellOrder = new SellOrder(m.sellOrder);
    return m;
  },
});

export const requiredAdminActions = (milestone, user) => {
  const actions = [];
  if (hasRole(user, "Administrative")) {
    if (milestone.status === "ok") actions.push("Do Invoice");
    else if (milestone.status === "pending" && !milestone?.sellOrder?.milestonesRequireOk) actions.push("Get Approval");
    else if (milestone?.status === "invoice" && milestone?.getNextDuedateDelta() > 1) actions.push("Review settlement Delay");
  }
  return actions;
};

export const requiredAccountManagerActions = (milestone, user) =>
  hasRole(user, "Account Manager") && milestone?.sellOrder?.accountManager.id === user.id && milestone?.isAdjustmentPending
    ? ["Negotiate Adjustment"]
    : [];

export const requiredProjectLeaderActions = (milestone, user) =>
  hasRole(user, "Project Leader") &&
  milestone?.sellOrder?.projectLeader?.id === user.id &&
  milestone?.status === "pending" &&
  milestone?.sellOrder?.milestonesRequireOk
    ? ["Get Approval"]
    : [];

export const requiredActions = (milestone, user) => [
  ...requiredAdminActions(milestone, user),
  ...requiredAccountManagerActions(milestone, user),
  ...requiredProjectLeaderActions(milestone, user),
];

export const isResponsible = (milestone, user) => requiredActions(milestone, user).length > 0;
