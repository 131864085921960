import { useState } from "react";
import { ModalBody, Common, Label, Button } from "@lainco/react-toolbox";
import { Box } from "@lainco/react-toolbox/dist/components/Select.styled";
import { orderBy } from "lodash";
import styled from "styled-components";
import { withModal } from "../components/Modal";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { icons } from "@lainco/react-toolbox/dist/lib/Icons";

const equals = (a, b) => a != null && b != null && a.length === b.length && a.every((x, i) => x === b[i]);

export const getNameOfField = (field, fields) => fields.find((x) => x.code === field).name;
export const getNameOfModel = (model, fields) => model.map((x) => getNameOfField(x, fields)).join(", ");

const DragHandle = sortableHandle(() => <Dragable>☰</Dragable>);

const SortableItem = sortableElement(({ value, fields, onRemove }) => (
  <Field key={value} className="handle" selected>
    <DragHandle />
    {getNameOfField(value, fields)}
    <Close onClick={() => onRemove?.()}>x</Close>
  </Field>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <FieldsContainer>{children}</FieldsContainer>;
});

const available = (availableFields, fields, onAdd) => (
  <Common.Col rows="auto" gap="0.6em">
    {availableFields.map((x) => (
      <Link onClick={() => onAdd?.(x)}>{getNameOfField(x, fields)}</Link>
    ))}
  </Common.Col>
);

export function PureGroupByModal({ model, favourites, fields, onChange, onFavouritesChange }) {
  const [expanded, setExpanded] = useState(false);

  const availableFields = orderBy(
    fields.filter((x) => !model.includes(x.code)),
    "name"
  ).map((x) => x.code);

  return (
    <ModalBody>
      <Common.Row cols="2fr 3fr" gap="4em">
        <Common.Col rows="auto 1fr" gap="1.5em">
          <Title>Fields</Title>
          <Label
            text={
              <div>
                <Link onClick={() => setExpanded(true)}>Add Field</Link>
                {expanded && (
                  <StyledBox onRequestClose={(x) => setExpanded(false)} padding={0.1} overlay="littleBlack">
                    <List>
                      {available(availableFields, fields, (x) => {
                        setExpanded(false);
                        onChange?.([...model, x]);
                      })}
                    </List>
                  </StyledBox>
                )}
              </div>
            }
            align="start"
          >
            <Common.Col rows="auto auto" gap="1em">
              <Common.Col rows="auto" gap="0.3em">
                <SortableContainer
                  onSortEnd={({ oldIndex, newIndex }) => onChange?.(arrayMoveImmutable(model, oldIndex, newIndex))}
                  useDragHandle
                >
                  {model.map((value, index) => (
                    <SortableItem
                      key={`item-${value}`}
                      index={index}
                      value={value}
                      fields={fields}
                      onRemove={() => onChange?.(model.filter((f) => f !== value))}
                    />
                  ))}
                </SortableContainer>
              </Common.Col>
              <Button
                disabled={model.length === 0 || true}
                onClick={() => {
                  if (favourites.find((f) => equals(f, model))) return;
                  onFavouritesChange?.([...favourites, model]);
                }}
              >
                Add to Favourites
              </Button>
            </Common.Col>
          </Label>
        </Common.Col>
        <Label text="Favourites" align="start">
          <FavoritesContaier>
            {favourites.map((x) => (
              <Favorite key={x.join("-")} selected={equals(model, x)} onClick={() => onChange(x)}>
                <Star selected={equals(model, x)}>{icons.solid.star()}</Star>
                {getNameOfModel(x, fields)}
                <Close onClick={() => onFavouritesChange?.(favourites.filter((f) => !equals(f, x)))}>x</Close>
              </Favorite>
            ))}
          </FavoritesContaier>
        </Label>
      </Common.Row>
    </ModalBody>
  );
}

export default withModal(PureGroupByModal, "Group By", "Select", "lg");

const Title = styled.div`
  font-size: 1.4em;
`;

const Field = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1em;
  padding: 0.4em 1em;
  border: 1px solid #ccc;
  background: white;
  margin-bottom: -1px;
  align-items: baseline;
  cursor: default;
`;

const Dragable = styled.span`
  font-size: 1.2em;
  cursor: grab;
`;

const Close = styled.div`
  cursor: pointer;
  align-self: center;
`;

const FieldsContainer = styled.div``;

const Link = styled(Common.Link)`
  &&&& {
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 0;
  }
`;

const StyledBox = styled(Box)`
  padding: 0.5em;
`;

const List = styled.div`
  padding: 0.7em;
`;

const FavoritesContaier = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
`;

const Favorite = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  font-size: 0.8em;
  color: ${(props) => (props.selected ? "#000" : "#777")};
  gap: 0.4em;
  padding: 0.4em 0.8em;
  cursor: pointer;
  width: fit-content;
  align-items: baseline;
`;

const Star = styled.div`
  color: ${(props) => (props.selected ? "#fd0" : "#ccc")};
`;
