import styled from "styled-components";
import { Common } from "@lainco/react-toolbox";
import { addComment, buildComment, ListOfComments } from "../components/Comments";
import { useUser } from "../lib/userContext";

export function QuotationModalActions({ model, onChange }) {
  const user = useUser();

  return (
    <Common.Col rows={`repeat(${model?.comments?.length + 1}, auto) 1fr`} gap="1em">
      <Common.Row cols="auto auto 1fr" gap="1em" alignItems="baseline">
        ACTIONS
        <Link
          onClick={(x) =>
            onChange((m) => ({
              ...m,
              comments: addComment(m.comments, { ...buildComment({ user, text: "" }), isNew: true }),
            }))
          }
        >
          ADD
        </Link>
      </Common.Row>
      <ListOfComments
        model={model}
        onChange={(x) => {
          onChange(x);
        }}
      />
      <div />
    </Common.Col>
  );
}

const Link = styled(Common.Link)`
  &&& {
    margin-right: 30px;
    font-size: 0.7em;
    text-transform: uppercase;
  }
`;
