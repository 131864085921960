const buildType = require("./models");
const dateFns = require("date-fns");
const statuses = require("./status").sellOrderMilestone;
const { uid } = require("../lib/id");
const { capitalizeFirstLetter } = require("../lib/misc");

const toDate = (date) => {
  if (!date) return undefined;
  else if (typeof date === "string") return dateFns.parseISO(date);
  else return date;
};

const SellOrderMilestone = buildType({
  initializer: (model, document) => {
    model.name = document?.name;
    model.detail = document?.detail;
    model.sellOrder = document?.sellOrder;
    model.definedPercentage = document?.definedPercentage;
    model.definedAmount = document?.definedAmount;
    model.ok = toDate(document?.ok);
    model.okBackup = toDate(document?.okBackup);
    model.invoiceNumber = document?.invoiceNumber;
    model.invoiceNumberBackup = document?.invoiceNumberBackup;
    model.invoiceDate = toDate(document?.invoiceDate);
    model.invoiceDateBackup = toDate(document?.invoiceDateBackup);
    model.invoiceText = document?.invoiceText;
    model.invoiceTextBackup = document?.invoiceTextBackup;
    model.settlementDate = toDate(document?.settlementDate);
    model.settlementDateBackup = toDate(document?.settlementDateBackup);
    model.expectedOk = toDate(document?.expectedOk);
    model.daysToInvoice = document?.daysToInvoice;
    model.daysToSettlement = document?.daysToSettlement;
    model.status = document?.status || "pending";
    model.hasFlag = document?.hasFlag;
    model.flagReasson = document?.flagReasson;
    model.isAdjustmentPending = document?.isAdjustmentPending;
    model.isDeleted = document?.isDeleted || false;
    model.comments = document?.comments || [];
    model.followUp = document?.followUp ? { ...document.followUp } : { hasFollowup: false };
    if (model.followUp?.dueDate) {
      model.followUp.dueDate = toDate(model.followUp.dueDate);
    }
  },
  code: "sellOrderMilestones",
  singular: "Invoice",
  plural: "Invoices",
  sufix: "all",
  save: async (sellOrderMilestone, create, update) => {
    const clone = {
      ...sellOrderMilestone,
      sellOrder: sellOrderMilestone.sellOrder.id,
    };
    if (!(await update("sellOrderMilestones", clone))) {
      await create("sellOrders", clone);
    }
  },
  afterLoad: async (m, get) => {
    const sellOrder = await get("sellOrders", m.sellOrder);
    m.sellOrder = sellOrder;
    return m;
  },
});

// Amount Related
SellOrderMilestone.prototype.getCurrency = function () {
  return this.sellOrder.amount.currency.symbol;
};
SellOrderMilestone.prototype.getAmount = function () {
  return !isNaN(parseFloat(this.definedAmount))
    ? parseFloat(this.definedAmount)
    : !isNaN(parseFloat(this.definedPercentage)) && !isNaN(parseFloat(this.sellOrder.amount.amount))
    ? (parseFloat(this.definedPercentage) / 100) * parseFloat(this.sellOrder.amount.amount)
    : undefined;
};
SellOrderMilestone.prototype.getShownAmount = function () {
  return this.getAmount() ? `${this.getCurrency()} ${this.getAmount().toLocaleString()}` : undefined;
};

const swapValue = (milestone, field, visible) => {
  const source = visible ? `${field}Backup` : field;
  const dest = visible ? field : `${field}Backup`;

  if (milestone[dest] != null) {
    throw new Error(`Cannot swap ${field} because ${dest} is not null: ${milestone[dest]}`);
  }

  milestone[dest] = milestone[source];
  milestone[source] = null;
};

SellOrderMilestone.prototype.setStatus = function (status) {
  const previousStatus = statuses[this.status];
  const newStatus = statuses[status];
  this.status = status;
  if (previousStatus != null && newStatus != null) {
    if (previousStatus.showInvoice !== newStatus.showInvoice) {
      swapValue(this, "invoiceNumber", newStatus.showInvoice);
      swapValue(this, "invoiceDate", newStatus.showInvoice);
      swapValue(this, "invoiceText", newStatus.showInvoice);
    }
    if (previousStatus.showMilestone !== newStatus.showMilestone) swapValue(this, "ok", newStatus.showMilestone);

    if (previousStatus.showSettle !== newStatus.showSettle) swapValue(this, "settlementDate", newStatus.showSettle);
  }
};

// Expected Date Related
SellOrderMilestone.prototype.getExpectedInvoiceDate = function () {
  return isNaN(parseInt(this.daysToInvoice))
    ? undefined
    : this.ok
    ? dateFns.addDays(this.ok, parseInt(this.daysToInvoice))
    : this.expectedOk
    ? dateFns.addDays(this.expectedOk, parseInt(this.daysToInvoice))
    : undefined;
};

SellOrderMilestone.prototype.getAdjustment = function (limitDate, change) {
  const date = this.settlementDate || this.getExpectedSettlementDate();
  return isNaN(change) || !date || !limitDate || date < limitDate ? undefined : this.getAmount() * change;
};

SellOrderMilestone.prototype.getExpectedSettlementDate = function () {
  const days = isNaN(parseInt(this.daysToSettlement)) ? 30 : parseInt(this.daysToSettlement);
  if (this.settlementDate) return this.settlementDate;
  else if (this.invoiceDate) return dateFns.addDays(this.invoiceDate, days);
  else if (this.getExpectedInvoiceDate()) {
    return dateFns.addDays(this.getExpectedInvoiceDate(), days);
  } else if (this.ok) return dateFns.addDays(this.ok, days + 7);
  else if (this.expectedOk) return dateFns.addDays(this.ok, days + 7);
  else return null;
};

const delta = (real, expected) => {
  const _real = real || dateFns.startOfToday();
  return _real && expected ? dateFns.differenceInDays(_real, expected) : undefined;
};

const delay = (real, expected) => {
  const diff = delta(real, expected);
  return diff > 0 ? diff : undefined;
};
SellOrderMilestone.prototype.getMilestoneDelay = function () {
  return delay(this.ok, this.expectedOk);
};

SellOrderMilestone.prototype.getInvoiceDelay = function () {
  return delay(this.invoiceDate, this.getExpectedInvoiceDate());
};

SellOrderMilestone.prototype.getSettlementDelay = function () {
  return delay(this.settlementDate, this.getExpectedSettlementDate());
};

SellOrderMilestone.prototype.getMilestoneDelta = function () {
  return delta(this.ok, this.expectedOk);
};

SellOrderMilestone.prototype.getInvoiceDelta = function () {
  return delta(this.invoiceDate, this.getExpectedInvoiceDate());
};

SellOrderMilestone.prototype.getSettlementDelta = function () {
  return delta(this.settlementDate, this.getExpectedSettlementDate());
};

SellOrderMilestone.prototype.getNextExpectedDate = function () {
  if (this.status === "pending") return this.expectedOk;
  else if (this.status === "ok") return this.getExpectedInvoiceDate();
  else if (this.status === "invoiced") return this.getExpectedSettlementDate();
  else return undefined;
};

SellOrderMilestone.prototype.getNextDuedate = function () {
  const s = statuses[this.status];
  return !s
    ? undefined
    : s.showCancel
    ? undefined
    : s.showInvoice && !this.settlementDate
    ? this.getExpectedSettlementDate()
    : s.showMilestone && !this.invoiceDate
    ? this.ok
    : !this.ok && this.expectedOk
    ? this.expectedOk
    : undefined;
};

SellOrderMilestone.prototype.getLastDate = function () {
  const s = statuses[this.status];
  return !s
    ? undefined
    : s.showInvoice && !this.settlementDate
    ? this.invoiceDate
    : s.showMilestone && !this.invoiceDate
    ? this.ok
    : !this.ok && this.expectedOk
    ? undefined
    : this.settlementDate;
};

SellOrderMilestone.prototype.getInboxDate = function () {
  return this.status === "settled"
    ? this.settlementDate
    : this.status === "invoice"
    ? this.getExpectedSettlementDate()
    : this.status === "ok"
    ? this.getExpectedInvoiceDate()
    : this.status === "pending"
    ? this.expectedOk
    : undefined;
};

SellOrderMilestone.prototype.getNextDuedateDelta = function () {
  const s = statuses[this.status];
  return !s
    ? undefined
    : s.showCancel
    ? undefined
    : s.showInvoice && !this.settlementDate
    ? this.getSettlementDelta()
    : s.showMilestone && !this.invoiceDate
    ? this.getInvoiceDelta()
    : !this.ok && this.expectedOk
    ? this.getMilestoneDelta()
    : undefined;
};

SellOrderMilestone.prototype.getStatusWarning = function () {
  const s = statuses[this.status];

  if (!s) {
    return {
      severity: "critical",
      message: "wrong status",
      code: "wrong-value",
      field: "status",
    };
  }
  if (s.showCancel) return undefined;

  if (s.showSettle && !this.settlementDate) {
    return {
      severity: "critical",
      message: "missing settlement date",
      code: "missing",
      field: "settlementDate",
    };
  }
  if (s.showInvoice && !this.invoiceDate) {
    return {
      severity: "critical",
      message: "missing invoice date",
      code: "missing",
      field: "invoiceDate",
    };
  }
  if (s.showMilestone && !this.ok) {
    return {
      severity: "critical",
      message: "missing milestone date",
      code: "missing",
      field: "ok",
    };
  }
  if (!this.expectedOk) {
    return {
      severity: "critical",
      message: "missing expected milestone date",
      code: "missing",
      field: "expectedOk",
    };
  }
  if (s.showInvoice && !this.settlementDate && this.getSettlementDelta() > 0) {
    return {
      severity: "error",
      message: "overdue",
      code: "overdue",
      field: "settlementDate",
    };
  }
  if (s.showMilestone && !this.invoiceDate && this.getInvoiceDelta() > 0) {
    return {
      severity: "error",
      message: "overdue",
      code: "overdue",
      field: "invoiceDate",
    };
  }
  if (!this.ok && this.expectedOk && this.getMilestoneDelta() > 0) {
    return {
      severity: "error",
      message: "overdue",
      code: "overdue",
      field: "ok",
    };
  }
  if (s.showInvoice && !this.invoiceNumber) {
    return {
      severity: "error",
      message: "missing invoice number",
      code: "missing",
      field: "invoiceNumber",
    };
  }
  if (s.showMilestone && !this.invoiceText) {
    return {
      severity: "error",
      message: "missing milestone comments",
      code: "missing",
      field: "invoiceText",
    };
  }
  if (s.showInvoice && !this.settlementDate && this.getSettlementDelta() > -4) {
    return {
      severity: "warning",
      message: "near due",
      code: "near-due",
      field: "settlementDate",
    };
  }
  if (s.showMilestone && !this.invoiceDate && this.getInvoiceDelta() > -4) {
    return {
      severity: "warning",
      message: "near due",
      code: "near-due",
      field: "invoiceDate",
    };
  }
  if (!this.ok && this.expectedOk && this.getMilestoneDelta() > -4) {
    return {
      severity: "warning",
      message: "near due",
      code: "near-due",
      field: "ok",
    };
  }
  return undefined;
};

SellOrderMilestone.prototype.isOverdue = function () {
  const warning = this.getStatusWarning();
  return warning && warning.code === "overdue";
};

SellOrderMilestone.prototype.getStatus = function () {
  const s = statuses[this.status];
  return !s ? undefined : s.name;
};

SellOrderMilestone.prototype.getFlags = function () {
  const flags = [
    this.getStatusWarning(),
    this.hasFlag && {
      code: "impediment",
      severity: "critical",
      message: "Impediment",
      reasson: this.flagReasson,
    },
    this.isAdjustmentPending && {
      code: "adjustment",
      severity: "error",
      message: "Adjustment",
      reasson: "Adjustment Clause Triggered",
    },
  ].filter((x) => x);
  return flags;
};

SellOrderMilestone.prototype.getRequiredAdminActionsFor = function (user) {
  const actions = [];
  if (user == null || user.hasRole("Administrative")) {
    if (this.status === "ok") actions.push("Do *Invoice*");
    else if (this.status === "pending" && !this.sellOrder?.milestonesRequireOk) {
      actions.push("Get *Approval*");
    } else if (this.status === "invoice" && this.getNextDuedateDelta() > 1) {
      actions.push("*Review settlement* Delay");
    } else if (this.status === "invoice") actions.push("Settlement");

    const validation = this.getStatusWarning();
    if (validation && validation.severity === "critical") {
      actions.push(`*${capitalizeFirstLetter(validation.message)}*`);
    }
  }
  return actions;
};

SellOrderMilestone.prototype.getRequiredProjectLeaderActionsFor = function (user) {
  return (user == null || user.hasRole("Project Leader")) &&
    (user == null || this.sellOrder?.projectLeader?.id === user.id) &&
    this.status === "pending" &&
    this.sellOrder?.milestonesRequireOk
    ? ["Get *Approval*"]
    : [];
};

SellOrderMilestone.prototype.getRequiredAccountManagerActionsFor = function (user) {
  return (user == null || user.hasRole("Account Manager")) &&
    (user == null || this.sellOrder?.accountManager.id === user.id) &&
    this.isAdjustmentPending
    ? ["Negotiate *Adjustment*"]
    : [];
};

SellOrderMilestone.prototype.getRequiredActions = function () {
  return [
    ...this.getRequiredAdminActionsFor(null).map((x) => ({
      role: { initials: "ADM" },
      action: x,
    })),
    ...this.getRequiredProjectLeaderActionsFor(null).map((x) => ({
      user: this.sellOrder.projectLeader,
      role: { initials: "P.L." },
      action: x,
    })),
  ];
};

SellOrderMilestone.prototype.getRequiredActionsFor = function (user) {
  return [
    ...this.getRequiredAdminActionsFor(user),
    ...this.getRequiredAccountManagerActionsFor(user),
    ...this.getRequiredProjectLeaderActionsFor(user),
  ];
};

SellOrderMilestone.prototype.hasPendingActionsFor = function (user) {
  return this.getRequiredActionsfunctionFor(user).length > 0;
};

SellOrderMilestone.prototype.getInboxItem = function () {
  const s = statuses[this.status];
  return {
    name: this.sellOrder.name,
    description: this.name,
    type: "Milestone",
    company: this.sellOrder.company,
    beneficiary: this.sellOrder.beneficiary,
    customer: this.sellOrder.customer,
    followUp: this.followUp,
    amount: {
      amount: this.getAmount(),
      currency: this.sellOrder?.amount?.currency,
    },
    actions: this.getRequiredActions().map((x) => ({
      user: x.user,
      role: x.role,
      dueDate: this.followUp?.hasFollowup ? null : this.getNextDuedate(),
      nextTask: x.action,
    })),
    due:
      this.followUp?.hasFollowup && this.followUp?.dueDate
        ? typeof this.followUp?.dueDate === "string"
          ? dateFns.parseISO(this.followUp?.dueDate)
          : this.followUp?.dueDate
        : this.getNextDuedate(),
    invoiceDate: this.getInboxDate(),
    url: "/inbox/sellOrderMilestones/" + this.id,
    number: this.invoiceNumber,
    flags: this.getFlags().filter((f) => f.code === "impediment" || f.code === "adjustment"),
    status: s.nextStatus ?? this.stats,
  };
};

SellOrderMilestone.prototype.isInInbox = function () {
  return this.followUp?.hasFollowup || this.getRequiredActions().length > 0;
};

SellOrderMilestone.buildPending = ({ sellOrder, name, amount, expectedOk }) =>
  new SellOrderMilestone({
    id: uid(),
    sellOrder,
    name,
    definedAmount: amount,
    expectedOk,
    status: "pending",
    daysToInvoice: 7,
    daysToSettlement: this.terms?.days || 30,
  });

SellOrderMilestone.buildWaitingInvoice = ({ sellOrder, name, amount, expectedOk, ok }) =>
  new SellOrderMilestone({
    id: uid(),
    name,
    definedAmount: amount,
    expectedOk,
    ok,
    status: "ok",
    daysToInvoice: 7,
    daysToSettlement: this.terms?.days || 30,
  });

SellOrderMilestone.buildWaitingSettlement = ({ sellOrder, name, amount, expectedOk, ok, invoiceDate, invoiceNumber }) =>
  new SellOrderMilestone({
    id: uid(),
    name,
    definedAmount: amount,
    expectedOk,
    ok,
    invoiceDate,
    invoiceNumber,
    status: "invoice",
    daysToInvoice: 7,
    daysToSettlement: this.terms?.days || 30,
  });

SellOrderMilestone.buildSettled = ({ sellOrder, name, amount, expectedOk, ok, invoiceDate, invoiceNumber, settlementDate }) =>
  new SellOrderMilestone({
    id: uid(),
    name,
    definedAmount: amount,
    expectedOk,
    ok,
    invoiceDate,
    invoiceNumber,
    settlementDate,
    status: "settled",
    daysToInvoice: 7,
    daysToSettlement: this.terms?.days || 30,
  });

SellOrderMilestone.buildCanceled = ({ sellOrder, name, amount, expectedOk, cancelDate }) =>
  new SellOrderMilestone({
    id: uid(),
    name,
    definedAmount: amount,
    expectedOk,
    cancelDate,
    status: "canceled",
    daysToInvoice: 7,
    daysToSettlement: this.terms?.days || 30,
  });

SellOrderMilestone.statuses = statuses;

module.exports = SellOrderMilestone;
