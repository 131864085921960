const { uid } = require("../lib/id");
const Base = require("./Base");
const buildType = require("./models");
const SellOrderMilestone = require("./SellOrderMilestone");
const dateFns = require("date-fns");
const statuses = require("./status").sellOrder;
const sum = require("lodash").sum;

const toDate = (date) => {
  if (!date) return undefined;
  else if (typeof date === "string") return dateFns.parseISO(date);
  else return date;
};

const FSellOrder = buildType({
  initializer: (model, document) => {
    model.name = document?.name;
    model.company = document?.company;
    model.beneficiary = document?.beneficiary;
    model.customer = document?.customer;
    model.category = document?.category;
    model.comercialPoc = document?.comercialPoc;
    model.projectLeader = document?.projectLeader;
    model.amount = document?.amount || { id: null, amount: null };
    model.terms = document?.terms;
    model.adjustmentClause = document?.adjustmentClause;
    model.adjustmentClauseFloor = document?.adjustmentClauseFloor;
    model.adjustmentClauseCap = document?.adjustmentClauseCap;
    model.adjustmentClauseReference = document?.adjustmentClauseReference;
    model.adjustmentTriggerDate = toDate(document?.adjustmentTriggerDate);
    model.adjustmentFormula = document?.adjustmentFormula;
    model.adjustmentCustomReference = document?.adjustmentCustomReference;
    model.customerId = document?.customerId;
    model.accountManager = document?.accountManager;
    model.projectLeader = document?.projectLeader;
    model.accountManagerOk = document?.accountManagerOk || false;
    model.projectLeaderOk = document?.projectLeaderOk || false;
    model.milestonesRequireOk = document?.milestonesRequireOk != null ? document?.milestonesRequireOk : true;
    model.status = document?.status || "draft";
    model.milestones = document?.milestones?.map((m) => new SellOrderMilestone({ ...m, sellOrder: model })) || [];
    model.comments = document?.comments || [];
    model.followUp = document?.followUp ? { ...document.followUp } : { hasFollowup: false };
    if (model.followUp?.dueDate) {
      model.followUp.dueDate = toDate(model.followUp.dueDate);
    }
  },
  code: "_sellOrders",
  singular: "Sell Order",
  plural: "Sell Orders",
  sufix: "all",
  save: async (sellOrder, create, update) => {
    const clone = { ...sellOrder };
    if (!clone.id) clone.id = uid();
    const milestones = clone.milestones.map((m) => ({
      ...m,
      sellOrder: clone.id,
    }));
    clone.milestones = clone.milestones.filter((m) => !m.isDeleted).map((m) => m.id);
    if (!(await update("sellOrders", clone))) await create("sellOrders", clone);
    for (const m of milestones) {
      if (!(await update("sellOrderMilestones", m))) {
        await create("sellOrderMilestones", m);
      }
    }
  },
  afterLoad: async (m, get) => {
    for (let i = 0; i < m.milestones.length; i++) {
      const milestone = await get("sellOrderMilestones", m.milestones[i]);
      m.milestones[i] = milestone;
    }
    return m;
  },
});

FSellOrder.statuses = statuses;

FSellOrder.prototype.asDocument = function () {
  const clone = { ...this };
  clone.milestones = clone.milestones.map((m) => ({ ...m, sellOrder: null }));
  const doc = Base.prototype.asDocument.bind(clone)();
  return doc;
};

FSellOrder.prototype.getTotalInvoiced = function () {
  return sum(this.milestones.filter((m) => m.status === "invoice").map((m) => m.getAmount()));
};

FSellOrder.prototype.getInvoicedMilestones = function () {
  const total = sum(this.milestones.filter((m) => m.status === "invoice").map((m) => m.getAmount()));
  if (!total) return "-";
  return `${this.amount?.currency?.symbol} ${total.toLocaleString()}`;
};

FSellOrder.prototype.getTotalPending = function () {
  return sum(this.milestones.filter((m) => m.status === "pending" || m.status === "ok").map((m) => m.getAmount()));
};

FSellOrder.prototype.getPendingMilestones = function () {
  const total = sum(this.milestones.filter((m) => m.status === "pending" || m.status === "ok").map((m) => m.getAmount()));
  if (!total) return "-";
  return `${this.amount?.currency?.symbol} ${total.toLocaleString()}`;
};

FSellOrder.prototype.getShownAmount = function () {
  return `${this.amount?.currency?.symbol} ${parseFloat(this.amount?.amount).toLocaleString()}`;
};

FSellOrder.prototype.getStatus = function () {
  return FSellOrder.statuses[this.status]?.name || "-";
};

FSellOrder.prototype.getShownAdjustementClause = function () {
  return this.adjustmentClause == null
    ? "-"
    : `${this.adjustmentClause.name} (${this.adjustmentClauseFloor || ""}${
        this.adjustmentClauseFloor && this.adjustmentClauseCap ? " - " : ""
      }${this.adjustmentClauseCap || ""})`;
};

FSellOrder.prototype.getRequiredAdminActionsFor = function (user) {
  const actions = [];
  if (user == null || user.hasRole("Administrative")) {
    if (this.status === "draft") actions.push("*Send to Approval*");
    if (this.status === "adminReview") {
      actions.push("*Review* and change to In Progress");
    } else if (this.status === "pendingApproval" && this.projectLeaderOk && this.accountManagerOk) {
      actions.push("*Review* and change to In Progress");
    } else if (
      this.status === "inProgress" &&
      this.milestones?.filter((m) => m.status !== "settled" && m.status !== "canceled")?.length === 0
    ) {
      actions.push("*Close/Cancel* sell order");
    }
  }
  return actions;
};

FSellOrder.prototype.getRequiredProjectLeaderActionsFor = function (user) {
  return (user == null || user.hasRole("Project Leader")) &&
    this.status === "pendingApproval" &&
    !this.projectLeaderOk &&
    (this.projectLeader == null || user == null || this.projectLeader?.id === user?.id)
    ? ["Review *milestones*"]
    : [];
};

FSellOrder.prototype.getRequiredAccountManagerActionsFor = function (user) {
  const actions = [];
  if (user == null || user.hasRole("Account Manager")) {
    if (
      this.status === "pendingApproval" &&
      !this.accountManagerOk &&
      (this.accountManager == null || user == null || this.accountManager?.id === user?.id)
    ) {
      actions.push("Review *Amount, Payment Terms & Adj Clause*");
    }
    if ((user == null || this.accountManager?.id === user?.id) && this.milestones?.filter((m) => m.isAdjustmentPending)?.length > 0) {
      actions.push("Negotiate *Adjustment*");
    }
  }
  return actions;
};

FSellOrder.prototype.getRequiredActions = function () {
  return [
    ...this.getRequiredAdminActionsFor(null).map((x) => ({
      role: { initials: "ADM" },
      action: x,
    })),
    ...this.getRequiredAccountManagerActionsFor(null).map((x) => ({
      user: this.accountManager,
      role: { initials: "A.M." },
      action: x,
    })),
    ...this.getRequiredProjectLeaderActionsFor(null).map((x) => ({
      user: this.projectLeader,
      role: { initials: "P.L." },
      action: x,
    })),
  ];
};

FSellOrder.prototype.getRequiredActionsFor = function (user) {
  return [
    ...this.getRequiredAdminActionsFor(user),
    ...this.getRequiredAccountManagerActionsFor(user),
    ...this.getRequiredProjectLeaderActionsFor(user),
  ];
};

FSellOrder.prototype.getInboxItem = function () {
  const s = statuses[this.status];
  return {
    name: null,
    description: this.name,
    type: "Sell Order",
    company: this.company,
    beneficiary: this.beneficiary,
    customer: this.customer,
    amount: this.amount,
    followUp: this.followUp,
    actions: this.getRequiredActions().map((x) => ({
      user: x.user,
      role: x.role,
      nextTask: x.action,
    })),
    due:
      this.followUp?.hasFollowup && this.followUp?.dueDate
        ? typeof this.followUp?.dueDate === "string"
          ? dateFns.parseISO(this.followUp?.dueDate)
          : this.followUp?.dueDate
        : dateFns.startOfToday(),
    url: "/inbox/sellOrders/" + this.id,
    number: this.customerId,
    status: s.name ?? this.stats,
  };
};

FSellOrder.prototype.hasPendingActionsFor = function (user) {
  return this.getRequiredActionsFor(user).length > 0;
};

FSellOrder.prototype.isInInbox = function () {
  return this.followUp?.hasFollowup || this.getRequiredActions().length > 0;
};

module.exports = FSellOrder;
