import Project from "../model/Project";
import { addPresentation } from "./presentation";

export default addPresentation(Project, {
  sort: (a, b) => a?.category?.name?.localeCompare(b?.category?.name) || a?.name?.localeCompare(b?.name),
  canAdd: true,
  canEdit: true,
  canRemove: false,
  singular: "Product",
  plural: "Products",
  columns: ["Code", "Name"],
});
