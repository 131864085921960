const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.name = document?.name;
    model.days = document?.days;
  },
  code: "terms",
  singular: "Payment Terms",
  plural: "Payment Terms",
});
