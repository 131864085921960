import axios from "axios";
import { useAuth } from "./auth";
import { invalidate } from "../app/candidatesSlice";
import { startGet, startPost, startPut, startDelete, get, startUpdateFollowUp } from "../app/browsersSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const baseUrl = process.env.REACT_APP_FUNCTIONS_URL;
const delay = { read: process.env.REACT_APP_READ_DELAY, write: process.env.REACT_APP_WRITE_DELAY };
const addDelay = (ms) => {
  return (x) => {
    return new Promise((resolve) => (ms ? setTimeout(() => resolve(x), parseFloat(ms)) : resolve(x)));
  };
};

const formatPhrase = (text) => text.replace(/^([A-Za-z])(.*)/gi, (x, p1, p2) => `${p1.toUpperCase()}${p2.toLowerCase()}`);

const execWithToast = (f, name, action) => {
  return toast.promise(f, {
    loading: formatPhrase(`${action}ing ${name}...`),
    success: formatPhrase(`${name} ${action}ed!`),
    error: formatPhrase(`Error ${action}ing ${name}!`),
  });
};

const RETRIES = 3;

const internalGet = (params, code, Type, auth, dispatch, callback, retries) => {
  console.log({ auth });
  auth?.getIdToken()?.then((token) => {
    console.log("Token", token);
  });
  auth
    ?.getIdToken()
    .then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      dispatch(startGet(code));
      axios
        .get(`${baseUrl}/api/${code}`, { headers, params })
        .then(addDelay(delay.read))
        .then((x) => {
          dispatch(get(code, x.data));
          return x.data.map((p) => {
            return Type != null ? new Type(Type.metadata.afterGet ? Type.metadata.afterGet({ ...p }) : p) : p;
          });
        })
        .then((x) => {
          return Type?.metadata?.sort ? x.sort(Type.metadata.sort) : x;
        })
        .then((x) => {
          if (retries < RETRIES) toast.success(`${Type.metadata.name.plural} succesfully reloaded`);
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          if (retries >= 0) {
            const time = Math.trunc(Math.random() * 10 + 2);
            setTimeout(() => internalGet(params, code, Type, auth, dispatch, callback, retries - 1), time * 1000);
            toast.error(`Error reading ${Type.metadata.name.plural}, will retry on ${time} seconds`);
          } else
            toast.error(
              `Error reading ${Type.metadata.name.plural}, maximum retries reached. Try relaoding the page in a couple of minutes`
            );
          callback && callback([]);
        });
    })
    .catch((error) => {
      console.error("error reading token", error);
    });
};

export function useGet(code, Type, _auth) {
  const auth = useAuth() || _auth;
  const dispatch = useDispatch();
  return (params, callback) => internalGet(params, code, Type, auth, dispatch, callback, RETRIES);
}
/* _auth se usa solo para obtener el usuario en el proceso de carga */
export function useGetById(code, Type, _auth) {
  const auth = useAuth() || _auth;
  return async (id, callback) => {
    try {
      const token = await auth?.getIdToken();
      const headers = auth && { Authorization: `Bearer ${token}` };
      const res = await axios.get(`${baseUrl}/api/${code}/${id}`, { headers });
      const model = Type != null ? new Type(res.data) : res.data;
      await callback?.(model);
      return model;
    } catch (reason) {
      console.error(reason);
      toast.error(`Error reading ${Type?.metadata?.name?.singular ?? code}`);
      await callback?.(null);
    }
  };
}

export function usePost(code, Type) {
  const auth = useAuth();
  const dispatch = useDispatch();
  return (payload, callback) => {
    auth?.getIdToken().then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      const document = new Type(payload).asDocument();
      dispatch(startPost(code, document));
      execWithToast(
        axios.post(`${baseUrl}/api/${code}`, document, { headers }).then(addDelay(delay.write)),
        Type.metadata.name.singular,
        "creat"
      )
        .then((x) => {
          dispatch(invalidate(code));
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          callback && callback(null);
        });
    });
  };
}

export function usePostAttachment(code) {
  const auth = useAuth();
  return (id, payload, callback) => {
    auth?.getIdToken().then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      axios
        .post(`${baseUrl}/api/${code}/${id}/attachments`, payload, { headers })
        .then(addDelay(delay.write))
        .then((x) => {
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          callback && callback(null);
        });
    });
  };
}

export function usePut(code, Type) {
  const auth = useAuth();
  const dispatch = useDispatch();
  return (id, payload, callback) => {
    auth?.getIdToken().then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      const document = new Type(payload).asDocument();
      dispatch(startPut(code, document));
      execWithToast(
        axios.put(`${baseUrl}/api/${code}/${id}`, document, { headers }).then(addDelay(delay.write)),
        Type.metadata.name.singular,
        "updat"
      )
        .then((x) => {
          dispatch(invalidate(code));
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          callback && callback(null);
        });
    });
  };
}

export function usePatch(code, Type, _auth) {
  const auth = useAuth() || _auth;
  return (id, payload, callback) => {
    auth?.getIdToken().then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      axios
        .patch(`${baseUrl}/api/${code}/${id}`, payload, { headers })
        .then(addDelay(delay.write))
        .then((x) => {
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          callback && callback(null);
        });
    });
  };
}

export function useDelete(code, Type) {
  const auth = useAuth();
  const dispatch = useDispatch();
  return (id, callback) => {
    auth?.getIdToken().then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      dispatch(startDelete(code, id));
      execWithToast(
        axios.delete(`${baseUrl}/api/${code}/${id}`, { headers }).then(addDelay(delay.write)),
        Type.metadata.name.singular,
        "delet"
      )
        .then((x) => {
          dispatch(invalidate(code));
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          callback && callback(null);
        });
    });
  };
}

export const useUpdateFollowUp = (callback) => {
  const auth = useAuth();
  const dispatch = useDispatch();

  return (id, type, body) => {
    const { nextEvent, ...followUp } = body;
    return auth?.getIdToken().then((token) => {
      const headers = auth && { Authorization: `Bearer ${token}` };
      dispatch(startUpdateFollowUp(type, id, followUp));
      axios
        .post(`${process.env.REACT_APP_FUNCTIONS_URL}/api/followups/${type}/${id}`, followUp, { headers })
        .then((x) => {
          dispatch(invalidate(type));
        })
        .then(addDelay(5000))
        .then((x) => {
          callback && callback(x);
        })
        .catch((reason) => {
          console.error(reason);
          callback && callback(null);
        });
    });
  };
};
