import { useEffect } from "react";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Base from "../model/Base";
import { useGet } from "../lib/services";

export const createCandidatesSlice = (initialState) =>
  createSlice({
    name: "candidates",
    initialState: initialState,
    reducers: {
      invalidate: (state, action) => {
        state[action.payload] = undefined;
      },
      startUpdate: (state, action) => {
        if (state[action.payload] === undefined) state[action.payload] = { loading: true, values: null };
        else state[action.payload].loading = true;
      },
      update: (state, action) => {
        state[action.payload.code].values = action.payload.values;
        state[action.payload.code].loading = false;
      },
    },
  });

export const candidatesSlice = createCandidatesSlice({});

export function useCandidates(code, auth) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.candidates[code]);
  const { values, loading } = state || { values: null, loading: true };
  const type = Base.types[code];
  const get = useGet(`${code}`, type, auth);

  useEffect(() => {
    if (type == null) console.warn(`useCandidates call for unregistered type ${code}`);

    if (state === undefined && type !== undefined) {
      dispatch(candidatesSlice.actions.startUpdate(code));
      get({}, (x) => {
        dispatch(candidatesSlice.actions.update({ code, values: x.map((v) => JSON.parse(JSON.stringify(v))) }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [values, loading];
}

export const { startUpdate, update, invalidate } = candidatesSlice.actions;

export default candidatesSlice.reducer;
