import Customer from "../model/Customer";
import { addPresentation } from "./presentation";

export default addPresentation(Customer, {
  canAdd: true,
  canEdit: true,
  canRemove: false,
  columns: [
    { title: "Code", width: "10%", align: "left", content: { name: "code" } },
    { title: "Short Name", width: "10%", align: "left", content: { name: "shortName" } },
    { title: "Name", width: "60%", align: "left", content: { name: "name" } },
    { title: "Country", width: "30%", align: "left", content: { name: "country.name" } },
  ],
});
