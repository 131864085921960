import Index from "../model/Index";
import { addPresentation } from "./presentation";

export default addPresentation(Index, {
  canAdd: true,
  canEdit: true,
  canRemove: true,
  singular: "Index",
  plural: "Indexes",
  columns: ["Code", "Name"],
});
