import { Common, Select, Label, Input, Checkbox } from "@lainco/react-toolbox";
import { useCandidates } from "../app/candidatesSlice";
import { QuotationId } from "../components/QuotationId";
import { useGetById } from "../lib/services";
import { Section } from "../components/Section";
import { formatPercentage, formatAmount } from "../lib/misc";

export function QuotationModalInfo({ model, onChange }) {
  const [customers, customersLoading] = useCandidates("customers");
  const [terms, termsLoading] = useCandidates("terms");
  const [currencies, currenciesLoading] = useCandidates("currencies");
  const [adjustmentClauses, adjustmentClausesLoading] = useCandidates("adjustmentTypes");

  const getNewId = useGetById("numerators", null, null);

  const createId = async () => {
    await getNewId("quotations", (x) => {
      onChange((m) => ({ ...m, quotationId: x.new_id }));
    });
  };

  return (
    <Common.Col rows="auto auto auto auto 1fr" gap="1em">
      <Common.Row cols="2fr 1fr" gap="2em">
        <Label text="Customer">
          <Select
            value={model.customer?.id}
            onChangeOption={(x) => {
              onChange((m) => ({ ...m, customer: x }));
            }}
            options={customers?.map((x) => ({ ...x, name: x.shortName || x.name })) || []}
            loading={customersLoading}
          />
        </Label>
        <QuotationId value={model.quotationId} onChange={(x) => onChange((m) => ({ ...m, quotationId: x }))} onCreate={createId} />
      </Common.Row>
      <Label text="Name">
        <Input value={model.name} onChange={(x) => onChange((m) => ({ ...m, name: x }))} />
      </Label>
      <Section title="Original Quote">
        <Common.Row cols="1fr 2fr" gap="1em">
          <Label text="Terms">
            <Select
              value={model.terms?.id}
              onChangeOption={(x) => onChange((m) => ({ ...m, terms: x }))}
              options={terms || []}
              loaidng={termsLoading}
            />
          </Label>
          <Label text="Amount">
            <Common.Row cols="14em 1fr" gap="1em">
              <Select
                value={model.amount?.currency?.id}
                showValue="shortName"
                onChangeOption={(x) => {
                  onChange((m) => ({ ...m, amount: { currency: x, amount: m.amount.amount } }));
                }}
                options={currencies?.map((x) => ({ ...x, shortName: x.symbol })) || []}
                loading={currenciesLoading}
              />
              <Input
                value={model.amount?.amount?.toString()}
                align="right"
                onChange={(x) => onChange((m) => ({ ...m, amount: { currency: m.amount.currency, amount: x } }))}
              />
            </Common.Row>
          </Label>
        </Common.Row>
        <Common.Row cols="1fr 2fr" gap="1em">
          <Label
            text={
              <div>
                Adjustment Clause
                <Common.Link
                  onClick={(x) =>
                    onChange({
                      ...model,
                      adjustmentClause: null,
                      adjustmentClauseCap: "",
                      adjustmentClauseFloor: "",
                      adjustmentClauseReference: "",
                    })
                  }
                >
                  Borrar
                </Common.Link>
              </div>
            }
          >
            <Select
              value={model.adjustmentClause?.id}
              onChangeOption={(x) => onChange((m) => ({ ...m, adjustmentClause: x }))}
              options={adjustmentClauses || []}
              loaidng={adjustmentClausesLoading}
            />
          </Label>
        </Common.Row>
      </Section>
      <Section title="Final Quote After Negotiation">
        <Common.Row cols="1fr 1fr 1fr 1fr" gap="1em" style={{ minHeight: "3.8em" }}>
          <Label text="">
            <Checkbox
              text="Amount"
              checked={model.hasAmountChanged}
              onChange={(x) =>
                onChange?.({
                  ...model,
                  hasAmountChanged: x,
                  amountChangeDiscount: null,
                  amountChangeNewAmount: null,
                  amountChangeFinalAmount: null,
                })
              }
            />
          </Label>
          {model.hasAmountChanged && (
            <>
              <Label text="Discount (%)">
                <Common.Row cols="1fr auto" alignItems="center" gap="0.5em">
                  <Input
                    value={model.amountChangeDiscount}
                    align="right"
                    onChange={(x) => onChange((m) => ({ ...m, amountChangeDiscount: x, amountChangeNewAmount: null }))}
                  />
                  <span>{"%"}</span>
                </Common.Row>
              </Label>
              <Label text="New Amount">
                <Common.Row cols="auto 1fr" alignItems="center" gap="0.5em">
                  <span>{model.amount?.currency?.symbol}</span>
                  <Input
                    value={model.amountChangeNewAmount}
                    align="right"
                    onChange={(x) => onChange((m) => ({ ...m, amountChangeDiscount: null, amountChangeNewAmount: x }))}
                  />
                </Common.Row>
              </Label>

              {model.amountChangeDiscount && (
                <Label text="Final Amount">
                  <span style={{ textAlign: "right" }}>
                    {model.amount?.currency?.symbol} {formatAmount((1 - Number(model.amountChangeDiscount) / 100) * model.amount?.amount)}
                  </span>
                </Label>
              )}
              {model.amountChangeNewAmount && (
                <Label text="Final Amount">
                  <span style={{ textAlign: "right" }}>
                    {model.amount?.amount
                      ? formatPercentage((1 - Number(model.amountChangeNewAmount) / Number(model.amount?.amount)) * 100)
                      : "-"}
                  </span>
                </Label>
              )}
            </>
          )}
        </Common.Row>
        <Common.Row cols="1fr 1fr 1fr 1fr" gap="1em" alignItems="center" style={{ minHeight: "3.8em" }}>
          <Label text="">
            <Checkbox
              text="Terms"
              checked={model.hasTermsChanged}
              onChange={(x) => onChange?.({ ...model, hasTermsChanged: x, termsChangeNewTerms: model.terms })}
            />
          </Label>
          <Label text="">
            {model.hasTermsChanged && (
              <Select
                value={model.termsChangeNewTerms?.id}
                onChangeOption={(x) => onChange((m) => ({ ...m, termsChangeNewTerms: x }))}
                options={terms || []}
                loaidng={termsLoading}
              />
            )}
          </Label>
        </Common.Row>
      </Section>
    </Common.Col>
  );
}
