const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.code = document?.code ?? "";
    model.name = document?.name;
  },
  code: "projects",
  singular: "Product",
  plural: "Products",
});
