import { useMemo } from "react";
import { parseISO } from "date-fns";
import { formatDate, formatMonth } from "../lib/misc";
import IndexValue from "../model/IndexValue";
import { addPresentation } from "./presentation";
import { useCandidates } from "../app/candidatesSlice";

export default addPresentation(IndexValue, {
  canAdd: false,
  canEdit: false,
  canRemove: false,
  columns: {
    date: {
      title: "Date",
      width: "30%",
      content: {
        projectors: (x) =>
          x?.id.split("#")[0] === "IPC" ? formatMonth(x?.date && parseISO(x.date)) : formatDate(x?.date && parseISO(x.date)),
      },
    },
    index: {
      title: "Index",
      width: "30%",
      content: { projectors: (x) => x.id.split("#")[0] },
    },
    value: {
      title: "Value",
      width: "30%",
      align: "right",
      content: { name: "value" },
    },
  },
});

export const getIndexValue = (indexes, index, date) => {
  const value = index == null ? null : indexes?.find((p) => p.index === index.id && (date == null || date <= p.date));

  return [value?.value, value?.date];
};

export function useIndexValue(index, date) {
  const [indexValues, indexValuesLoading] = useCandidates("indexValues");
  const [indexValue, indexValueDate] = useMemo(() => getIndexValue(indexValues, index, date), [indexValues, index, date]);

  return [indexValue, indexValuesLoading, indexValueDate];
}
