const buildType = require("./models");
const statuses = require("./status").quotation;
const dateFns = require("date-fns");

const toDate = (date) => {
  if (!date) return undefined;
  else if (typeof date === "string") return dateFns.parseISO(date);
  else return date;
};

const Quotation = buildType({
  initializer: (model, document) => {
    model.quotationId = document?.quotationId;
    model.customer = document?.customer;
    model.category = document?.category;
    model.project = document?.project;
    model.company = document?.company;
    model.beneficiary = document?.beneficiary;
    model.accountManager = document?.accountManager;
    model.projectLeader = document?.projectLeader;
    model.accountManagerOk = document?.accountManagerOk || false;
    model.projectLeaderOk = document?.projectLeaderOk || false;
    model.amount = document?.amount || { id: null, amount: null };
    model.terms = document?.terms;
    model.adjustmentClause = document?.adjustmentClause;
    model.name = document?.name;
    model.status = document?.status || "research";
    model.comments = document?.comments || [];
    model.followUp = document?.followUp ? { ...document.followUp } : { hasFollowup: false };
    if (model.followUp?.dueDate) {
      model.followUp.dueDate = toDate(model.followUp.dueDate);
    }
    model.hasAmountChanged = document?.hasAmountChanged || false;
    model.amountChangeDiscount = document?.amountChangeDiscount || null;
    model.amountChangeNewAmount = document?.amountChangeNewAmount || null;
    model.hasTermsChanged = document?.hasTermsChanged || false;
    model.termsChangeNewTerms = document?.termsChangeNewTerms || null;
  },
  code: "quotations",
  singular: "Quotation",
  plural: "Quotations",
});

Quotation.statuses = statuses;

Quotation.prototype.getStatus = function () {
  return Quotation.statuses[this.status]?.name || "-";
};

Quotation.prototype.getShownAdjustementClause = function () {
  return this.adjustmentClause == null
    ? "-"
    : // : this.adjustmentClauseTrigger.code === "AUTO"
      // ? this.adjustmentClause.name
      `${this.adjustmentClause.name}`;
  /* (${this.adjustmentClauseFloor || ""}${
        this.adjustmentClauseFloor && this.adjustmentClauseCap ? " - " : ""
      }${this.adjustmentClauseCap || ""})`; */
};

Quotation.prototype.getFinalDiscount = function () {
  return Number(this.amountChangeDiscount) > 0
    ? Number(this.amountChangeDiscount)
    : Number(this.amount?.amount) > 0 && Number(this.amountChangeNewAmount) > 0
    ? (1 - Number(this.amountChangeNewAmount) / Number(this.amount?.amount)) * 100
    : null;
};

Quotation.prototype.getFinalAmount = function () {
  return Number(this.amountChangeNewAmount) > 0
    ? Number(this.amountChangeNewAmount)
    : Number(this.amount?.amount) > 0 && Number(this.amountChangeDiscount) > 0
    ? (1 - Number(this.amountChangeDiscount) / 100) * Number(this.amount?.amount)
    : this.amount?.amount;
};

module.exports = Quotation;
