const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.name = document?.name;
    model.items = document?.items || [];
  },
  code: "expenseCategories",
  singular: "Expense Category",
  plural: "Expense Categories",
});
