import { Common } from "@lainco/react-toolbox";
import React from "react";
import styled from "styled-components";

export default function User({ name, imageUrl }) {
  return (
    <Common.Row cols="auto auto" gap="0.5em" alignItems="center">
      <Name>{name}</Name>
      <Icon>
        <Image src={imageUrl} />
      </Icon>
    </Common.Row>
  );
}

const Icon = styled.div``;

const Image = styled.img`
  height: 2em;
  border-radius: 1em;
`;

const Name = styled.div``;
