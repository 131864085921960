import { merge } from "../lib/misc";
import SellOrder from "../model/SellOrder";
import { addPresentation } from "./presentation";
import { hasRole } from "./User";

export default addPresentation(SellOrder, {
  canAdd: true,
  canEdit: true,
  canRemove: false,
  columns: [
    {
      title: "Company",
      align: "center",
      content: { projectors: (x) => merge(x?.company?.code, x?.beneficiary?.code) },
    },
    { title: "Category", content: { name: "category.name" } },
    { title: "Customer", content: { name: "customer.shortName" } },
    { title: "Name", content: { name: "name" } },
    { title: "Status", content: { projectors: (x) => (x?.getStatus ? x.getStatus() : "-") } },
    {
      title: "Amount",
      align: "right",
      content: { projectors: (x) => (x?.getShownAmount ? x?.getShownAmount() : "-") },
    },
    { title: "Terms", content: { name: "terms.name" } },
    {
      title: "Adjustment Clause",
      content: {
        projectors: (x) =>
          x.adjustmentClause == null
            ? "n/a"
            : `${x.adjustmentClause.name} (${x.adjustmentClauseFloor || ""}${
                x.adjustmentClauseFloor && x.adjustmentClauseCap ? " - " : ""
              }${x.adjustmentClauseCap || ""})`,
      },
    },
  ],
});

export const requiredAdminActions = (sellOrder, user) => {
  const actions = [];
  if (hasRole(user, "Administrative")) {
    if (sellOrder?.status === "draft") actions.push("Send to Approval");
    if (sellOrder?.status === "adminReview") actions.push("Review and change to In Progress");
    else if (sellOrder?.status === "pendingApproval" && sellOrder?.projectLeaderOk && sellOrder?.accountManagerOk)
      actions.push("Review and change to In Progress");
    else if (
      sellOrder?.status === "inProgress" &&
      sellOrder?.milestones?.filter((m) => m.status !== "settled" && m.status !== "canceled")?.length === 0
    )
      actions.push("Close/Cancel sell order");
  }
  return actions;
};

export const requiredProjectLeaderActions = (sellOrder, user) =>
  hasRole(user, "Project Leader") &&
  sellOrder?.status === "pendingApproval" &&
  !sellOrder?.projectLeaderOk &&
  (sellOrder?.projectLeader == null || sellOrder?.projectLeader?.id === user?.id)
    ? ["Review milestones"]
    : [];

export const requiredAccountManagerActions = (sellOrder, user) => {
  const actions = [];
  if (hasRole(user, "Account Manager")) {
    if (
      sellOrder?.status === "pendingApproval" &&
      !sellOrder?.accountManagerOk &&
      (sellOrder?.accountManager == null || sellOrder?.accountManager?.id === user?.id)
    )
      actions.push("Review Amount, Payment Terms & Adj Clause");
    if (sellOrder?.accountManager?.id === user?.id && sellOrder?.milestones?.filter((m) => m.isAdjustmentPending)?.length > 0)
      actions.push("Negotiate Adjustment");
  }
  return actions;
};

export const requiredActions = (sellOrder, user) => [
  ...requiredAdminActions(sellOrder, user),
  ...requiredAccountManagerActions(sellOrder, user),
  ...requiredProjectLeaderActions(sellOrder, user),
];

export const isResponsible = (sellOrder, user) => requiredActions(sellOrder, user).length > 0;
