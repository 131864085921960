import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useStorage } from "../lib/storage";
import styled from "styled-components";
import { uid } from "../lib/id";
import ProgressBar from "./ProgressBar";
import { fileNameSplit } from "../lib/misc";
import toast from "react-hot-toast";

export function PureUploadFiles({
  legend,
  disabledLegend,
  disabled,
  uploading,
  uploadingName,
  uploadingTotal,
  uploadingTransfered,
  onAdd,
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback(onAdd, []);
  const res = useDropzone({ onDrop });
  const { getRootProps, getInputProps, isDragActive } = res;
  const color = "#6fb8cc";

  if (disabled) return <DropBox color="#ccc">{disabledLegend}</DropBox>;

  if (uploading)
    return (
      <DropBox color={color}>
        <UplaodingText>Uploading {uploadingName}...</UplaodingText>
        <ProgressBar min={0} max={uploadingTotal} value={uploadingTransfered} />
      </DropBox>
    );

  return (
    <section style={{ display: "grid" }}>
      <div {...getRootProps()} style={{ display: "grid" }}>
        <DropBox color={color} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <p>{isDragActive ? "Drop the files here..." : legend}</p>
        </DropBox>
      </div>
    </section>
  );
}

export default function UploadFiles({ onFileUploadEnd, onFileAdded, onFileRejected, onAllFilesUploadEnded }) {
  const storage = useStorage();
  const [transfered, setTransfered] = useState(0);
  const [total, setTotal] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [uploadingName, setUploadingName] = useState("");

  const buildStatus = (id, file) => {
    const { name, extension } = fileNameSplit(file.name);
    return {
      id: id,
      file,
      fileName: file.name,
      name,
      extension,
      storagePath: `${process.env.REACT_APP_ATTACHMENTS_STORAGE_PATH}/${id}.${extension}`,
      status: "uploading",
    };
  };
  const buildSuccessStatus = (id, file, downloadURL) => ({ ...buildStatus(id, file), status: "success", downloadURL });
  const buildFailedStatus = (id, file, error) => ({ ...buildStatus(id, file), status: "failed", error });

  const uploadFile = (file, index) => {
    return new Promise((resolve, reject) => {
      const id = uid();
      const { extension } = fileNameSplit(file.name);
      const storagePath = `${process.env.REACT_APP_ATTACHMENTS_STORAGE_PATH}/${id}.${extension}`;
      // Comienzo download
      const storageRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, file, { customMetadata: { originalFileName: file.name } });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setTransfered(snapshot.bytesTransferred);
          setTotal(snapshot.totalBytes);
        },
        (error) => {
          const result = buildFailedStatus(id, file, document);
          onFileRejected && onFileAdded(result);
          onFileUploadEnd && onFileUploadEnd(result);
          toast.error(`Error uploading file ${file.name}!`);
          console.error(`Error uploading file ${file.name}`, error);
          resolve(result);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const result = buildSuccessStatus(id, file, downloadURL);
            onFileAdded && onFileAdded(result);
            onFileUploadEnd && onFileUploadEnd(result);
            resolve(result);
          });
        }
      );
    });
  };

  const onDrop = async (files) => {
    setUploading(true);
    setTransfered(0);
    setTotal(100);
    const results = [];
    for (let i = 0; i < files.length; i++) {
      setUploadingName(files[i].name);
      results.push(await uploadFile(files[i], i));
    }
    setUploading(false);
    onAllFilesUploadEnded && (await onAllFilesUploadEnded(results));
  };

  return (
    <PureUploadFiles
      onAdd={onDrop}
      uploading={uploading}
      uploadingName={uploadingName}
      uploadingTransfered={transfered}
      uploadingTotal={total}
      legend="Drag & drop files here, or click to select files"
    />
  );
}

const DropBox = styled.div`
  border-radius: 10px;
  border: 3px dashed ${(props) => props.color};
  color: ${(props) => props.color};
  display: grid;
  grid-auto-columns: auto;
  grid-gap: 0.5em;
  align-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.6em;
  font-weight: 600;
  padding: 0 3em;
  min-height: 8em;
  min-width: 8em;
  background-color: ${(props) => (props.isDragActive ? "#e8faff" : "transparent")};
  cursor: pointer;
`;

const UplaodingText = styled.span`
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  animation: in-progress 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;
