const { startOfToday, parseISO } = require("date-fns");
const buildType = require("./models");

const toDate = (date, defaultValue) => {
  if (!date) return defaultValue;
  else if (typeof date === "string") return parseISO(date);
  else return date;
};

const FollowUp = buildType({
  initializer: (model, document) => {
    model.hasFollowup = document?.hasFollowup || false;
    model.dueDate = toDate(document?.dueDate, startOfToday());
    model.nextFollowup = document?.nextFollowup;
  },
  code: "followUps",
});

module.exports = FollowUp;
