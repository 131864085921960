import chroma from "chroma-js";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";

export function DueBox({ width, label, text, color, date }) {
  return (
    <Container color={color} width={width}>
      <Label>
        {label
          .replace("\n", "\n<br />\n")
          .split("\n")
          .map((x) => (x === "<br />" ? <br /> : <span>{x}</span>))}
      </Label>
      <Text>{text || (date && format(date, "dd / MM / yy"))}</Text>
    </Container>
  );
}

export default function Due({ delta, width, date, action }) {
  if (isNaN(delta)) return "-";

  const label =
    delta === 0
      ? action
        ? `${action} dues`
        : "due"
      : delta > 0
      ? action
        ? `to ${action} on`
        : "near due"
      : action
      ? `${action} overdue`
      : "overdue";
  const color = chroma.scale(["black", "#c08", "red", "#880", "#4c4", "green"]).domain([-180, -120, -30, -7, 0, 7, 180])(delta).hex();
  const text = delta === 0 ? "Today" : Math.abs(delta) > 7 && date ? format(date, "dd / MM / yy") : `${Math.abs(delta)} days`;
  return <DueBox width={width} color={color} label={label} text={text} />;
}

/*
const Text = styled.span`
  text-align: center;
  font-size: 0.9em;
  font-weight: 500;
  color: #444;
`;
*/

const Container = styled.div`
  display: grid;
  color: white;
  ${(props) => props.width && `width: ${props.width};`}
  background: ${(props) => props.color};
  font-size: 0.8em;
  text-align: center;
  gap: 0.1em;
  padding: 0.2em 0.4em;
  border-radius: 0.4em;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  transform: scaleX(0.7);
  width: 200%;
  margin-left: -50%;
`;
const Text = styled.div`
  font-size: 1.1em;
`;
