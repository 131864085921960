import React from "react";
import { ModalBody, Common, Label, Input, Select } from "@lainco/react-toolbox";
import { withCrudModal } from "../components/Modal";
import { useCandidates } from "../app/candidatesSlice";

export function PureNameSelectModalContent({ model, onChange, fieldName, fieldLabel, options, optionsLoading, hasCode, hasShortName }) {
  return (
    <ModalBody>
      <Common.Row cols="1fr 1fr 1fr" gap="1em">
        {hasCode && (
          <Label text="Code" colSpan={hasShortName ? 1 : 2}>
            <Input value={model.code} onChange={(x) => onChange({ ...model, code: x })} />
          </Label>
        )}
        {hasShortName && (
          <Label text="Short Name" colSpan={hasCode ? 1 : 2}>
            <Input value={model.shortName} onChange={(x) => onChange({ ...model, shortName: x })} />
          </Label>
        )}
        {!hasCode && hasShortName && <div colSpan={2} />}
        <Label text="Id">{model.id}</Label>
      </Common.Row>
      <Common.Row cols="2fr 1fr" gap="1em">
        <Label text="Name">
          <Input value={model.name} onChange={(x) => onChange({ ...model, name: x })} />
        </Label>
        <Label text={fieldLabel}>
          <Select
            type="select"
            value={model[fieldName]?.id}
            onChangeOption={(x) => {
              onChange({ ...model, [fieldName]: { id: x.id, name: x.name } });
            }}
            options={options}
            loading={optionsLoading}
          />
        </Label>
      </Common.Row>
    </ModalBody>
  );
}

const NameCountryModalContent = ({ model, onChange, hasCode, hasShortName }) => {
  const [countries, countriesLoading] = useCandidates("countries");
  return (
    <PureNameSelectModalContent
      {...{
        options: countries,
        optionsLoading: countriesLoading,
        model,
        fieldName: "country",
        fieldLabel: "Country",
        onChange,
        hasCode,
        hasShortName,
      }}
    />
  );
};

const NameCategoryModalContent = ({ model, onChange, hasCode, hasShortName }) => {
  const [categories, categoriesLoading] = useCandidates("incomeCategories");
  return (
    <PureNameSelectModalContent
      {...{
        options: categories,
        optionsLoading: categoriesLoading,
        model,
        fieldName: "category",
        fieldLabel: "Category",
        onChange,
        hasCode,
        hasShortName,
      }}
    />
  );
};

export function NameCountryModal(type, hasCode, hasShortName, attachments) {
  return withCrudModal(NameCountryModalContent, type, "lg", attachments || false, { hasCode, hasShortName });
}

export function NameCategoryModal(type, hasCode, hasShortName, attachments) {
  return withCrudModal(NameCategoryModalContent, type, "lg", attachments || false, { hasCode, hasShortName });
}
