import { Tag } from "../modals/SellOrderModal";
import Currency from "../model/Currency";
import { addPresentation } from "./presentation";

export default addPresentation(Currency, {
  canAdd: true,
  canEdit: true,
  canRemove: true,
  columns: [
    { title: "Code", width: "10%", align: "center", content: { name: "code" } },
    {
      title: "Symbol",
      width: "10%",
      align: "center",
      content: {
        projectors: (x) =>
          x ? (
            x.style === "tag" ? (
              <div style={{ fontSize: "1.3em" }}>
                <Tag color={x.color}>{x.symbol}</Tag>
              </div>
            ) : (
              <div style={{ color: x.color, fontWeight: 700 }}>{x.symbol}</div>
            )
          ) : (
            ""
          ),
      },
    },
    "Name",
  ],
});
