const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.code = document?.code;
    model.shortName = document?.shortName;
    model.name = document?.name;
    model.country = document?.country;
  },
  code: "customers",
  singular: "Customer",
  plural: "Customers",
});
