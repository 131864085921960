const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.code = document?.code || null;
    model.name = document?.name;
  },
  code: "countries",
  singular: "Country",
  plural: "Countries",
});
