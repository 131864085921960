const { format } = require("date-fns");

const merge = (a, b) => {
  if (a == null && b == null) return "-";
  if (a != null && b == null) return a;
  if (a == null && b != null) return b;
  if (a === b) return a;
  return `${a} / ${b}`;
};

const formatDate = (d) => (d ? format(d, "dd/MM/yyyy") : "-");

const log = (...args) => console.log(...args);

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

module.exports = { merge, log, formatDate, capitalizeFirstLetter };
