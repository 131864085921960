const Base = require("./Base");

const buildType = ({ initializer, code, save, afterLoad, sortBy, sortDirection, sufix, singular, plural }) => {
  const Type = class extends Base {
    constructor(document) {
      super(code, document);
      initializer(this, document, this);
    }
  };

  Base.registerType(code, Type);

  Type.metadata = {
    create: () => {
      const model = {};
      initializer(model);
      return model;
    },
    code,
    sufix,
    url: () => code,
    filterTemplate: { options: [] },
    queryParams: ({ search, option }) => "",
    name: { singular, plural },
    save,
    afterLoad,
    sortBy,
    sortDirection,
    getLink: (id) => `/${code}${sufix ? "/" + sufix : ""}/${id}`,
  };

  return Type;
};

module.exports = buildType;
