import { useState } from "react";
import { Common, ModalBody, WizardSteps, Label, Select, Checkbox } from "@lainco/react-toolbox";
import { withCrudModal } from "../components/Modal";
import Quotation from "../presentation/Quotation";
import { QuotationModalInfo } from "./QuotationModal.Info";
import { QuotationModalMilestones } from "./QuotationModal.Milestones";
import { QuotationModalActions } from "./QuotationModal.Actions";
import { useCandidates } from "../app/candidatesSlice";
import FollowUpComponent from "../components/FollowUp";
import { addComment, buildComment } from "../components/Comments";
import { useUser } from "../lib/userContext";

const steps = [
  { id: "info", name: "Info" },
  { id: "milestones", name: "Milestones" },
  { id: "actions", name: "Actions" },
];

export function QuotationModalContent({ model, onChange }) {
  const defaultUser = useUser();
  const [currentStep, setCurrentStep] = useState(steps[0].id);
  const [categories, categoriesLoading] = useCandidates("incomeCategories");
  const [projects, projectsLoading] = useCandidates("projects");
  const [companies, companiesLoading] = useCandidates("companies");
  const [users, usersLoading] = useCandidates("users");

  return (
    <div>
      <WizardSteps currentStep={currentStep} steps={steps} onChange={setCurrentStep} />
      <ModalBody>
        <Common.Row cols="2fr 1fr" gap="3em">
          {currentStep === "info" && <QuotationModalInfo model={model} onChange={onChange} />}
          {currentStep === "milestones" && <QuotationModalMilestones model={model} onChange={onChange} />}
          {currentStep === "actions" && <QuotationModalActions model={model} onChange={onChange} />}
          {/* Side Panel */}
          <Common.Col rows="auto auto auto auto auto auto 1fr" gap="1em">
            <Label text="" align="start">
              <FollowUpComponent
                orientation="vertical"
                value={model.followUp}
                defaultUser={defaultUser}
                onChange={(x) => {
                  onChange((m) => ({ ...m, followUp: x }));
                }}
                onNewComment={(c) => {
                  onChange((m) => ({
                    ...m,
                    comments: addComment(m.comments, buildComment({ user: c.user, text: c.text })),
                  }));
                }}
              />
            </Label>
            <Label text="Category">
              <Select
                value={model.category?.id}
                onChangeOption={(x) => {
                  onChange((m) => ({ ...m, category: x, project: null }));
                }}
                options={categories || []}
                loading={categoriesLoading}
              />
            </Label>
            <Label text="Product">
              <Select
                value={model.project?.id}
                onChangeOption={(x) => {
                  onChange((m) => ({ ...m, project: x }));
                }}
                options={projects || []}
                loading={projectsLoading}
              />
            </Label>
            <Label text="Status">
              <Select
                value={model.status}
                options={Object.keys(Quotation.statuses).map((k) => ({
                  id: k,
                  ...Quotation.statuses[k],
                }))}
                onChange={(x) => onChange((m) => ({ ...m, status: x }))}
              />
            </Label>
            <Common.Row cols="1fr 1fr" gap="1em">
              <Label text="Company">
                <Select
                  value={model.company?.id}
                  showValue="shortName"
                  onChangeOption={(x) => {
                    onChange((m) => ({ ...m, company: x, beneficiary: m.beneficiary || x }));
                  }}
                  options={companies?.map((x) => ({ ...x, shortName: x.code, name: x.code })) || []}
                  loading={companiesLoading}
                />
              </Label>
              <Label text="Beneficiary">
                <Select
                  value={model.beneficiary?.id}
                  showValue="shortName"
                  onChangeOption={(x) => {
                    onChange((m) => ({ ...m, beneficiary: x }));
                  }}
                  options={companies?.map((x) => ({ ...x, shortName: x.code, name: x.code })) || []}
                  loading={companiesLoading}
                />
              </Label>
            </Common.Row>
            <Common.Row cols="1fr 1fr" gap="1em">
              <Label
                text="Account Manager"
                info={
                  <span>
                    Are Total amount, Terms,
                    <br />
                    Adjustment clasue & <br />
                    Milestones amounts OK?
                  </span>
                }
              >
                <Common.Row cols="1fr auto" gap="0.5em" alignItems="center">
                  <Select
                    value={model.accountManager?.id}
                    showValue="shortName"
                    onChangeOption={(x) => {
                      onChange((m) => ({ ...m, accountManager: x }));
                    }}
                    options={users?.map((x) => ({ ...x, shortName: x.initials, name: x.initials || x.name })) || []}
                    loading={usersLoading}
                  />
                  <Checkbox
                    checked={model.accountManagerOk}
                    text="OK?"
                    onChange={(x) => onChange((m) => ({ ...m, accountManagerOk: x }))}
                    side="right"
                  />
                </Common.Row>
              </Label>
              <Label text="Project Leader" info={<span>Are milestones dates & names OK?</span>}>
                <Common.Row cols="1fr auto" gap="0.5em" alignItems="center">
                  <Select
                    value={model.projectLeader?.id}
                    showValue="shortName"
                    onChangeOption={(x) => {
                      onChange((m) => ({ ...m, projectLeader: x }));
                    }}
                    options={users?.map((x) => ({ ...x, shortName: x.initials, name: x.initials || x.name })) || []}
                    loading={usersLoading}
                  />
                  <Checkbox
                    checked={model.projectLeaderOk}
                    text="OK?"
                    onChange={(x) => onChange((m) => ({ ...m, projectLeaderOk: x }))}
                    side="right"
                  />
                </Common.Row>
              </Label>
            </Common.Row>
          </Common.Col>
        </Common.Row>
      </ModalBody>
    </div>
  );
}

export default withCrudModal(QuotationModalContent, Quotation, "xl", true, {}, false, true);
