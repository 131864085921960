import React from "react";
import { ModalBody, Common, Label, Input, Select, Checkbox } from "@lainco/react-toolbox";
import { withCrudModal } from "../components/Modal";
import { useCandidates } from "../app/candidatesSlice";
import AdjustmentType from "../presentation/AdjustmentType";
import { usePrice } from "../presentation/Price";

export function PureAdjustmentTypeModalContent({
  model,
  onChange,
  currencies,
  currenciesLoading,
  indexes,
  indexesLoading,
  priceTypes,
  priceTypesLoading,
  price,
}) {
  return (
    <ModalBody>
      <Common.Row cols="2fr 1fr" gap="1em">
        <Label text="Name">
          <Input value={model.name} onChange={(x) => onChange({ ...model, name: x })} />
        </Label>
        <Label text="Id">{model.id}</Label>
      </Common.Row>
      <Common.Row cols="1fr" gap="1em">
        <Label text="Adjust By">
          <Common.Grid rows="1em auto 1em auto" cols="auto 2fr 1em 2fr 1em 1fr">
            <Label text={<span>&nbsp;</span>} row={2} col={1}>
              <div style={{ fontSize: "1.4em" }}>
                <Checkbox checked={model?.currency} />
              </div>
            </Label>
            <Label text="Currency" row={2} col={2}>
              {
                <Select
                  type="select"
                  value={model.currency?.id}
                  onChangeOption={(x) => {
                    onChange({ ...model, currency: { id: x.id, name: x.name }, index: null });
                  }}
                  options={currencies}
                  loading={currenciesLoading}
                />
              }
            </Label>
            <Label text="Price Type" row={2} col={4}>
              <Select
                type="select"
                value={model.priceType?.id}
                onChangeOption={(x) => {
                  onChange({ ...model, priceType: { id: x.id, name: x.name, code: x.code }, index: null });
                }}
                options={priceTypes?.map((x) => ({ ...x, shortName: x.code, name: x.code }))}
                loading={priceTypesLoading}
                showValue="shortName"
              />
            </Label>
            <Label text={<span>&nbsp;</span>} row={2} col={6}>
              {price}
            </Label>
            <Label text={<span>&nbsp;</span>} row={4} col={1}>
              <div style={{ fontSize: "1.4em" }}>
                <Checkbox checked={model?.index} />
              </div>
            </Label>
            <Label text="Index" row={4} col={2} colSpan={3}>
              <Select
                type="select"
                value={model.index?.id}
                onChangeOption={(x) => {
                  onChange({ ...model, index: { id: x.id, name: x.name }, currency: null });
                }}
                options={indexes?.map((i) => ({ ...i, shortName: i.code }))}
                loading={indexesLoading}
                showValue="shortName"
              />
            </Label>
          </Common.Grid>
        </Label>
      </Common.Row>
    </ModalBody>
  );
}

const AdjustmentTypeModalContent = ({ model, onChange }) => {
  const [currencies, currenciesLoading] = useCandidates("currencies");
  const [indexes, indexesLoading] = useCandidates("indexes");
  const [priceTypes, priceTypesLoading] = useCandidates("priceTypes");
  const [price] = usePrice(model?.currency, model?.priceType);

  return (
    <PureAdjustmentTypeModalContent
      {...{
        currencies,
        currenciesLoading,
        indexes,
        indexesLoading,
        priceTypes,
        priceTypesLoading,
        price,
        model,
        onChange,
      }}
    />
  );
};

export default withCrudModal(AdjustmentTypeModalContent, AdjustmentType, "lg", false, {});
