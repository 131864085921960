import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Common, ModalBody, Label, Input, Checkbox } from "@lainco/react-toolbox";
import { DateRangeInput } from "@lainco/react-toolbox";
import { Tag } from "./SellOrderModal";
import styled from "styled-components";
import { Select } from "@lainco/react-toolbox/dist/components/Select";
import { formatDate, formatUnit } from "../lib/misc";
import { withCrudModal, withInMemoryModal } from "../components/Modal";
import SellOrderMilestone from "../model/SellOrderMilestone";
import { TextArea } from "@lainco/react-toolbox/dist/components/TextArea";
import FollowUpComponent from "../components/FollowUp";
import { useUser } from "../lib/userContext";
import { addComment, buildComment } from "../components/Comments";

export function PureSellOrderMilestoneModalContent({ model, onChange, defaultUser }) {
  const [value, setValue] = useState(
    model.definedPercentage != null && model.definedPercentage.toString().trim() !== ""
      ? 0
      : model.definedAmount != null && model.definedAmount.toString().trim() !== ""
      ? 1
      : undefined
  );

  const status = SellOrderMilestone.statuses[model.status] || {};
  const statusWarning = model.getStatusWarning();

  const _onChange = (x) => {
    onChange(x);
  };

  return (
    <ModalBody>
      <Common.Grid cols="12fr 12fr 12fr 0em 20fr" rows="auto" gap="1em">
        <Common.Row col={5} row={1} rowSpan={2}>
          <FollowUpComponent
            orientation="vertical"
            value={model.followUp}
            defaultUser={defaultUser}
            onChange={(x) => {
              onChange((m) => ({ ...m, followUp: x }));
            }}
            onNewComment={(c) => {
              _onChange((m) => ({
                ...m,
                comments: addComment(m.comments, buildComment({ user: c.user, text: c.text })),
              }));
            }}
          />
        </Common.Row>
        {/* RIGHT SIDEBAR */}
        <Label text="Calculated Amount" row={3} col={3}>
          {model.getShownAmount()}
        </Label>
        <Label
          text={
            <Common.Row cols="auto auto 1fr" gap="0.5em">
              Status {statusWarning && <Tag severity={statusWarning.severity}>{statusWarning.message}</Tag>}
            </Common.Row>
          }
          row={3}
          col={5}
        >
          <Common.Row cols="auto auto 1fr"></Common.Row>
          <Select
            value={model.status}
            options={Object.keys(SellOrderMilestone.statuses).map((k) => ({
              id: k,
              ...SellOrderMilestone.statuses[k],
            }))}
            onChange={(x) =>
              onChange((m) => {
                m.setStatus(x);
                return { ...m };
              })
            }
          />
        </Label>
        <Label
          text={
            <Common.Row cols="auto auto 1fr" gap="0.5em">
              Flag {model.hasFlag && <Tag color="red">Impediment</Tag>}
            </Common.Row>
          }
          row={4}
          col={5}
        >
          <Common.Row cols="auto 1fr" gap="0.5em" alignItems="center">
            <Checkbox
              checked={model.hasFlag}
              onChange={() =>
                onChange((m) => ({
                  ...m,
                  hasFlag: !m.hasFlag,
                  flagReasson: !m.hasFlag ? model.flagReasson : "",
                }))
              }
            />
            <Input
              value={model.flagReasson}
              onChange={(x) => {
                onChange((m) => ({
                  ...m,
                  flagReasson: x,
                  hasFlag: x && x !== "",
                }));
              }}
            />
          </Common.Row>
        </Label>
        <Label text="Adjustment Pending" row={5} col={5}>
          <Common.Row cols="auto 1fr" gap="0.5em" alignItems="center">
            <Checkbox
              checked={model.isAdjustmentPending}
              onChange={() =>
                onChange((m) => ({
                  ...m,
                  isAdjustmentPending: !m.isAdjustmentPending,
                }))
              }
            />
            {model.isAdjustmentPending && <Tag severity="error">ADJUSTMENT</Tag>}
          </Common.Row>
        </Label>
        {/* BODY */}
        <Label text="Milestone" row={1} col={1} colSpan={3}>
          <Input value={model.name} onChange={(x) => onChange((m) => ({ ...m, name: x }))} />
        </Label>
        <Label text="Detail" row={2} col={1} colSpan={3}>
          <TextArea value={model.detail} onChange={(x) => onChange((m) => ({ ...m, detail: x }))} />
        </Label>
        <Label text="Percentage" row={3} col={1}>
          <Common.Row cols="auto 1fr" gap="0.5em" alignItems="center">
            <Checkbox
              checked={value === 0}
              onChange={() => {
                setValue(0);
                onChange((m) => ({ ...m, definedAmount: 0 }));
              }}
            />
            <Input
              value={model.definedPercentage}
              onChange={(x) => {
                setValue(0);
                onChange((m) => ({
                  ...m,
                  definedPercentage: x,
                  definedAmount: "",
                }));
              }}
            />
          </Common.Row>
        </Label>
        <Label text="Amount" row={3} col={2}>
          <Common.Row cols="auto 1fr" gap="0.5em" alignItems="center">
            <Checkbox
              checked={value === 1}
              onChange={() => {
                setValue(1);
                onChange((m) => ({ ...m, definedPercentage: "" }));
              }}
            />
            <Input
              value={model.definedAmount}
              onChange={(x) => {
                setValue(1);
                onChange((m) => ({
                  ...m,
                  definedPercentage: "",
                  definedAmount: x,
                }));
              }}
            />
          </Common.Row>
        </Label>
        <Label text="Estimated Project Milestone" row={4} col={1}>
          <DateRangeInput date={model.expectedOk} onDateSelected={(x) => onChange((m) => ({ ...m, expectedOk: x }))} />
        </Label>
        <Label text="Days to Invoice" row={4} col={2}>
          <Input value={model.daysToInvoice} onChange={(x) => onChange((m) => ({ ...m, daysToInvoice: x }))} />
        </Label>
        <Label text="Estimated Invoice Date" row={4} col={3}>
          {formatDate(model.getExpectedInvoiceDate())}
        </Label>
        <Label text="Estimated Invoice Date" row={5} col={1}>
          {formatDate(model.getExpectedInvoiceDate())}
        </Label>
        <Label text="Days to Settle" row={5} col={2}>
          <Input value={model.daysToSettlement} onChange={(x) => onChange((m) => ({ ...m, daysToSettlement: x }))} />
        </Label>
        <Label text="Estimated Settlement Date" row={5} col={3}>
          {formatDate(model.getExpectedSettlementDate())}
        </Label>

        <Title row={7} col={5}>
          Sell Order
        </Title>
        <Common.Grid cols="3fr 2fr 2fr" rows="auto" gap="1em" row={8} col={5} rowSpan={2} background="#fafafa" padding="1em">
          <Label text="Name" row={1} col={1} colSpan={3}>
            <Link to={`/sellOrders/all/${model?.sellOrder?.id}`}>{model?.sellOrder?.name}</Link>
          </Label>
          <Label text="Customer" row={2} cole={1} colSpan={2}>
            {model?.sellOrder?.customer?.name}
          </Label>
          <Label text="#PO" row={2} col={3}>
            {model?.sellOrder?.customerId}
          </Label>
          <Label text="Acc Manager" row={3} col={1}>
            {model?.sellOrder?.accountManager?.initials}
          </Label>
          <Label text="Pr Leader" row={3} col={2}>
            {model?.sellOrder?.accountManager?.initials}
          </Label>
          <Label text="Approver" row={3} col={3} colSpan={2}>
            {model?.sellOrder?.milestonesRequireOk ? "Pr Leader" : "Admin"}
          </Label>
          <Label text="Category" row={4} col={1}>
            {model?.sellOrder?.category?.name}
          </Label>
          <Label text="Company" row={4} col={2}>
            {model?.sellOrder?.company?.name}
          </Label>{" "}
          <Label text="Beneficiary" row={4} col={3}>
            {model?.sellOrder?.beneficiary?.name}
          </Label>{" "}
        </Common.Grid>

        {status.showMilestone && (
          <>
            <Title row={7} col={1} colSpan={3}>
              Invoice
            </Title>

            <Common.Grid
              cols="2fr 3fr 2fr"
              rows="auto"
              gap="1em"
              row={8}
              col={1}
              colSpan={3}
              rowSpan={status.showInvoice ? 2 : 1}
              background="#fafafa"
              padding="1em"
            >
              <Label
                text={
                  <span>
                    Milestone
                    <Common.Link
                      onClick={(x) => {
                        onChange((m) => ({ ...m, ok: null }));
                      }}
                    >
                      Borrar
                    </Common.Link>
                  </span>
                }
                row={1}
                col={1}
              >
                <DateRangeInput date={model.ok} onDateSelected={(x) => onChange((m) => ({ ...m, ok: x }))} />
              </Label>
              <Label text="Milestone Delta" row={1} col={3}>
                {formatUnit(model.getMilestoneDelay(), "days")}
              </Label>
              <Label
                text="Invoice Comment"
                info="Allows to indicate the text to be included in the invoice"
                row={2}
                col={1}
                colSpan={3}
                align="stretch"
              >
                <TextArea fontSize="0.7em" value={model.invoiceText} onChange={(x) => onChange((m) => ({ ...m, invoiceText: x }))} />
              </Label>

              {status.showInvoice && (
                <>
                  <Label
                    text={
                      <span>
                        Invoice
                        <Common.Link
                          onClick={(x) => {
                            onChange((m) => ({ ...m, invoiceDate: null }));
                          }}
                        >
                          Borrar
                        </Common.Link>
                      </span>
                    }
                    row={3}
                    col={1}
                  >
                    <DateRangeInput date={model.invoiceDate} onDateSelected={(x) => onChange((m) => ({ ...m, invoiceDate: x }))} />
                  </Label>
                  <Label text="Invoice Number" row={3} col={2}>
                    <Input value={model.invoiceNumber} onChange={(x) => onChange((m) => ({ ...m, invoiceNumber: x }))} align="right" />
                  </Label>
                  <Label text="Invoice Delta" row={3} col={3}>
                    {formatUnit(model.getInvoiceDelay(), "days")}
                  </Label>
                </>
              )}

              {status.showSettle && (
                <>
                  <Label
                    text={
                      <span>
                        Settlement
                        <Common.Link
                          onClick={(x) => {
                            onChange((m) => ({ ...m, settlementDate: null }));
                          }}
                        >
                          Borrar
                        </Common.Link>
                      </span>
                    }
                    row={4}
                    col={1}
                  >
                    <DateRangeInput date={model.settlementDate} onDateSelected={(x) => onChange((m) => ({ ...m, settlementDate: x }))} />
                  </Label>
                  <Label text="Settlement Delta" row={4} col={3}>
                    {formatUnit(model.getSettlementDelay(), "days")}
                  </Label>
                </>
              )}
            </Common.Grid>
          </>
        )}
      </Common.Grid>
    </ModalBody>
  );
}

function SellOrderMilestoneModal({ model, onChange }) {
  const defaultUser = useUser();
  return <PureSellOrderMilestoneModalContent model={model} defaultUser={defaultUser} onChange={onChange} />;
}

export const CrudSellOrderMilestonModal = withCrudModal(SellOrderMilestoneModal, SellOrderMilestone, "xl", true, {}, true, true);

export const MemorySellOrderMilestonModal = withInMemoryModal(SellOrderMilestoneModal, SellOrderMilestone, "xl", {});

const Title = styled(Common.Span)`
  color: black;
  align-self: center;
  text-transform: uppercase;
`;
