import React from "react";
import BrowserPage from "../components/v2/BrowserPage";
import { buildOptions, useOptions } from "../lib/misc";
import { formatDate } from "../lib/misc";
import Activity from "../presentation/Activity";
import styled from "styled-components";
import { differenceInCalendarDays, formatDistanceToNow, parseISO, startOfToday } from "date-fns";
import { Common } from "@lainco/react-toolbox";
import Filter, { ButtonOptionFilter } from "../components/v2/Filter";
import { icons } from "@lainco/react-toolbox/dist/lib/Icons";

const elapsed = (timestamp) => {
  const text = formatDistanceToNow(parseISO(timestamp));
  if (text === "less than a minute") return "1m";
  return text
    .replace("about ", "")
    .replace("minute", "m")
    .replace("hour", "h")
    .replace("day", "D")
    .replace("month", "M")
    .replace("year", "Y")
    .replace(" ", "")
    .replace("s", "");
};

const today = startOfToday();

const dues = buildOptions(["name"], ["Week", "Month", "Year", "All"]);

function FollowUpLine({ title, followUp }) {
  if (!followUp) return null;
  const { hasFollowup, dueDate, nextTask, user } = followUp;
  return (
    <FollowupStyle>
      {hasFollowup ? (
        <>
          <LightText>&nbsp;&nbsp; {title} follow up</LightText>
          {nextTask && <BoldText>{nextTask}</BoldText>}
          {dueDate && (
            <>
              <LightText>on</LightText>
              <BoldText>{formatDate(parseISO(dueDate))}</BoldText>
            </>
          )}
          {user && (
            <>
              <LightText>by</LightText>
              <BoldText>{user.name}</BoldText>
            </>
          )}
        </>
      ) : (
        <LightText>&nbsp;&nbsp; No {title.toLowerCase()} follow up</LightText>
      )}
    </FollowupStyle>
  );
}

const columns = (filterState) => [
  {
    title: "",
    width: "2em",
    content: {
      projectors: (x) => {
        if (x.activity_type === Activity.ACTIVITY_TYPES.CREATE) return icons.plusCircle({ fontSize: "2em", color: "#888" });
        else if (x.activity_type === Activity.ACTIVITY_TYPES.UPDATE)
          return (
            <span className="fa-layers fa-fw">
              {icons.circle({
                transform: "",
                style: { fontSize: "2em", color: "#888" },
              })}
              {icons.regular.pencil({
                transform: "shrink-8",
                style: { fontSize: "2em", color: "#888" },
              })}
            </span>
          );
        else if (x.activity_type === Activity.ACTIVITY_TYPES.CANCEL) return icons.timesCircle({ fontSize: "2em", color: "red" });
        else if (x.activity_type === Activity.ACTIVITY_TYPES.CLOSE) return icons.checkCircle({ fontSize: "2em", color: "green" });
        else if (x.activity_type === Activity.ACTIVITY_TYPES.STATUS_CHANGE)
          return icons.arrowCircleRight({ fontSize: "2em", color: "#888" });
        else if (x.activity_type === Activity.ACTIVITY_TYPES.FOLLOWUP)
          return (
            <span className="fa-layers fa-fw">
              {icons.circle({
                transform: "",
                style: { fontSize: "2em", color: "#888" },
              })}
              {icons.regular.phone({
                transform: "shrink-8",
                style: { fontSize: "2em", color: "#888" },
              })}
            </span>
          );
        else if (x.activity_type === Activity.ACTIVITY_TYPES.ADD_ATTACHEMENT)
          return (
            <span className="fa-layers fa-fw">
              {icons.circle({
                transform: "",
                style: { fontSize: "2em", color: "#888" },
              })}
              {icons.regular.paperclip({
                transform: "shrink-8",
                style: { fontSize: "2em", color: "#888" },
              })}
            </span>
          );
      },
    },
  },
  {
    title: "Activity",
    content: {
      projectors: (x) => (
        <Common.Col rows="auto auto" gap="5px">
          <div>
            <User>{x.user.name}</User>
            <Action>{x.summary}</Action>
            {x.attachment && (
              <>
                <Action>: </Action>
                <Link href={x.attachment.downloadURL} target="_blank">
                  {x.attachment.fileName}
                </Link>
              </>
            )}
          </div>
          <Common.Row cols="auto 1fr" gap="0.5em">
            <When>{elapsed(x.timestamp)}</When>
            <span>
              <Type>{x.model_type.name.singular}</Type>
              <Link target="_blank" href={x.reference}>
                {x.name}
              </Link>
            </span>
          </Common.Row>
        </Common.Col>
      ),
    },
  },
  {
    title: "Actions made",
    content: {
      projectors: (x) => {
        const changedFollowup =
          x?.followUp?.previous?.hasFollowup !== x?.followUp?.newOne?.hasFollowup ||
          (x?.followUp?.previous?.hasFollowup === x?.followUp?.newOne?.hasFollowup &&
            (x?.followUp?.previous?.dueDate !== x?.followUp?.newOne?.dueDate ||
              x?.followUp?.previous?.nextTask !== x?.followUp?.newOne?.nextTask ||
              x?.followUp?.previous?.user?.id !== x?.followUp?.newOne?.user?.id));
        return (
          <Common.Col>
            {x.comments && (
              <ul style={{ listStyle: '"- "', margin: "0.5em 0" }}>
                {x.comments.map((c) => (
                  <li
                    style={{
                      fontWeight: 400,
                      marginLeft: "0.7em",
                      marginBottom: "0.1em",
                    }}
                  >
                    {c.text}
                  </li>
                ))}
              </ul>
            )}
            {changedFollowup && <FollowUpLine title="Previous" followUp={x?.followUp?.previous} />}
            <FollowUpLine title="Next" followUp={x?.followUp?.newOne} />
          </Common.Col>
        );
      },
    },
  },
];

export const ActivityPage = () => {
  const [responsibles, responsiblesLoading] = useOptions(
    "users",
    [{ name: "All", shortName: "All" }],
    (x) => ({
      ...x,
      shortName: x.initials,
      name: x.initials,
    }),
    (x) => true
  );

  return (
    <BrowserPage
      type={Activity}
      columns={columns}
      initialFilterState={{ responsible: 0, due: 1 }}
      filterFunction={(filter, item) => {
        return (
          (filter.responsible === 0 || filter.responsible === item.user.id) &&
          (filter.due === 4 ||
            (filter.due === 1 && differenceInCalendarDays(today, parseISO(item.timestamp)) <= 7) ||
            (filter.due === 2 && differenceInCalendarDays(today, parseISO(item.timestamp)) <= 30) ||
            (filter.due === 3 && differenceInCalendarDays(today, parseISO(item.timestamp)) <= 365))
        );
      }}
      Filter={
        <Filter fontSize="0.7em">
          <div />
          <ButtonOptionFilter header="Last" name="due" options={dues} />
          <ButtonOptionFilter header="User" name="responsible" options={responsibles} loading={responsiblesLoading} />
        </Filter>
      }
      minWidth="50em"
      sortByFunction={(x) => x.timestamp}
      reverse
    />
  );
};

const User = styled.span`
  &&& {
    color: #00a;
    font-weight: 500;
    margin-right: 7px;
  }
`;

const Type = styled.span`
  &&& {
    font-weight: 500;
    margin-right: 7px;
  }
`;

const When = styled.span`
  &&& {
    color: #777;
    font-weight: 500;
    margin-right: 7px;
  }
`;

const Action = styled.span`
  &&& {
    font-weight: 500;
  }
`;

const Link = styled.a`
  &&& {
    padding-left: 0;
    margin-left: 0;
    color: #0092aa;
    text-decoration: none;
  }
`;

const LightText = styled.span`
  &&& {
    font-weight: 500;
    color: #777;
    margin-right: 0.3em;
  }
`;
const BoldText = styled.span`
  &&& {
    font-weight: 600;
    color: #000;
    margin-right: 0.3em;
  }
`;

const FollowupStyle = styled.div`
  &&& {
    display: flex;
    font-size: 0.8em;
    text-transform: uppercase;
  }
`;
