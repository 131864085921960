import { parseISO } from "date-fns";
import { useMemo } from "react";
import { useCandidates } from "../app/candidatesSlice";
import { formatDate } from "../lib/misc";
import Price from "../model/Price";
import { addPresentation } from "./presentation";

export default addPresentation(Price, {
  canAdd: false,
  canEdit: false,
  canRemove: false,
  columns: {
    date: {
      title: "Date",
      content: {
        projectors: (x) => formatDate(x?.date && parseISO(x.date)),
      },
    },
    currency: { title: "Currency", content: { name: "currency.code" } },
    type: { title: "Type", content: { name: "type.code" } },
    extra1: {
      title: "",
      content: {
        projectors: (x) => {
          return x?.buy ? `BUY: $ ${x?.buy}` : `GD30D: u$s ${x?.gd30d?.price}`;
        },
      },
    },
    extra2: {
      title: "",
      content: {
        projectors: (x) => {
          return x?.buy ? `SELL: $ ${x?.sell}` : `GD30: $ ${x?.gd30?.price}`;
        },
      },
    },
    price: { title: "Price", align: "right", content: { name: "price" } },
  },
});

export const getPrice = (prices, currency, type, date) => {
  const price =
    currency == null || type == null || prices == null
      ? null
      : prices.find((p) => p.currency.id === currency.id && p.type.id === type.id && (date == null || date <= p.date));

  return [price?.price, price?.date];
};

export function usePrice(currency, type, date) {
  const [prices, pricesLoading] = useCandidates("prices");
  const [price, priceDate] = useMemo(() => getPrice(prices, currency, type, date), [prices, currency, type, date]);

  return [price, pricesLoading, priceDate];
}
