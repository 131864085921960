import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { sortBy } from "lodash";

export const initBuckets = () => [];

export const addBucket = (buckets, { from, to, name }) =>
  sortBy(buckets.push({ from, to, key: `${buckets.length}`.padStart(3, "0"), name }), "key");
export const addDayBucket = (buckets, { date }) => addBucket(buckets, { from: date, to: addDays(date, 1), name: format(date, "MMM dd") });
export const addRangeBucket = (buckets, { from, to }) =>
  addBucket(buckets, {
    from,
    to,
    name:
      format(from, "MMM") === format(to, "MMM")
        ? `${format(from, "MMM dd")} to ${format(to, "dd")}`
        : `${format(from, "MMM dd")} to ${format(to, "MMM dd")}`,
  });

export const getBucket = (buckets, date) =>
  buckets.find((b) => (b.from == null || b.from.getTime() <= date.getTime()) && (b.to == null || date.getTime() < b.to));

const normalize = (f) => (d) => addDays(startOfDay(f(d)), 1);

export const buildPeriods = ({ start, days, weeks, months, quarters, years }) => {
  return {
    start,
    from: addDays(start, 1),
    lastDay: addDays(start, days == null ? 0 : days),
    lastWeek: addWeeks(normalize(endOfWeek)(start), weeks == null ? 0 : weeks),
    lastMonth: addMonths(normalize(endOfMonth)(start), months == null ? 0 : months),
    lastQuarter: addQuarters(normalize(endOfQuarter)(start), quarters == null ? 0 : quarters),
    lastYear: addYears(normalize(endOfYear)(start), years == null ? 0 : years),
  };
};

const addRange = (buckets, { start, end, add, limit, name }) => {
  let d = start;

  if (d.getTime() < limit.getTime()) {
    if (d.getTime() < normalize(end)(d).getTime()) {
      if (name) addBucket(buckets, { from: d, to: normalize(end)(d), name: "Rest of " + name(d) });
      else addRangeBucket(buckets, { from: d, to: normalize(end)(d) });
      d = normalize(end)(d);
    }
    for (; d.getTime() < limit.getTime(); d = add(d, 1))
      if (name) addBucket(buckets, { from: d, to: normalize(end)(d), name: name(d) });
      else addRangeBucket(buckets, { from: d, to: normalize(end)(d) });
  }
  return d;
};

export const buildBuckets = ({ today, days, weeks, months, quarters, years }) => {
  const t0 = today || startOfDay();

  var buckets = initBuckets();

  addBucket(buckets, { to: startOfMonth(t0), name: `Before ${format(startOfMonth(today), "MMMM")}` });
  addBucket(buckets, {
    from: startOfMonth(t0),
    to: t0,
    name: `${format(startOfMonth(today), "MMMM")} before today`,
  });
  addBucket(buckets, { from: t0, to: addDays(t0, 1), name: "Today" });

  const { from, lastDay, lastWeek, lastMonth, lastQuarter, lastYear } = buildPeriods({
    start: t0,
    days,
    weeks,
    months,
    quarters,
    years,
  });

  let d = from;

  for (; d.getTime() < lastDay.getTime(); d = addDays(d, 1)) addDayBucket(buckets, { date: d });

  d = addRange(buckets, { start: d, end: endOfWeek, add: addWeeks, limit: lastWeek });
  d = addRange(buckets, {
    start: d,
    end: endOfMonth,
    add: addMonths,
    limit: lastMonth,
    name: (x) => format(x, "MMMM yyyy"),
  });
  d = addRange(buckets, {
    start: d,
    end: endOfQuarter,
    add: addQuarters,
    limit: lastQuarter,
    name: (x) => `Q${format(x, "Q yyyy")}`,
  });
  d = addRange(buckets, {
    start: d,
    end: endOfYear,
    add: addYears,
    limit: lastYear,
    name: (x) => format(x, "yyyy"),
  });

  addBucket(buckets, { from: d, to: null, name: "Later" });

  return buckets;
};
