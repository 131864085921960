import React from "react";
import { Common, icons } from "@lainco/react-toolbox";
import styled from "styled-components";
import UploadFiles, { PureUploadFiles } from "./UploadFiles";
import { usePostAttachment } from "../lib/services";

const contentTypes = [
  [/^image\/.*$/i, icons.fileImage()],
  [/^audio\/.*$/i, icons.fileAudio()],
  [/^video\/.*$/i, icons.fileVideo()],
];

const extensions = [
  [/^pdf$/i, icons.filePdf()],
  [/^xls.?$/i, icons.fileExcel()],
  [/^doc.?$/i, icons.fileWord()],
  [/^pp[st].?$/i, icons.filePowerpoint()],
  [/^(zip|rar|tar)$/i, icons.fileArchive()],
];

const getIcon = (file) => {
  let match = contentTypes.find((x) => x[0].test(file.contentType));
  if (!match) match = extensions.find((x) => x[0].test(file.extension));
  if (!match) match = icons.file();
  else match = match[1];
  return match;
};

export const PureAttachments = ({ onAdded, files }) => {
  return (
    <Common.Row cols="1fr 2fr">
      <PureUploadFiles onAdd={onAdded} legend="Drag & drop files here, or click to select files" />
      <FilesBox>
        {files?.map((f) => (
          <Common.Link key={f.downloadURL}>
            <a href={f.downloadURL} target="_blank" rel="noreferrer">
              {getIcon(f)} <span style={{ fontSize: "0.7em" }}>{f.fileName}</span>
            </a>
          </Common.Link>
        ))}
      </FilesBox>
    </Common.Row>
  );
};

export const FirebaseStorageAttachments = ({ model, code, onChange }) => {
  const postAttachment = usePostAttachment(code);

  const addAttachmentToModel = (file) => {
    const newOne = {
      id: file.id,
      name: file.name,
      fileName: file.fileName,
      extension: file.extension,
      contentType: file.file.type,
      downloadURL: file.downloadURL,
      storagePath: file.storagePath,
    };
    if (model.id) postAttachment(model.id, newOne);

    onChange((m) => ({ ...m, attachments: [...(m.attachments || []), newOne] }));
  };

  return (
    <Common.Col cols="auto 1fr">
      <UploadFiles onFileAdded={addAttachmentToModel} />
      <FilesBox>
        {model?.attachments &&
          model.attachments.map((f) => (
            <FileLink key={f.downloadURL}>
              <a href={f.downloadURL} target="_blank" rel="noreferrer">
                {getIcon(f)} <FileName>{f.fileName}</FileName>
              </a>
            </FileLink>
          ))}
      </FilesBox>
    </Common.Col>
  );
};

const FilesBox = styled.div`
  display: flex;
  flex-flow: column;
  align-content: start;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
  grid-gap: 0.5em;
  padding: 10px 0;
`;

const FileLink = styled(Common.Link)`
  &&& {
    margin-left: 14px;
    max-width: calc(100% - 28px);
  }
`;

const FileName = styled.div`
  font-size: 0.7em;
  max-width: 30em;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;
