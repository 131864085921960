import React from "react";
import BrowserPage from "../components/v2/BrowserPage";
import SellOrderMilestone, { isResponsible, requiredActions } from "../presentation/SellOrderMilestone";
import { CrudSellOrderMilestonModal } from "../modals/SellOrderMilestoneModal";
import Filter, { SelectOptionFilter, ButtonOptionFilter } from "../components/v2/Filter";
import { buildOptions, formatDate, formatDelta, merge, useOptions } from "../lib/misc";
import { Tag } from "../modals/SellOrderModal";
import { startOfToday, addMonths } from "date-fns";
import { useCandidates } from "../app/candidatesSlice";
import Amount from "../components/Amount";
import { hasRole } from "../presentation/User";
import { FollowUpTag } from "../components/FollowUp";

// COLUMNS
const cols = {
  company: {
    title: "Company",
    align: "center",
    content: {
      projectors: (x) => merge(x?.sellOrder?.company?.code, x?.sellOrder?.beneficiary?.code),
    },
  },
  responsible: {
    title: "Responsible",
    align: "center",
    content: {
      projectors: (x) => merge(x?.sellOrder?.accountManager?.initials, x?.sellOrder?.projectLeader?.initials),
    },
  },
  impediment: { title: "Impediment", content: { name: "flagReasson" } },
  customer: {
    title: "Customer",
    content: { name: "sellOrder.customer.shortName" },
  },
  sellOrder: { title: "Sell Order", content: { name: "sellOrder.name" } },
  milestone: {
    title: "Milestone",
    width: "20%",
    content: {
      projectors: (x) => (
        <>
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>{x.name}</div>
          {x
            .getFlags()
            .filter((f) => f.code === "impediment" || f.code === "adjustment")
            .map((f) => (
              <Tag style={{ marginLeft: "5px", verticalAlign: "middle" }} severity={f.severity}>
                {f.message}
              </Tag>
            ))}
        </>
      ),
    },
  },
  status: {
    title: "Status",
    align: "center",
    content: {
      projectors: (x) => x.getStatus(),
    },
  },
  date: {
    title: "Date",
    align: "center",
    content: { projectors: (x) => formatDate(x?.getLastDate()) },
  },
  nextEvent: {
    title: "Next Event",
    align: "center",
    content: {
      projectors: (x) => formatDate(x?.getNextDuedate()),
    },
  },
  delay: {
    title: "Due",
    align: "center",
    content: {
      projectors: (x) => formatDelta(x?.getNextDuedateDelta()),
    },
  },
  amount: {
    title: "Amount",
    align: "right",
    width: "10%",
    content: {
      projectors: (x, c) => <Amount amount={x.getAmount()} currency={x?.sellOrder?.amount?.currency} />,
    },
  },
  adjustmentClause: {
    title: "Adjustment Clause",
    content: {
      projectors: (x) => {
        return x?.isAdjustmentPending ? (
          <Tag color="red">
            <div style={{ fontSize: "1.5em" }}>{x?.sellOrder?.getShownAdjustementClause()}</div>
          </Tag>
        ) : (
          x?.sellOrder?.getShownAdjustementClause()
        );
      },
    },
  },
  invoiceDate: {
    title: "Invoice Date",
    align: "center",
    content: { projectors: (x) => formatDate(x?.invoiceDate) },
  },
  invoiceNumber: {
    title: "#Invoice",
    align: "center",
    content: { name: "invoiceNumber" },
  },
  actions: {
    title: "Actions",
    width: "15%",
    content: {
      projectors: (x, c) => {
        const user = c.users.find((u) => u.id === c.filter?.responsible);
        const actions = requiredActions(x, user);
        return (
          <ul style={{ listStyle: '"- "' }}>
            {actions.map((a) => (
              <li style={{ fontWeight: 400 }}>{a}</li>
            ))}
          </ul>
        );
      },
    },
  },
  followUp: {
    title: "Follow Up",
    width: "15%",
    content: {
      projectors: (x) => {
        return x?.followUp?.hasFollowup && x?.followUp?.dueDate ? (
          <FollowUpTag
            style={{ fontSize: "0.7em" }}
            hasFollowup={true}
            dueDate={x.followUp.dueDate}
            maxLen={50}
            nextTask={x.followUp.nextTask}
          />
        ) : (
          "-"
        );
      },
    },
  },
};

export const useDueFilter = (initialState) => {
  const dues = buildOptions(["name"], ["Overdue", "1 w", "1 m", "All"]);

  return {
    state: { due: initialState },
    filter: (filter, item) => {
      const nextDateDelta = item?.getNextDuedateDelta();

      return (
        filter.due === 4 ||
        (filter.due === 1 && nextDateDelta > 0) ||
        (filter.due === 2 && nextDateDelta >= -7) ||
        (filter.due === 3 && nextDateDelta > -30)
      );
    },
    component: <ButtonOptionFilter header="Duedate" name="due" options={dues} />,
  };
};

export const useCustomerFilter = () => {
  const [customers, customersLoading] = useOptions("customers", [{ name: "All Customers", shortName: "All" }], (x) => ({
    ...x,
    name: x.shortName,
  }));

  return {
    state: { customer: 0 },
    filter: (filter, item) => filter.customer === 0 || item?.sellOrder?.customer?.id === filter.customer,
    component: (
      <SelectOptionFilter header="Customer" name="customer" options={customers} loading={customersLoading} showValue="shortName" />
    ),
  };
};

export const useCompanyFilter = (type) => {
  const [companies, companiesLoading] = useOptions("companies", [{ name: "All Companies", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.code,
  }));

  return {
    state: { company: 0 },
    filter: (filter, item) => filter.company === 0 || item?.sellOrder?.company?.id === filter.company,
    component:
      type === "button" ? (
        <ButtonOptionFilter header="Company" name="company" options={companies} loading={companiesLoading} showValue="shortName" />
      ) : (
        <SelectOptionFilter header="Company" name="company" options={companies} loading={companiesLoading} showValue="shortName" />
      ),
  };
};

export const useBeneficiaryFilter = (type) => {
  const [beneficiaries, beneficiariesLoading] = useOptions("companies", [{ name: "All Companies", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.code,
  }));

  return {
    state: { beneficiary: 0 },
    filter: (filter, item) => filter.beneficiary === 0 || item?.sellOrder?.beneficiary?.id === filter.beneficiary,
    component:
      type === "button" ? (
        <ButtonOptionFilter
          header="Beneficiary"
          name="beneficiary"
          options={beneficiaries}
          loading={beneficiariesLoading}
          showValue="shortName"
        />
      ) : (
        <SelectOptionFilter
          header="Beneficiary"
          name="beneficiary"
          options={beneficiaries}
          loading={beneficiariesLoading}
          showValue="shortName"
        />
      ),
  };
};

export const useCurrencyFilter = () => {
  const [currencies, currenciesLoading] = useOptions("currencies", [{ name: "All Currencies", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.symbol,
  }));

  return {
    state: { currency: 0 },
    filter: (filter, item) => filter.currency === 0 || item?.sellOrder?.amount?.currency?.id === filter.currency,
    component: (
      <SelectOptionFilter header="Currency" name="currency" options={currencies} loading={currenciesLoading} showValue="shortName" />
    ),
  };
};

export const useCategoryFilter = () => {
  const [categories, categoriesLoading] = useOptions("incomeCategories", [{ name: "All" }]);

  return {
    state: { category: 0 },
    filter: (filter, item) => filter.category === 0 || item?.sellOrder?.category?.id === filter.category,
    component: <SelectOptionFilter header="Category" name="category" options={categories} loading={categoriesLoading} />,
  };
};

export const useAccountManagerFilter = () => {
  const [users, usersLoading] = useOptions("users", [{ name: "All", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.initials,
  }));

  return {
    state: { accountManager: 0 },
    filter: (filter, item) => filter.accountManager === 0 || item?.sellOrder?.accountManager?.id === filter.accountManager,
    component: (
      <SelectOptionFilter header="Account Manager" name="accountManager" options={users} loading={usersLoading} showValue="shortName" />
    ),
  };
};

export const useProjectLeaderFilter = () => {
  const [users, usersLoading] = useOptions("users", [{ name: "All", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.initials,
  }));

  return {
    state: { projectLeader: 0 },
    filter: (filter, item) => filter.projectLeader === 0 || item?.sellOrder?.projectLeader?.id === filter.projectLeader,
    component: (
      <SelectOptionFilter header="Project Leader" name="projectLeader" options={users} loading={usersLoading} showValue="shortName" />
    ),
  };
};

export const useNextEventFilter = () => {
  const settlements = buildOptions(
    ["name"],
    [
      addMonths(startOfToday(), -1).toLocaleString("default", {
        month: "long",
      }),
      startOfToday().toLocaleString("default", { month: "long" }),
      startOfToday().getFullYear().toString(),
      "All",
    ]
  );

  return {
    state: { nextEvent: 2 },
    filter: (filter, item) =>
      filter.nextEvent === 4 ||
      (filter.nextEvent === 1 && item?.settlementDate?.getMonth() === addMonths(startOfToday(), -1).getMonth()) ||
      (filter.nextEvent === 2 && item?.settlementDate?.getMonth() === startOfToday().getMonth()) ||
      (filter.nextEvent === 3 && item?.settlementDate?.getYear() === startOfToday().getYear()),
    component: <ButtonOptionFilter header="Settlement" name="nextEvent" options={settlements} />,
  };
};

export const useAdjustmentClauseFilter = () => {
  const [adjustments, adjustmentsLoading] = useOptions("adjustmentTypes", [{ name: "All" }, { name: "No Clause" }, { name: "With Clase" }]);

  return {
    state: { adjustmentClause: 0 },
    filter: (filter, item) =>
      filter.adjustmentClause === 0 ||
      (filter.adjustmentClause === 1 && item?.sellOrder?.adjustmentClause == null) ||
      (filter.adjustmentClause === 2 && item?.sellOrder?.adjustmentClause != null) ||
      item?.sellOrder?.adjustmentClause?.id === filter.adjustmentClause,
    component: <SelectOptionFilter header="Adj. Clause" name="adjustmentClause" options={adjustments} loading={adjustmentsLoading} />,
  };
};

export const useFlagsFilter = () => {
  const flags = buildOptions(["name"], ["All", "No Flags", "Without Impediment", "Impediment", "Adjustment Pending", "Any Flag"]);

  return {
    state: { flag: 1 },
    filter: (filter, item) =>
      filter.flag === 1 ||
      (filter.flag === 2 && !item?.hasFlag && !item?.isAdjustmentPending) ||
      (filter.flag === 3 && !item?.hasFlag) ||
      (filter.flag === 4 && item?.hasFlag) ||
      (filter.flag === 5 && item?.isAdjustmentPending) ||
      (filter.flag === 6 && (item?.hasFlag || item?.isAdjustmentPending)) ||
      false, // si saco este false, no funciona la última opción
    component: <SelectOptionFilter header="Flags" name="flag" options={flags} />,
  };
};

export const useResonsibleFilter = () => {
  const [responsibles, responsiblesLoading] = useOptions(
    "users",
    [{ name: "All", shortName: "All" }],
    (x) => ({
      ...x,
      shortName: x.initials,
      name: x.initials,
    }),
    (x) => hasRole(x, "Project Leader") || hasRole(x, "Account Manager") || hasRole(x, "Administrative")
  );

  return {
    state: { responsible: 0 },
    filter: (filter, item) =>
      filter.responsible === 0 ||
      isResponsible(
        item,
        responsibles.find((r) => r?.id === filter.responsible, filter.responsibleType === 1)
      ),
    component: <ButtonOptionFilter header="Responsible" name="responsible" options={responsibles} loading={responsiblesLoading} />,
  };
};

export const useFilter = (...args) => {
  const initialState = Object.fromEntries(args.map((a) => Object.entries(a.state)).flat());
  const filterFunction = (filter, item) => !args.find((a) => a.filter(filter, item) === false);
  const filterComponents = args.map((a) => a.component);

  return { initialState, filterFunction, filterComponents };
};

// CONTROL
export const SellOrderMilestonePage = ({ status, initialDue, columns, dueType }) => {
  const [currencies] = useCandidates("currencies");
  const [users] = useOptions("users", [{ name: "All", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.initials,
  }));
  const dueDateFilter = useDueFilter(initialDue || 2);
  const settlementFilter = useNextEventFilter();
  const { initialState, filterFunction, filterComponents } = useFilter(
    dueType === "nextEvent" ? dueDateFilter : settlementFilter,
    useResonsibleFilter(),
    useAccountManagerFilter(),
    useProjectLeaderFilter(),
    useAdjustmentClauseFilter(),
    useFlagsFilter(),
    useCategoryFilter(),
    useCurrencyFilter(),
    useCustomerFilter(),
    useCompanyFilter(),
    useBeneficiaryFilter()
  );

  return (
    <BrowserPage
      minWidth="65em"
      Modal={CrudSellOrderMilestonModal}
      columns={columns}
      type={SellOrderMilestone}
      sortByFunction={(x) =>
        x.getNextDuedateDelta() == null ? (x.settlementDate == null ? 9999999999 : x.settlementDate.getTime()) : -x.getNextDuedateDelta()
      }
      initialFilterState={initialState}
      filterFunction={filterFunction}
      Filter={
        <Filter fontSize="0.5em">
          <div />
          {filterComponents}
        </Filter>
      }
      itemsFilter={(x) =>
        !x.isDeleted &&
        (status ? x.status === status : true) &&
        x?.sellOrder?.status !== "draft" &&
        (x?.sellOrder?.status !== "pendingApproval" || (x?.sellOrder?.accountManagerOk && x?.sellOrder?.projectLeaderOk))
      }
      projectorsContext={{ currencies, users }}
    />
  );
};

export const SellOrderMilestoneAllPage = () => (
  <SellOrderMilestonePage
    dueType="nextEvent"
    columns={(filter) => [
      cols.customer,
      cols.sellOrder,
      cols.milestone,
      ...(filter.impediment === 3 || filter.impediment === 5 ? [cols.impediment] : []),
      cols.status,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      cols.nextEvent,
      cols.delay,
      cols.amount,
      cols.adjustmentClause,
    ]}
  />
);
export const SellOrderMilestoneInProgressPage = () => (
  <SellOrderMilestonePage
    status="pending"
    dueType="nextEvent"
    columns={(filter) => [
      cols.customer,
      cols.sellOrder,
      cols.milestone,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      ...(filter.impediment === 3 || filter.impediment === 5 ? [cols.impediment] : []),
      { ...cols.nextEvent, title: "Expected Milestone" },
      cols.delay,
      cols.amount,
    ]}
  />
);

export const SellOrderMilestoneReadyInvoicePage = () => (
  <SellOrderMilestonePage
    status="ok"
    initialDue={4}
    dueType="nextEvent"
    columns={(filter) => [
      cols.customer,
      cols.sellOrder,
      cols.milestone,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      ...(filter.impediment === 3 || filter.impediment === 5 ? [cols.impediment] : []),
      { ...cols.nextEvent, title: "Expected Invoice" },
      cols.delay,
      cols.amount,
    ]}
  />
);

export const SellOrderMilestoneWaitingSettlementPage = () => (
  <SellOrderMilestonePage
    status="invoice"
    initialDue={4}
    dueType="nextEvent"
    columns={(filter) => [
      cols.customer,
      cols.sellOrder,
      cols.milestone,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      ...(filter.impediment === 3 || filter.impediment === 5 ? [cols.impediment] : []),
      cols.invoiceDate,
      cols.invoiceNumber,
      { ...cols.nextEvent, title: "Expected Settlement" },
      cols.delay,
      cols.amount,
    ]}
  />
);

export const SellOrderMilestoneSettledPage = () => (
  <SellOrderMilestonePage
    status="settled"
    dueType="settlement"
    columns={(filter) => [
      cols.customer,
      cols.sellOrder,
      cols.milestone,
      cols.followUp,
      ...(filter.responsible !== 0 ? [cols.actions] : []),
      ...(filter.impediment === 3 || filter.impediment === 5 ? [cols.impediment] : []),
      cols.invoiceDate,
      cols.invoiceNumber,
      { ...cols.date, title: "Settlement Date" },
      cols.amount,
    ]}
  />
);
