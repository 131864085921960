import FollowUp from "../model/FollowUp";
import { addPresentation } from "./presentation";

export default addPresentation(FollowUp, {
  canAdd: false,
  canEdit: true,
  canRemove: false,
  singular: "Follow Up",
  plural: "Follow Ups",
  columns: [],
  actions: [],
});
