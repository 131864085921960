import { orderBy } from "lodash";
import ExpenseCategory from "../model/ExpenseCategory";
import { addPresentation } from "./presentation";

export default addPresentation(ExpenseCategory, {
  sort: (a, b) => a?.country?.name?.localeCompare(b?.country?.name),
  canAdd: true,
  canEdit: true,
  canRemove: false,
  columns: [
    "Name",
    {
      title: "Subcategorias",
      content: {
        projectors: (x) => {
          const aux = x;
          const items = aux?.items;
          const filtered = orderBy(items, (x) => x);
          const sorted = filtered?.join(", ");

          return sorted;
        },
      },
    },
  ],
});
