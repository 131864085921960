import { Common } from "@lainco/react-toolbox";
import styled from "styled-components";
import User from "../model/User";
import { addPresentation } from "./presentation";

export default addPresentation(User, {
  canAdd: false,
  canEdit: true,
  canRemove: true,
  columns: [
    { title: "Initials", width: "5%", content: { name: "initials" } },
    {
      title: "Name",
      width: "20%",
      align: "left",
      content: {
        projectors: (x) =>
          x.photo ? (
            <Common.Row cols="auto 1fr" gap="0.5em" alignItems="center">
              <Icon>
                <Image src={x.photo} />
              </Icon>
              {x.name}
            </Common.Row>
          ) : (
            x.name
          ),
      },
    },
    {
      title: "Mail",
      width: "20%",
      align: "left",
      content: { name: "mail" },
    },
    {
      title: "Roles",
      width: "30%",
      align: "center",
      content: { projectors: (x) => x?.roles?.join(", ") },
    },
    {
      title: "Areas",
      width: "30%",
      align: "center",
      content: { projectors: (x) => (x?.managerOf?.length ? x.managerOf.join(", ") : "-") },
    },
    {
      title: "Habilitado",
      width: "10%",
      align: "center",
      content: { projectors: (x) => (x?.enabled ? "enabled" : "disabled") },
    },
  ],
});

const Icon = styled.div``;

const Image = styled.img`
  height: 1.8em;
  border-radius: 1em;
`;

export const hasRole = (user, role) => user?.roles?.filter((r) => r === role)?.length > 0;
