import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@lainco/react-toolbox";
import { differenceInMilliseconds } from "date-fns";
import { useGet, usePost, usePut } from "../lib/services";
import SellOrder from "../model/SellOrder";
import { statuses } from "../model/SellOrderMilestone";
import Quotation from "../model/Quotation";

const useBatch = (initialText, f) => {
  const [running, setRunning] = useState(false);
  const [text, setText] = useState(initialText);
  const [total, setTotal] = useState(null);
  const [value, setValue] = useState(null);

  const dispatch = async () => {
    const start = new Date();
    setRunning(true);
    const result = await f(setTotal, setValue);
    const diff = differenceInMilliseconds(new Date(), start);
    setRunning(false);
    setText(`${result} in ${diff.toLocaleString()}ms`);
  };

  return { text, running, value, total, dispatch };
};

const BatchComponent = ({ name, initialText, f }) => {
  const { text, running, value, total, dispatch } = useBatch(initialText, f);

  return (
    <Test>
      <Name>{name}</Name>
      <Button onClick={dispatch} disabled={running}>
        Start
      </Button>
      {running && total == null ? (
        <span>Running...</span>
      ) : running && total != null ? (
        <span>
          Running, {value} of {total}
        </span>
      ) : (
        <div>{text}</div>
      )}
    </Test>
  );
};

export function BatchPage() {
  const getSellOrders = useGet(SellOrder.metadata.code, SellOrder);
  const postQuotation = usePost(Quotation.metadata.code, Quotation);
  const putSellOrder = usePut(SellOrder.metadata.code, SellOrder);

  const buildQuotations = async (setTotal, setValue) => {
    return new Promise((resolve) => {
      getSellOrders({}, (x) => {
        x.forEach((sellOrder, index) => {
          if (sellOrder?.status !== "canceled") {
            const file = sellOrder?.attachments?.find((x) => /QUOT\-\d{4}/g.test(x.name));
            const quotation = new Quotation(sellOrder);
            quotation.status = "won";
            quotation.followUp = { hasFollowup: false };

            quotation.hasAmountChanged = true;
            quotation.amountChangeNewAmount = quotation.amount?.amount;
            if (quotation.amount != null) quotation.amount = { ...quotation.amount, amount: null };
            quotation.hasTermsChanged = true;
            quotation.termsChangeNewTerms = quotation.terms;
            quotation.terms = null;

            if (file) {
              quotation.attachments = [file];
              quotation.quotationId = file.name.substring(0, 9);
            }
            postQuotation(quotation, (v) => {
              if (index === x.length - 1) resolve(`${x.length} quotations build`);
            });
          }
        });
      });
    });
  };

  const updateSellorders = async (setTotal, setValue) => {
    return new Promise((resolve) => {
      getSellOrders({}, (x) => {
        x.forEach((sellOrder, index) => {
          if (sellOrder?.status !== "canceled") {
            let modified = false;
            sellOrder.milestones?.forEach((milestone) => {
              const s = statuses[milestone?.status];
              if (milestone != null && s != null) {
                if (!s.showInvoice && (milestone.invoiceDate != null || milestone.invoiceNumber != null || milestone.invoiceText != null)) {
                  console.log("Clean", {
                    s_status: sellOrder.status,
                    status: milestone.status,
                    showInvoice: s.showInvoice,
                    invoideDAte: milestone.invoiceDate,
                    invoiceNumber: milestone.invoiceNumber,
                    invoiceText: milestone.invoiceText,
                  });
                  milestone.invoiceDateBackup = milestone.invoiceDate;
                  milestone.invoiceDate = null;
                  milestone.invoiceNumberBackup = milestone.invoiceNumber;
                  milestone.invoiceNumber = null;
                  milestone.invoiceTextBackup = milestone.invoiceText;
                  milestone.invoiceText = null;
                  modified = true;
                }
                if (!s.showMilestone && milestone.ok != null) {
                  console.log("Clean", { ok: milestone.ok });
                  milestone.okBackup = milestone.ok;
                  milestone.ok = null;
                  modified = true;
                }
                if (!s.showSettle && milestone.settlementDate != null) {
                  console.log("Clean", { settlementDate: milestone.settlementDate });
                  milestone.settlementDateBackup = milestone.settlementDate;
                  milestone.settlementDate = null;
                  modified = true;
                }
              }
            });
            if (modified) {
              console.log("Modified", sellOrder);
              putSellOrder(sellOrder.id, sellOrder);
            }
          }
        });
      });
      resolve(`Done`);
    });
  };

  return (
    <Container>
      <BatchComponent name="Build Quotations from SellOrders" initialText="-" f={buildQuotations} />
      <BatchComponent name="Update SellOrders (clean missing dates)" initialText="-" f={updateSellorders} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
  gap: 1em;
`;

const Test = styled.div`
  display: grid;
  gap: 1em;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  padding: 1em;
  border-radius: 1em;
  background-color: #eee;
`;

const Name = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #777;
`;
