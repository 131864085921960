import BrowserPage from "./components/BrowserPage";
// MODALS
import AdjustmentTypeModal from "./modals/AdjustmentTypeModal";
import CurrencyModal from "./modals/CurrencyModal";
import { NameCountryModal, NameCategoryModal } from "./modals/NameCountryModal";
import NameModal from "./modals/NameModal";
import TermsModal from "./modals/TermsModal";
import UserModal from "./modals/UserModal";
// PAGES
import {
  SellOrderMilestoneAllPage,
  SellOrderMilestoneInProgressPage,
  SellOrderMilestoneReadyInvoicePage,
  SellOrderMilestoneSettledPage,
  SellOrderMilestoneWaitingSettlementPage,
} from "./pages/SellOrderMilestonesPage";
import {
  SellOrdersAdminReviewPage,
  SellOrdersAllPage,
  SellOrdersClossedPage,
  SellOrdersDraftPage,
  SellOrdersInProgressPage,
  SellOrdersPendingApprovalPage,
} from "./pages/SellOrdersPage";
// MODELS
import AdjustmentType from "./presentation/AdjustmentType";
import AdjustmentTypeTrigger from "./presentation/AdjustmentTypeTrigger";
import Company from "./presentation/Company";
import Currency from "./presentation/Currency";
import Country from "./presentation/Country";
import Customer from "./presentation/Customer";
import ExpenseCategory from "./presentation/ExpenseCategory";
import IncomeCategory from "./presentation/IncomeCategory";
import Index from "./presentation/Index";
import PriceType from "./presentation/PriceType";
import Terms from "./presentation/Terms";
import User from "./presentation/User";
import Project from "./presentation/Project";
import { PricesPage } from "./pages/PricesPage";
import { InboxPage } from "./pages/InboxPage";
import CashflowPage from "./pages/CashflowPage";
import { QuotationsPage } from "./pages/QuotationsPage";
import { icons } from "@lainco/react-toolbox/dist/lib/Icons";
import { ActivityPage } from "./pages/ActivityPage";
import { IndexValuesPage } from "./pages/IndexValuesPage";
import { TestSpeedPage } from "./pages/TestSpeedPage";
import IncomesResumePage from "./pages/IncomesResumePage";
import { BatchPage } from "./pages/BatchPages";

export const buildMenu = (user) => {
  return [
    {
      name: "Inbox",
      title: "Inbox",
      link: "/inbox",
      icon: icons.duotone.inbox(),
      hasType: true,
      Component: <InboxPage />,
    },
    {
      name: "Activity Stream",
      title: "Activity Stream",
      link: "/activity",
      icon: icons.duotone.stream(),
      Component: <ActivityPage />,
    },
    {
      name: "Cashflow",
      title: "Cashflow",
      link: "/cashflow",
      icon: icons.duotone.analytics(),
      Component: <CashflowPage />,
    },
    {
      name: "Incomes Resume",
      title: "Incomes Resume",
      link: "/incomes-resume",
      icon: icons.duotone.analytics(),
      Component: <IncomesResumePage />,
      visible: user?.labs?.indexOf("Cashflow") !== -1,
    },
    {
      name: (
        <span style={{ color: "#f88" }}>
          Quotations <span style={{ marginLeft: 7 }}>( {icons.duotone.flask()} )</span>
        </span>
      ),
      title: "Quotations",
      link: "/quotations",
      icon: icons.duotone.funnelDollar({ style: { color: "#f88" } }),
      Component: <QuotationsPage />,
      visible: user?.labs?.indexOf("Quotations") !== -1,
    },
    {
      title: "Incomes",
      icon: icons.duotone.dollarSign(),
      items: [
        {
          name: "Milestones",
          title: "All Milestones",
          link: "/sellOrderMilestones/all",
          Component: <SellOrderMilestoneAllPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; In Progress</span>,
          title: "In Progress",
          link: "/sellOrderMilestones/inProgress",
          Component: <SellOrderMilestoneInProgressPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Ready to Invoice</span>,
          title: "Ready to Invoice",
          link: "/sellOrderMilestones/readyInvoice",
          Component: <SellOrderMilestoneReadyInvoicePage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Waiting Settlement</span>,
          title: "Waiting Settlement",
          link: "/sellOrderMilestones/pendingInvoice",
          Component: <SellOrderMilestoneWaitingSettlementPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Settled</span>,
          title: "Settled",
          link: "/sellOrderMilestones/settled",
          Component: <SellOrderMilestoneSettledPage />,
        },
        {
          name: "Sell Orders",
          title: "All Sell Orders",
          link: "/sellOrders/all",
          Component: <SellOrdersAllPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Drafts</span>,
          title: "Drafts",
          link: "/sellOrders/draft",
          Component: <SellOrdersDraftPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Waiting Approval</span>,
          title: "Waiting Approval",
          link: "/sellOrders/pendingApproval",
          Component: <SellOrdersPendingApprovalPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Admin Review</span>,
          title: "Admin Review",
          link: "/sellOrders/adminReview",
          Component: <SellOrdersAdminReviewPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; In Progress</span>,
          title: "In Progress",
          link: "/sellOrders/inProgress",
          Component: <SellOrdersInProgressPage />,
        },
        {
          name: <span>-&nbsp;&nbsp;&nbsp; Closed / Canceled</span>,
          title: "Closed / Canceled",
          link: "/sellOrders/closed",
          Component: <SellOrdersClossedPage />,
        },
      ],
    },
    {
      title: "Settings",
      icon: icons.duotone.cog(),
      items: [
        {
          title: "Companies",
          link: "/companies",
          Component: <BrowserPage Modal={NameCountryModal(Company, true)} type={Company} />,
        },
        {
          title: "Countries",
          link: "/countries",
          Component: <BrowserPage Modal={NameModal(Country)} type={Country} />,
        },
        {
          title: "Customers",
          link: "/customers",
          Component: <BrowserPage Modal={NameCountryModal(Customer, true, true, true)} type={Customer} />,
        },
        {
          title: "Expense Categories",
          link: "/expenseCategories",
          Component: <BrowserPage Modal={NameModal(ExpenseCategory)} type={ExpenseCategory} />,
        },
        {
          title: "Income Categories",
          link: "/incomeCategories",
          Component: <BrowserPage Modal={NameModal(IncomeCategory)} type={IncomeCategory} />,
        },
        {
          title: "Products",
          link: "/projects",
          Component: <BrowserPage Modal={NameModal(Project)} type={Project} />,
        },
        {
          title: "Payment Terms",
          link: "/terms",
          Component: <BrowserPage Modal={TermsModal} type={Terms} />,
        },
        {
          title: "Users",
          link: "/users",
          Component: <BrowserPage Modal={UserModal} type={User} />,
        },
        /*
        {
          title: "Delay Reassons",
          link: "/delayRessons",
          Component: <BrowserPage Modal={NameModal(DelayReasson)} type={DelayReasson} />,
        },*/
      ],
    },
    {
      title: "Market Data",
      icon: icons.duotone.abacus(),
      items: [
        {
          title: "Adjustment Types",
          link: "/adjustmentTypes",
          Component: <BrowserPage Modal={AdjustmentTypeModal} type={AdjustmentType} />,
        },
        {
          title: "Adjustment Type Triggers",
          link: "/adjustmentTypeTriggers",
          Component: <BrowserPage Modal={NameModal(AdjustmentTypeTrigger, true, { hasCode: true })} type={AdjustmentTypeTrigger} />,
        },
        {
          title: "Currencies",
          link: "/currencies",
          Component: <BrowserPage Modal={CurrencyModal} type={Currency} />,
        },
        {
          title: "Indexes",
          link: "/indexes",
          Component: <BrowserPage Modal={NameModal(Index)} type={Index} />,
        },
        {
          title: "Index Values",
          link: "/indexValues",
          Component: <IndexValuesPage />,
        },
        {
          title: "Price Types",
          link: "/priceTypes",
          Component: <BrowserPage Modal={NameModal(PriceType, true, { hasCode: true })} type={PriceType} />,
        },
        {
          title: "Prices",
          link: "/prices",
          Component: <PricesPage />,
        },
      ],
    },
    {
      name: "Batchs",
      title: "Batchs",
      link: "/batchs",
      icon: icons.duotone.tasksAlt(),
      Component: <BatchPage />,
      visible: user?.roles?.indexOf("Administrator") !== -1,
    },
    {
      name: "Test Speed",
      title: "Test Speed",
      link: "/testSpeed",
      icon: icons.duotone.tasksAlt(),
      Component: <TestSpeedPage />,
      visible: user?.roles?.indexOf("Administrator") !== -1,
    },
  ];
};
