import React, { useEffect, useState } from "react";
import { Common, Label, SelectButton, TextArea, icons } from "@lainco/react-toolbox";
import { highlightText } from "@lainco/react-toolbox/dist/lib/utils";
import { DateRangeInput } from "@lainco/react-toolbox";
import { differenceInDays, differenceInMonths, format, startOfToday } from "date-fns";
import styled from "styled-components";
import { withInMemoryModal } from "./Modal";
import FollowUp from "../presentation/FollowUp";
import { ModalBody } from "@lainco/react-toolbox/dist/components/compound/Modals";
import { Select } from "@lainco/react-toolbox/dist/components/Select";
import { useCandidates } from "../app/candidatesSlice";
import { linefeedToBr } from "../lib/misc";
import { useUser } from "../lib/userContext";

const calculateText = ({ dueDate, hasFollowup }) => {
  const sign = Math.sign(differenceInDays(dueDate, startOfToday()));
  const deltaMonth = Math.abs(differenceInMonths(dueDate, startOfToday()));
  const deltaDays = Math.abs(differenceInDays(dueDate, startOfToday()));
  const days = deltaDays > 60 ? `${deltaMonth} Months` : `${deltaDays} day${deltaDays === 1 ? "" : "s"}`;
  const text = !hasFollowup ? "No follow up" : sign === 0 ? "Today" : sign < 0 ? `${days} late` : `in ${days}`;
  const shortText = hasFollowup ? (deltaDays > 60 ? `${deltaMonth}M` : `${deltaDays}d`) : "-";

  const color = !hasFollowup ? "#ccc" : sign > 0 ? "green" : "red";
  const icon = !hasFollowup ? null : sign > 0 ? icons.solid.alarmClock() : icons.solid.exclamationTriangle();
  const shortIcon = null;

  return { text, shortText, color, icon, shortIcon };
};

export function FollowUpTag({ hasFollowup, dueDate, user, role, nextTask, maxLen }) {
  const { shortText, color, shortIcon } = calculateText({
    dueDate,
    hasFollowup,
  });

  const nextTaskText = maxLen && nextTask && nextTask.length > maxLen ? nextTask.substring(0, maxLen) + "..." : nextTask;
  const nextTaskWithBr = linefeedToBr(nextTaskText, highlightText);

  if (!hasFollowup) return null;
  return (
    <Common.Row cols="auto auto auto" gap="0.5em" alignItems="center">
      {dueDate && (
        <DateTag color={color}>
          {shortIcon}
          {shortText}
        </DateTag>
      )}
      {user && <UserTag>{user.initials}</UserTag>}
      {!user && role && <RoleTag>{role.initials}</RoleTag>}
      <NextTaskSpan>{nextTaskWithBr}</NextTaskSpan>
    </Common.Row>
  );
}

export function PureComponent({ hasFollowup, dueDate, nextTask, orientation, maxLen, user, onClick }) {
  const { text, color, icon, sign } = calculateText({ dueDate, hasFollowup });
  const nextTaskText = maxLen && nextTask && nextTask.length > maxLen ? nextTask.substring(0, maxLen) + "..." : nextTask;
  const nextTaskWithBr = linefeedToBr(nextTaskText, highlightText);

  const content = ({ orientation }) => (
    <>
      <Button background={color} onClick={(x) => onClick && onClick()}>
        <div>
          <Icon>{icon}</Icon>
          {text}
        </div>
      </Button>
      {hasFollowup && (
        <NextTask orientation={orientation}>
          <Common.Row
            cols="auto auto"
            gap="1em"
            justifyItems="center"
            justifyContent="center"
            alignItems="baseline"
            alignContent="baseline"
            padding="0 0 0.5em 0"
          >
            <FollowupUserTag>{user.initials}</FollowupUserTag>
            <Date overdue={sign <= 0}>{format(dueDate, "yyyy-MM-dd")}: </Date>
          </Common.Row>
          {nextTaskWithBr}
        </NextTask>
      )}
    </>
  );

  if (orientation === "vertical")
    return (
      <Common.Col rows="auto 1fr" gap="1em" justifyItems="stretch">
        {content({ orientation })}
      </Common.Col>
    );
  else
    return (
      <Common.Row cols="auto 1fr" gap="1em" alignItems="center">
        {content({ orientation })}
      </Common.Row>
    );
}

export function PureFollowUpModalContent({ model, onChange, users, usersLoading }) {
  const { hasFollowup, dueDate, nextTask, haveDone } = model;
  const loggedUser = useUser();
  return (
    <ModalBody>
      <ModalContainer>
        <Common.Col rows="auto 0em auto auto" gap="1em">
          <Common.Row cols="auto auto auto 1fr" gap="1em">
            <SelectButton
              type="buttons"
              value={hasFollowup}
              onChange={(x) =>
                onChange &&
                onChange((m) => ({
                  ...m,
                  hasFollowup: x,
                  user: x ? m.user : loggedUser,
                }))
              }
              options={[
                { id: false, name: "None" },
                { id: true, name: "Follow Up" },
              ]}
            />
            {hasFollowup && (
              <>
                <DateRangeInput date={dueDate} onDateSelected={(x) => onChange && onChange({ ...model, dueDate: x })} />
                <Select
                  value={model.user?.id}
                  showValue="shortName"
                  onChangeOption={(x) => {
                    onChange({ ...model, user: x });
                  }}
                  options={
                    users?.map((x) => ({
                      ...x,
                      shortName: x.name,
                      name: x.initials || x.name,
                    })) || []
                  }
                  loading={usersLoading}
                />
              </>
            )}
          </Common.Row>
          <div />
          {hasFollowup && (
            <Label text="What you plan todo" align="stretch">
              <TextArea value={nextTask} onChange={(x) => onChange && onChange({ ...model, nextTask: x })} />
            </Label>
          )}
          <Label text="What you have done" align="stretch">
            <TextArea value={haveDone} onChange={(x) => onChange && onChange({ ...model, haveDone: x })} />
          </Label>
        </Common.Col>
      </ModalContainer>
    </ModalBody>
  );
}

function FollowUpModalContent({ model, onChange, defaultUser }) {
  const [users, usersLoading] = useCandidates("users");
  useEffect(() => {
    if (model.user == null || model.dueDate == null)
      onChange &&
        onChange((m) => ({
          ...m,
          user: m.user || defaultUser,
          dueDate: m.dueDate || startOfToday(),
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PureFollowUpModalContent model={model} onChange={onChange} users={users} defaultUser={defaultUser} usersLoading={usersLoading} />;
}

export const FollowUpModal = withInMemoryModal(FollowUpModalContent, FollowUp, "lg", {});

export default function FollowUpComponent({ maxLen, orientation, value, onChange, onNewComment, defaultUser }) {
  const [isOpen, setIsOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const update = (x) => {
    if (x.haveDone && x.haveDone.trim() !== "") {
      onNewComment && onNewComment({ text: x.haveDone, user: defaultUser });
      delete x.haveDone;
    }
    onChange && onChange(x);
    setIsOpen(false);
  };

  return (
    <>
      <PureComponent
        {...{ maxLen, orientation, ...value }}
        onClick={(x) => {
          setEditing({ ...value });
          setIsOpen(true);
        }}
      />
      <FollowUpModal
        isOpen={isOpen}
        onRequestClose={(x) => setIsOpen(false)}
        model={editing}
        onChange={setEditing}
        onAccept={(x) => update(editing)}
        defaultUser={defaultUser}
      />
    </>
  );
}

const Button = styled.div`
  display: grid;
  background: ${(props) => props.background};
  border-radius: 0.5em;
  padding: 0.5em 1em;
  color: white;
  justify-content: center;
  align-content: center;
  cursor: pointer;
`;

const Icon = styled.span`
  margin-right: 0.5em;
`;

const NextTask = styled.span`
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(props) => (props.orientation === "vertical" ? "normal" : "nowrap")};
  line-height: 1.2;
  text-align: ${(props) => (props.orientation === "vertical" ? "left" : "left")};
`;

const ModalContainer = styled.div`
  display: grid;
  /*min-height: 16em;*/
`;

const Date = styled.span`
  font-weight: 600;
  color: ${(props) => (props.overdue ? "red" : "green")};
`;

const DateTag = styled.span`
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  width: fit-content;
  display: grid;
  font-weight: 500;
  border-radius: 0.5em;
  padding: 0.25em 0.6em;
  height: 1.5em;
  justify-content: center;
  align-content: center;
`;

const UserTag = styled.span`
  color: #555;
  width: fit-content;
  display: grid;
  font-weight: 500;
  border-radius: 0.5em;
  padding: 0.25em 0.6em;
  height: 1.5em;
  background: #ccc;
  justify-content: center;
  align-content: center;
`;

const RoleTag = styled.span`
  color: #005;
  width: fit-content;
  display: grid;
  font-weight: 500;
  border-radius: 0.5em;
  padding: 0.25em 0.6em;
  height: 1.5em;
  background: #aac;
  justify-content: center;
  align-content: center;
`;

const NextTaskSpan = styled.span`
  & {
    font-weight: 400;
    justify-self: start;
  }

  &&&& em  {
    font-weight: 600;
    color: #000;
  }

  & br {
    content: "";
    margin: 0.3em;
    display: block;
  }
`;

const FollowupUserTag = styled(UserTag)`
  display: inline-block;
`;
