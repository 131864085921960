import { Common } from "@lainco/react-toolbox";
import { groupBy, sortBy, sum } from "lodash";
import React from "react";
import styled from "styled-components";
import { convert } from "../lib/misc";

const distinct = (items, func) =>
  sortBy(
    Object.entries(
      groupBy(
        items?.map((x) => func(x)),
        (x) => x.key
      )
    ).map((x) => ({ key: x[0], name: x[1][0].name, color: x[1][0].color })),
    (x) => x.key
  );

const buildMatrix = ({ items, rowFunction, columnFunction, getCurrency, getAmount, ars, currency, prices, getDate }) => {
  const rowHeaders = distinct(items, rowFunction); //, { id: "_total", name: "Total" }];
  const columnHeaders = distinct(items, columnFunction); //, { id: "_total", name: "Total" }];
  const values = [...Object.entries(groupBy(items, (x) => rowFunction(x).key))]
    .map((g) => ({ row: g[0], cols: [...Object.entries(groupBy(g[1], (x) => columnFunction(x).key))] }))
    .map((x) => x.cols.map((y) => ({ row: x.row, col: y[0], values: y[1] })))
    .flat()
    .map((i) => ({
      row: i.row,
      col: i.col,
      value: {
        currency,
        amount: sum(
          i.values.map((item) => convert(getDate ? getDate(item) : null, getCurrency(item), getAmount(item), ars, currency, prices))
        ),
      },
    }));

  const rowTotals = Object.entries(groupBy(values, (x) => x.row)).map((t) => ({
    row: t[0],
    col: "_total",
    value: { currency: t[1][0].value.currency, amount: sum(t[1].map((x) => x.value.amount)) },
  }));

  const columnTotals = Object.entries(groupBy(values, (x) => x.col)).map((t) => ({
    col: t[0],
    row: "_total",
    value: { currency: t[1][0].value.currency, amount: sum(t[1].map((x) => x.value.amount)) },
  }));

  const grandTotal = {
    col: "_total",
    row: "_total",
    value: { currency, amount: sum(values.map((x) => x.value.amount)) },
  };

  const all = [...values, ...rowTotals, ...columnTotals, grandTotal];

  const mapped = Object.fromEntries(
    Object.entries(groupBy(all, (x) => x.row)).map((g) => [
      g[0],
      Object.fromEntries(Object.entries(groupBy(g[1], (x) => x.col)).map((x) => [x[0], x[1][0].value])),
    ])
  );
  return {
    rowHeaders: [...rowHeaders, { key: "_total", name: "Total" }],
    columnHeaders: [...columnHeaders, { key: "_total", name: "Total" }],
    values: all,
    mapped,
  };
};

const landscape = (matrix) =>
  matrix.columnHeaders.map((c) => ({
    ...Object.fromEntries(matrix.rowHeaders.map((r) => [r.key, matrix.mapped[r.key][c.key]])),
    name: c.name,
    key: c.key,
  }));

const portait = (matrix) =>
  matrix.rowHeaders.map((r) => ({
    ...Object.fromEntries(matrix.columnHeaders.map((c) => [c.key, matrix.mapped[r.key][c.key]])),
    name: r.name,
    key: r.key,
  }));

export function FilteredContainer({
  title,
  FiltersRow1,
  FiltersRow2,
  content,
  items,
  getDate,
  itemsLoading,
  filter,
  filterState,
  rowFunction,
  columnFunction,
  getValue,
  getCurrency,
  ars,
  asCurrency,
  prices,
}) {
  const filtered = items?.filter((x) => filter == null || filter(filterState, x));
  const matrix =
    rowFunction && columnFunction && getValue && getCurrency && asCurrency && ars && prices
      ? buildMatrix({
          items: filtered,
          rowFunction,
          columnFunction,
          getCurrency,
          getAmount: getValue,
          ars,
          currency: asCurrency,
          prices,
          getDate,
        })
      : null;
  const totals = matrix ? { portait: portait(matrix), landscape: landscape(matrix) } : null;

  return (
    <Common.Col rows="auto auto 1fr" gap="1em" padding="1em">
      <Common.Row cols="auto 1fr" gap="1em">
        <Title>{title}</Title>
        {FiltersRow1}
      </Common.Row>
      {FiltersRow2}
      {itemsLoading ? "Cargando..." : content && content({ items: filtered, matrix, totals })}
    </Common.Col>
  );
}

const Title = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: #777;
`;
