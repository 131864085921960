import React, { Fragment, useState } from "react";
// Components
import { ModalBody, Common, Select, Label, Input, icons, Checkbox, Table } from "@lainco/react-toolbox";
import Amount from "../components/Amount";
import { MemorySellOrderMilestonModal } from "./SellOrderMilestoneModal";
import { withCrudModal } from "../components/Modal";
import { useCandidates } from "../app/candidatesSlice";
import styled from "styled-components";
// Libraries
import { sortBy, sum } from "lodash";
import { formatDate, formatUnit } from "../lib/misc";
import { addMonths, differenceInDays, startOfToday } from "date-fns";
import { uid } from "../lib/id";
import { usePrice } from "../presentation/Price";
import produce from "immer";
// Model
import SellOrder from "../presentation/SellOrder";
import SellOrderMilestone from "../presentation/SellOrderMilestone";
import { DateRangeInput } from "@lainco/react-toolbox";
import FollowUpComponent from "../components/FollowUp";
import { useUser } from "../lib/userContext";
import { addComment, buildComment } from "../components/Comments";
import { useIndexValue } from "../presentation/IndexValue";

const date = (milestone, field, real, expected) => {
  if (real)
    return (
      <div style={{ opacity: 0.7 }}>
        {formatDate(real)} {icons.check({ style: { color: "green" } })}
      </div>
    );
  if (!expected) return <div>-</div>;
  const warning = milestone.getStatusWarning();
  const tagColor = !warning
    ? null
    : warning.field === field && warning.code === "overdue"
    ? "orange"
    : warning.field === field && warning.code === "near-due"
    ? "#ec8"
    : null;
  return (
    <div>
      {tagColor ? (
        <Tag style={{ fontSize: "0.9em" }} color={tagColor}>
          {formatDate(expected)}
        </Tag>
      ) : (
        formatDate(expected)
      )}
    </div>
  );
};

const hasStatus = (model, status) => model.milestones.filter((x) => !x.isDeleted && x.status === status).length > 0;
const total = (model, status, notStatus1, notStatus2) => {
  const _sum = sum(
    model.milestones
      .filter(
        (x) =>
          !x.isDeleted &&
          (status == null || x.status === status) &&
          (notStatus1 == null || x.status !== notStatus1) &&
          (notStatus2 == null || x.status !== notStatus2)
      )
      .map((x) => x.getAmount())
  );
  const _overdue = sum(
    model.milestones
      .filter(
        (x) =>
          !x.isDeleted &&
          (status == null || x.status === status) &&
          (notStatus1 == null || x.status !== notStatus1) &&
          (notStatus2 == null || x.status !== notStatus2)
      )
      .filter((x) => x.isOverdue())
      .map((x) => x.getAmount())
  );
  return (
    <>
      {_overdue === 0 ? (
        <div />
      ) : (
        <OverdueValue>
          <Amount amount={_overdue} currency={model.amount.currency} />
        </OverdueValue>
      )}
      {_sum === 0 ? (
        <PendingValue>-</PendingValue>
      ) : (
        <PendingValue>
          <Amount amount={_sum} currency={model.amount.currency} />
        </PendingValue>
      )}
    </>
  );
};

const statusTable = (model) => {
  return (
    <Common.Grid cols="auto 1fr 1fr" rows={`repeat(${Object.keys(SellOrderMilestone.statuses).length + 4}, auto) 1fr`}>
      <div></div>
      <PendingValue>Overdue</PendingValue>
      <PendingValue>Total</PendingValue>
      {Object.keys(SellOrderMilestone.statuses)
        .filter((x) => x !== "settled" && x !== "canceled")
        .map((key) => (
          <Fragment key={key}>
            <Title>{SellOrderMilestone.statuses[key].name}:</Title>
            {total(model, key, null)}
          </Fragment>
        ))}
      <TableLine />
      <Title subtotal>Total Unsettled</Title>
      {total(model, null, "settled", "canceled")}
      <Title subtotal>Total Settled</Title>
      {total(model, "settled", null)}
      <TableLine />
      <Title total>Total</Title>
      {total(model, null, "canceled")}
      <div>&nbsp;</div>
      <div />
      <div />
      {hasStatus(model, "canceled") && (
        <>
          <Title canceled>Canceled</Title>
          {total(model, "canceled", null)}
        </>
      )}
    </Common.Grid>
  );
};

export function PureSellOrderModalContent({
  model,
  onChange,
  customers,
  companies,
  categories,
  projects,
  terms,
  adjustmentClauses,
  currencies,
  users,
  customersLoading,
  companiesLoading,
  categoriesLoading,
  projectsLoading,
  termsLoading,
  adjustmentClausesLoading,
  adjustmentClausesTriggers,
  adjustmentClausesTriggersLoading,
  currenciesLoading,
  usersLoading,
  price,
  loadingPrice,
  priceDate,
  indexValue,
  loadingIndexValue,
  indexValueDate,
  requestAddMilestone,
  requestEditMilestone,
  defaultUser,
}) {
  const floor = parseFloat(model?.adjustmentClauseFloor);
  const cap = parseFloat(model?.adjustmentClauseCap);
  const reference = parseFloat(model?.adjustmentClauseReference);
  const requireDecrease = price && floor && price < floor;
  const requireIncrease = price && cap && price > cap;
  const requireAdjustment = requireDecrease || requireIncrease;
  const newReference =
    model?.adjustmentFormula === "custom"
      ? parseFloat(model?.adjustmentCustomReference)
      : model?.adjustmentFormula === "market"
      ? price
      : requireDecrease
      ? floor
      : cap;
  const change = newReference / reference;
  const newFloor = floor * change;
  const newCap = cap * change;
  const newClauseDescription = [
    ...[newFloor && !isNaN(newFloor) ? `floor: ${newFloor.toLocaleString()}` : null],
    ...[newReference && !isNaN(newReference) ? `reference: ${newReference.toLocaleString()}` : null],
    ...[newCap && !isNaN(newCap) ? `cap: ${newCap.toLocaleString()}` : null],
  ]
    .filter((x) => x !== null)
    .join(", ");

  const adjustment = (
    <Amount
      currency={model.amount?.currency}
      amount={sum(model?.milestones?.map((x) => x.getAdjustment(model.adjustmentTriggerDate, change - 1)).filter((x) => x != null))}
    />
  );

  const adjustmentAmount = !model?.adjustmentTriggerDate ? (
    "Select adjustment trigger date"
  ) : !model?.adjustmentFormula ||
    (model?.adjustmentFormula === "custom" &&
      (isNaN(parseFloat(model?.adjustmentCustomReference)) || parseFloat(model?.adjustmentCustomReference) === 0)) ? (
    "Enter adjustment price"
  ) : (
    <Common.Row cols="auto auto auto 1fr" gap="1em">
      {`${((change - 1) * 100).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}%`}
      {adjustment} {` ( ${newClauseDescription} )`}
    </Common.Row>
  );

  return (
    <ModalBody>
      <Common.Grid rows="auto auto" cols="2fr 3fr 1em 2fr" gap="1em">
        <Common.Row cols="2fr 1fr" gap="2em" row={1} col={1} colSpan={2}>
          <Label text="Customer">
            <Select
              value={model.customer?.id}
              onChangeOption={(x) => {
                onChange((m) => ({ ...m, customer: x }));
              }}
              options={customers?.map((x) => ({ ...x, name: x.shortName || x.name })) || []}
              loading={customersLoading}
            />
          </Label>
          <Label text="Sell Order Number">
            <Input value={model.customerId} onChange={(x) => onChange((m) => ({ ...m, customerId: x }))} />
          </Label>
        </Common.Row>
        <Label text="Name" row={2} col={1} colSpan={2}>
          <Input value={model.name} onChange={(x) => onChange((m) => ({ ...m, name: x }))} />
        </Label>
        <Label text="Terms" row={3} col={1}>
          <Select
            value={model.terms?.id}
            onChangeOption={(x) => onChange((m) => ({ ...m, terms: x }))}
            options={terms || []}
            loaidng={termsLoading}
          />
        </Label>
        <Label text="Amount" row={3} col={2}>
          <Common.Row cols="14em 1fr" gap="1em">
            <Select
              value={model.amount?.currency?.id}
              showValue="shortName"
              onChangeOption={(x) => {
                onChange((m) => ({ ...m, amount: { currency: x, amount: m.amount.amount } }));
              }}
              options={currencies?.map((x) => ({ ...x, shortName: x.symbol })) || []}
              loading={currenciesLoading}
            />
            <Input
              value={model.amount?.amount?.toString()}
              align="right"
              onChange={(x) => onChange((m) => ({ ...m, amount: { currency: m.amount.currency, amount: x } }))}
            />
          </Common.Row>
        </Label>
        <Label text="" row={1} col={4} rowSpan={2} align="start">
          <FollowUpComponent
            orientation="vertical"
            value={model.followUp}
            defaultUser={defaultUser}
            onChange={(x) => {
              onChange((m) => ({ ...m, followUp: x }));
            }}
            onNewComment={(c) => {
              onChange((m) => ({
                ...m,
                comments: addComment(m.comments, buildComment({ user: c.user, text: c.text })),
              }));
            }}
          />
        </Label>
        <Label
          text={
            <div>
              Adjustment Clause
              <Common.Link
                onClick={(x) =>
                  onChange({
                    ...model,
                    adjustmentClause: null,
                    adjustmentClauseCap: "",
                    adjustmentClauseFloor: "",
                    adjustmentClauseReference: "",
                  })
                }
              >
                Borrar
              </Common.Link>
            </div>
          }
          row={4}
          col={1}
        >
          <Select
            value={model.adjustmentClause?.id}
            onChangeOption={(x) => onChange((m) => ({ ...m, adjustmentClause: x }))}
            options={adjustmentClauses || []}
            loaidng={adjustmentClausesLoading}
          />
        </Label>
        {model.adjustmentClause?.id && (
          <Label
            row={4}
            col={2}
            text={
              <span>
                Market {priceDate && `( ${priceDate})`} {indexValueDate && `( ${indexValueDate})`}
              </span>
            }
          >
            {!isNaN(price) && price != null ? (
              <span style={{ textAlign: "left" }}>{price.toLocaleString()}</span>
            ) : !isNaN(indexValue) && indexValue != null ? (
              <span style={{ textAlign: "left" }}>{indexValue.toLocaleString()}</span>
            ) : (
              "-"
            )}
          </Label>
        )}

        {model.adjustmentClause && (
          <Label text="Adjustment Trigger" row={5} col={1}>
            <Select
              value={model.adjustmentClauseTrigger?.id}
              onChangeOption={(x) => onChange((m) => ({ ...m, adjustmentClauseTrigger: x }))}
              options={adjustmentClausesTriggers || []}
              loaidng={adjustmentClausesTriggersLoading}
            />
          </Label>
        )}
        {model.adjustmentClause && model.adjustmentClauseTrigger?.code != null && (
          <Common.Row cols="1fr 1fr 1fr" gap="1em" row={5} col={2}>
            {(model.adjustmentClauseTrigger?.code === "FLOOR" || model.adjustmentClauseTrigger?.code === "CAP-FLOOR") && (
              <Label
                text="Floor"
                info={
                  <span>
                    If Price/Index gets below this value,
                    <br />a decrease in the sell order must be made
                  </span>
                }
              >
                <Input
                  value={model.adjustmentClauseFloor?.toString()}
                  onChange={(x) => onChange((m) => ({ ...m, adjustmentClauseFloor: x }))}
                />
              </Label>
            )}
            {model.adjustmentClauseTrigger?.code !== "AUTO" && (
              <Label
                text="Reference"
                info={
                  <span>
                    Price/Index of reference used when an adjustment takse place.
                    <br />
                    Usually is the price/index when the quote was made.
                  </span>
                }
              >
                <Input
                  value={model.adjustmentClauseReference?.toString()}
                  onChange={(x) => onChange((m) => ({ ...m, adjustmentClauseReference: x }))}
                />
              </Label>
            )}
            {(model.adjustmentClauseTrigger?.code === "CAP" || model.adjustmentClauseTrigger?.code === "CAP-FLOOR") && (
              <Label
                text="Cap"
                info={
                  <span>
                    If Price/Index gets over this value
                    <br />a increase in the sell order must be made
                  </span>
                }
              >
                <Input
                  value={model.adjustmentClauseCap?.toString()}
                  onChange={(x) => onChange((m) => ({ ...m, adjustmentClauseCap: x }))}
                />
              </Label>
            )}
          </Common.Row>
        )}
        {loadingPrice ? (
          <Label text="" row={5} col={1} rowSpan={2} colSpan={2}>
            "Loading Price..."
          </Label>
        ) : requireAdjustment ? (
          <>
            <Label text="Adjustment Trigger Date" row={6} col={1}>
              <DateRangeInput
                date={model.adjustmentTriggerDate}
                onDateSelected={(x) => onChange((m) => ({ ...m, adjustmentTriggerDate: x }))}
              />
            </Label>
            <Label text="Adjustment Price" row={6} col={2}>
              <Common.Row cols="auto auto auto 1fr" gap="0.5em" alignItems="center">
                <Checkbox
                  text={requireDecrease ? "Floor" : "Cap"}
                  checked={model.adjustmentFormula === "limit"}
                  onChange={() => onChange((m) => ({ ...m, adjustmentFormula: "limit" }))}
                />
                <Checkbox
                  text="Market"
                  checked={model.adjustmentFormula === "market"}
                  onChange={() => onChange((m) => ({ ...m, adjustmentFormula: "market" }))}
                />
                <Checkbox
                  text="Custom"
                  checked={model.adjustmentFormula === "custom"}
                  onChange={() => onChange((m) => ({ ...m, adjustmentFormula: "custom" }))}
                />
                {model.adjustmentFormula === "custom" ? (
                  <Input
                    value={model?.adjustmentCustomReference}
                    onChange={(x) => onChange((m) => ({ ...m, adjustmentCustomReference: x }))}
                    textAlign="rigt"
                  />
                ) : (
                  <div />
                )}
              </Common.Row>
            </Label>
            {model.adjustmentTriggerDate && (
              <Label text="Waiting Adjustment" row={7} col={1}>
                {formatUnit(differenceInDays(startOfToday(), model.adjustmentTriggerDate), "days")}
              </Label>
            )}
            <Label text="Adjustment Amount" row={7} col={2}>
              {adjustmentAmount}
            </Label>
          </>
        ) : null}
        <Subtitle row={9} col={1}>
          <span>
            Milestones <Common.Link onClick={(x) => requestAddMilestone()}>Add</Common.Link>
          </span>
        </Subtitle>

        <Common.Row align="stretch" justify="stretch" row={10} col={1} colSpan={2} background="#fafafa" padding="1em">
          <Common.Col rows="auto 1fr">
            <div style={{ zIndex: 1 }}>
              <Table
                loading={false}
                items={sortBy(
                  model.milestones.filter((m) => !m.isDeleted),
                  (m) => (m.status === "canceled" ? Number.MAX_VALUE : (m.ok ?? m.expectedOk)?.getTime())
                )}
                onRowClick={(milestone, index) => requestEditMilestone(milestone, index)}
                columns={[
                  {
                    title: "Name",
                    content: {
                      projectors: (x) => (
                        <span style={{ color: x.status === "canceled" ? "#600" : null }}>
                          {x.name}{" "}
                          {x.getFlags().map((f) => (
                            <Tag style={{ marginLeft: "5px" }} severity={f.severity}>
                              {f.message}
                            </Tag>
                          ))}
                        </span>
                      ),
                    },
                  },
                  {
                    title: "Status",
                    align: "center",
                    content: {
                      projectors: (x) => (x.status === "canceled" ? <span style={{ color: "#600" }}>{x.getStatus()}</span> : x.getStatus()),
                    },
                  },
                  {
                    title: "Amount",
                    align: "center",
                    content: {
                      projectors: (x) =>
                        x.getAmount() === 0 ? (
                          "-"
                        ) : (
                          <span style={{ color: x.status === "canceled" ? "#600" : null }}>
                            <Amount amount={x.getAmount()} currency={model?.amount?.currency} />
                          </span>
                        ),
                    },
                  },
                  ...(requireAdjustment
                    ? [
                        {
                          title: "Adjustment",
                          align: "right",
                          content: {
                            projectors: (x) => {
                              const adjustment = x?.getAdjustment(model?.adjustmentTriggerDate, change - 1);
                              return <Amount amount={adjustment} currency={model?.amount?.currency} />;
                            },
                          },
                        },
                      ]
                    : []),
                  {
                    title: "Ok",
                    align: "center",
                    content: { projectors: (x) => (x.status === "canceled" ? "" : date(x, "ok", x.ok, x.expectedOk)) },
                  },
                  {
                    title: "Invoice",
                    align: "center",
                    content: {
                      projectors: (x) => (x.status === "canceled" ? "" : date(x, "invoiceDate", x.invoiceDate, x.getExpectedInvoiceDate())),
                    },
                  },
                  {
                    title: "Settlement",
                    align: "center",
                    content: {
                      projectors: (x) =>
                        x.status === "canceled" ? "" : date(x, "settlementDate", x.settlementDate, x.getExpectedSettlementDate()),
                    },
                  },
                ]}
                overlayBackground="#eee"
              />
            </div>
            <div />
          </Common.Col>
        </Common.Row>
        <Label
          row={9}
          col={2}
          text={
            <Checkbox
              text="Milestones must be Approved by Project Leader?"
              checked={model.milestonesRequireOk}
              onChange={(x) => onChange((m) => ({ ...m, milestonesRequireOk: x }))}
            />
          }
        ></Label>
        <Label text="Category" row={3} col={4}>
          <Select
            value={model.category?.id}
            onChangeOption={(x) => {
              onChange((m) => ({ ...m, category: x, project: null }));
            }}
            options={categories || []}
            loading={categoriesLoading}
          />
        </Label>
        <Label text="Product" row={4} col={4}>
          <Select
            value={model.project?.id}
            onChangeOption={(x) => {
              onChange((m) => ({ ...m, project: x }));
            }}
            options={projects || []}
            loading={projectsLoading}
          />
        </Label>
        <Label text="Status" row={5} col={4}>
          <Select
            value={model.status}
            options={Object.keys(SellOrder.statuses).map((k) => ({
              id: k,
              ...SellOrder.statuses[k],
            }))}
            onChange={(x) => onChange((m) => ({ ...m, status: x }))}
          />
        </Label>
        <Common.Row cols="1fr 1fr" gap="1em" row={6} col={4}>
          <Label text="Company">
            <Select
              value={model.company?.id}
              showValue="shortName"
              onChangeOption={(x) => {
                onChange((m) => ({ ...m, company: x, beneficiary: m.beneficiary || x }));
              }}
              options={companies?.map((x) => ({ ...x, shortName: x.code, name: x.code })) || []}
              loading={companiesLoading}
            />
          </Label>
          <Label text="Beneficiary">
            <Select
              value={model.beneficiary?.id}
              showValue="shortName"
              onChangeOption={(x) => {
                onChange((m) => ({ ...m, beneficiary: x }));
              }}
              options={companies?.map((x) => ({ ...x, shortName: x.code, name: x.code })) || []}
              loading={companiesLoading}
            />
          </Label>
        </Common.Row>
        <Common.Row cols="1fr 1fr" gap="1em" row={7} col={4}>
          <Label
            text="Account Manager"
            info={
              <span>
                Are Total amount, Terms,
                <br />
                Adjustment clasue & <br />
                Milestones amounts OK?
              </span>
            }
          >
            <Common.Row cols="1fr auto" gap="0.5em" alignItems="center">
              <Select
                value={model.accountManager?.id}
                showValue="shortName"
                onChangeOption={(x) => {
                  onChange((m) => ({ ...m, accountManager: x }));
                }}
                options={users?.map((x) => ({ ...x, shortName: x.initials, name: x.initials || x.name })) || []}
                loading={usersLoading}
              />
              <Checkbox
                checked={model.accountManagerOk}
                text="OK?"
                onChange={(x) => onChange((m) => ({ ...m, accountManagerOk: x }))}
                side="right"
              />
            </Common.Row>
          </Label>
          <Label text="Project Leader" info={<span>Are milestones dates & names OK?</span>}>
            <Common.Row cols="1fr auto" gap="0.5em" alignItems="center">
              <Select
                value={model.projectLeader?.id}
                showValue="shortName"
                onChangeOption={(x) => {
                  onChange((m) => ({ ...m, projectLeader: x }));
                }}
                options={users?.map((x) => ({ ...x, shortName: x.initials, name: x.initials || x.name })) || []}
                loading={usersLoading}
              />
              <Checkbox
                checked={model.projectLeaderOk}
                text="OK?"
                onChange={(x) => onChange((m) => ({ ...m, projectLeaderOk: x }))}
                side="right"
              />
            </Common.Row>
          </Label>
        </Common.Row>
        <Label row={8} col={4}>
          &nbsp;
        </Label>
        <Subtitle row={9} col={4}>
          {`Totals ( ${model.amount?.currency?.name} )`}
        </Subtitle>
        <Common.Row background="#fafafa" row={10} col={4} padding="1em" alignSelf="start">
          {statusTable(model)}
        </Common.Row>
      </Common.Grid>
    </ModalBody>
  );
}

export const SellOrderModalContent = ({ model, onChange }) => {
  const defaultUser = useUser();
  const [customers, customersLoading] = useCandidates("customers");
  const [companies, companiesLoading] = useCandidates("companies");
  const [categories, categoriesLoading] = useCandidates("incomeCategories");
  const [terms, termsLoading] = useCandidates("terms");
  const [adjustmentClauses, adjustmentClausesLoading] = useCandidates("adjustmentTypes");
  const [adjustmentClausesTriggers, adjustmentClausesTriggersLoading] = useCandidates("adjustmentTypeTriggers");
  const [currencies, currenciesLoading] = useCandidates("currencies");
  const [users, usersLoading] = useCandidates("users");
  const [isMilestoneModalOpen, setIsMilestoneModalOpen] = useState(false);
  const [projects, projectsLoading] = useCandidates("projects");
  const [isMilestoneModalLoading, setIsMilestoneModalLoading] = useState(false);
  const [isNew, setIsNew] = useState();
  const [milestone, setMilestone] = useState();
  const [price, loadingPrice, priceDate] = usePrice(model?.adjustmentClause?.currency, model?.adjustmentClause?.priceType);
  const [indexValue, loadingIndexValue, indexValueDate] = useIndexValue(model?.adjustmentClause?.index);

  const requestAddMilestone = () => {
    setIsNew(true);
    setMilestone(SellOrderMilestone.buildPending({ sellOrder: model }));
    setIsMilestoneModalOpen(true);
  };

  const requestEditMilestone = (milestone, index) => {
    setIsNew(false);
    setMilestone(milestone);
    setIsMilestoneModalOpen(true);
  };

  const accept = () => {
    onChange(
      isNew
        ? { ...model, milestones: [...model.milestones, milestone] }
        : {
            ...model,
            milestones: produce(model.milestones, (draft) => {
              const index = draft.findIndex((x) => x.id === milestone.id);
              draft[index] = milestone;
            }),
          }
    );
    setIsMilestoneModalOpen(false);
  };

  const remove = () => {
    const newOne = {
      ...model,
      milestones: produce(model.milestones, (draft) => {
        const index = draft.findIndex((x) => x.id === milestone.id);
        draft[index].isDeleted = true;
      }),
    };
    onChange(newOne);
    setIsMilestoneModalOpen(false);
  };

  const moveDate = (date) => (date ? addMonths(date, 1) : null);

  const executeAction = (actionCode) => {
    onChange((m) => ({ ...m, milestones: [...model.milestones, milestone] }));
    const newOne = {
      ...milestone,
      id: uid(),
      expectedOk: moveDate(milestone.expectedOk),
      invoiceDate: moveDate(milestone.invoiceDate),
      settlementDate: moveDate(milestone.settlementDate),
    };
    setIsMilestoneModalLoading(true);
    setMilestone(new SellOrderMilestone(newOne));
    setTimeout(() => setIsMilestoneModalLoading(false), 300);
  };

  const onMilestonChange = (x) => {
    setMilestone(typeof x === "function" ? (y) => new SellOrderMilestone(x(y)) : new SellOrderMilestone(x));
  };

  const options = {
    customers,
    customersLoading,
    companies,
    companiesLoading,
    categories,
    categoriesLoading,
    projects,
    projectsLoading,
    terms,
    termsLoading,
    adjustmentClauses,
    adjustmentClausesLoading,
    adjustmentClausesTriggers,
    adjustmentClausesTriggersLoading,
    currencies,
    currenciesLoading,
    users,
    usersLoading,
    price,
    loadingPrice,
    priceDate,
    indexValue,
    loadingIndexValue,
    indexValueDate,
    requestAddMilestone,
    requestEditMilestone,
    defaultUser,
  };
  return (
    <>
      <PureSellOrderModalContent {...{ model, onChange, ...options }} />
      <MemorySellOrderMilestonModal
        loading={isMilestoneModalLoading}
        isOpen={isMilestoneModalOpen}
        isNew={isNew}
        model={milestone}
        defaultUser={defaultUser}
        onChange={onMilestonChange}
        onAccept={(x) => accept()}
        onAction={(x) => executeAction(x)}
        onRemove={(x) => remove()}
        onRequestClose={() => setIsMilestoneModalOpen(false)}
      />
    </>
  );
};

export default withCrudModal(SellOrderModalContent, SellOrder, "xl", true, {}, true, true);

export const Tag = styled.div`
  display: inline-block;
  font-size: 0.6em;
  font-weight: ${(props) => props.fontWeight || "700"};
  padding: 0.4em 0.6em;
  border-radius: 0.4em;
  background: ${(props) =>
    props.color ? props.color : props.severity === "warning" ? "#ec8" : props.severity === "critical" ? "red" : "orange"};
  color: white;
  text-transform: uppercase;
`;

const Title = styled.div`
  font-size: 0.6em;
  font-weight: ${(props) => (props.total || props.subtotal ? "700" : "600")};
  padding: 0.3em 0.6em;
  padding-top: 0.4em;
  color: ${(props) => (props.total ? "#000" : props.subtotal ? "#666" : props.canceled ? "#aaa" : "#888")};
  text-transform: uppercase;
`;

const PendingValue = styled.div`
  font-size: 0.6em;
  font-weight: 600;
  padding: 0.3em 0.6em;
  padding-top: 0.4em;
  color: #444;
  text-transform: uppercase;
  justify-self: end;
`;

const OverdueValue = styled.div`
  font-size: 0.6em;
  font-weight: 600;
  border-radius: 0.4em;
  padding: 0.2em 0.5em;
  padding-top: 0.3em;
  color: #800;
  //color: white;
  text-transform: uppercase;
  justify-self: end;
`;

const TableLine = styled.div`
  grid-column-start: 1;
  grid-column-end: span 3;
  border-top: 1px solid #ccc;
  margin-bottom: 3px;
  margin-top: 3px;
`;

const Subtitle = styled(Common.Span)`
  color: black;
  align-self: center;
  text-transform: uppercase;
`;
