import React from "react";
import { Label, Common, icons } from "@lainco/react-toolbox";
import styled from "styled-components";

export default function List({ label, initializer, items, wrapper, onChange }) {
  return (
    <Label
      text={
        <>
          <span>{label}</span>
          <Link onClick={() => onChange([...items, initializer()])}>add</Link>
        </>
      }
    >
      <Box>
        {items.map((x, i) => (
          <Item key={i}>
            <Common.Row cols="1fr auto" gap="1em">
              {wrapper(x, i)}
              <div onClick={() => onChange([...items.slice(0, i), ...items.slice(i + 1)])}>
                {icons.trash({ style: { fontSize: "0.7em", marginTop: "0.6em", color: "#09a" } })}
              </div>
            </Common.Row>
          </Item>
        ))}
      </Box>
    </Label>
  );
}

const Box = styled.div`
  display: grid;
  /*
  border: 1px solid #ccc;
  border-radius: 0.5em;
  padding: 0.5em;
  */
`;

const Item = styled.div`
  padding: 0.2em;
`;

const Link = styled(Common.Link)`
  font-size: 0.25em !important;
`;
