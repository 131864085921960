import { useState, useRef, forwardRef } from "react";
import styled from "styled-components";
import { Label, Common, Input, Button, icons } from "@lainco/react-toolbox";

export function QuotationId({ label = null, value, onChange, onCreate }) {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const inputRef = useRef(null);

  const isError = inputValue == null || !/^QUOT\-\d{4}$/.test(inputValue);

  const startEditing = () => {
    setInputValue(value ?? "QUOT-");
    setEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  if (editing)
    return (
      <Label error={isError ? "must be QUOT-XXXX" : null} text={label ?? "Quotation ID"}>
        <Common.Row cols="1fr auto auto" gap="0.4em" align="center">
          <Input ref={inputRef} value={inputValue} onChange={setInputValue} />
          <HoverButton
            onClick={() => {
              if (isError) return;
              onChange(inputValue);
              setEditing(false);
            }}
          >
            <div className="normal">{icons.solid.check({ color: isError ? "#ccc" : "#999" })}</div>
            <div className="hover">{icons.solid.check({ color: isError ? "#ccc" : "green", cursor: isError ? "default" : "pointer" })}</div>
          </HoverButton>
          <HoverButton onClick={() => setEditing(false)}>
            <div className="normal">{icons.solid.times()}</div>
            <div className="hover">{icons.solid.times({ color: "red" })}</div>
          </HoverButton>
        </Common.Row>
      </Label>
    );

  if (value == null)
    return (
      <Label error="(*)" text={label ?? "Quotation ID"}>
        <Common.Row cols="auto auto 1fr" gap="0.2em" height="2.4em" alignItems="center">
          <Button spacing="small" fontSize="0.9em" type="primary" onClick={onCreate}>
            <span style={{ fontVariant: "small-caps" }}>Create</span>
          </Button>
          <Button spacing="small" fontSize="0.9em" type="cancel" onClick={startEditing}>
            <span style={{ fontWeight: "bold", fontVariant: "small-caps" }}>Edit</span>
          </Button>
        </Common.Row>
      </Label>
    );

  return (
    <Label text={label ?? "Quotation ID"}>
      <Common.Row cols="1fr auto" gap="0.4em" alignItems="center">
        <ReadOnly>{value}</ReadOnly>
        <HoverButton onClick={startEditing}>
          <div className="normal">{icons.solid.lock()}</div>
          <div className="hover">{icons.solid.unlock()}</div>
        </HoverButton>
      </Common.Row>
    </Label>
  );
}

const ReadOnly = styled.span`
  padding: 0.625em 0;
  line-height: normal;
`;

const HoverButton = styled.div`
  font-size: 1.3em;
  color: #999;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > .normal,
  &:hover > .hover {
    display: grid;
  }

  &:hover > .normal,
  & > .hover {
    display: none;
    color: #555;
  }
`;

//const StyledInput = forwardRef(({ value, onChange }, ref) => <input value={value} onChange={(e) => onChange(e.target.value)} ref={ref} />);
