import React, { useState, useMemo, useEffect } from "react";
import { Common, Table } from "@lainco/react-toolbox";
import styled from "styled-components";
import { useGet, useGetById } from "../../lib/services";
import { useItems } from "../../app/browsersSlice";
import { inject } from "../../lib/misc";
import { reverse as reversItems, sortBy } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "@lainco/react-toolbox/dist/lib/Icons";

export function PureBrowserPage({
  title,
  name,
  items,
  loading,
  cols,
  stats,
  filter,
  filterState,
  addEnabled,
  editEnabled,
  onAddRequested,
  onEditRequested,
  onRefreshRequested,
  projectorsContext,
  minWidth,
  maxWidth,
  emptyContent,
  allowMultiSelect,
  multipleSelection,
  onMultipleSelectionChange,
  actions,
}) {
  const rows = filter ? 1 : 0;
  const showEmpty = emptyContent && items && items.length === 0;
  return (
    <Container rows={`repeat(${rows}, auto) 1fr`} gap="1em" minWidth={minWidth} maxWidth={maxWidth}>
      {filter}
      <Common.Col rows="auto auto 1fr">
        <Title>
          <div />
          {addEnabled ? (
            <Link onClick={() => onAddRequested && onAddRequested()} fontSize="0.7em" space="medium">
              Add {name}
            </Link>
          ) : (
            <div />
          )}
          <Link onClick={() => onRefreshRequested && onRefreshRequested()} fontSize="0.7em" space="medium">
            Refresh {title}
          </Link>
        </Title>
        {showEmpty && emptyContent}
        {!showEmpty && (
          <Table
            projectorsContext={{ ...projectorsContext, filter: filterState }}
            loading={loading}
            items={items || (loading ? [null, null, null] : [])}
            columns={cols}
            onRowClick={editEnabled ? (x) => onEditRequested && onEditRequested(x) : null}
            overlayBackground="#eee"
            selectedBackground="#e8e8e8"
            allowMultiSelect={allowMultiSelect}
            multipleSelection={multipleSelection}
            onMultipleSelectionChange={onMultipleSelectionChange}
          />
        )}
      </Common.Col>
      {allowMultiSelect && multipleSelection && multipleSelection.length > 0 && actions && (
        <ActionsPanel>
          <Times onClick={(x) => onMultipleSelectionChange([])}>{icons.times()}</Times>
          {actions}
        </ActionsPanel>
      )}
    </Container>
  );
}

export default function BrowserPage({
  type,
  Modal,
  Filter,
  columns,
  canAdd,
  canEdit,
  itemsFilter,
  filterFunction,
  sortByFunction,
  reverse,
  initialFilterState,
  projectorsContext,
  minWidth,
  maxWidth,
  emptyContent,
  allowMultiSelect,
  multipleSelection,
  onMultipleSelectionChange,
  actions,
  modals,
}) {
  const [filterState, setFilterState] = useState(initialFilterState);
  const [items, loading] = useItems(type.metadata.code);
  const [model, setModel] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(null);
  const get = useGet(type.metadata.code, type);
  const getById = useGetById(type.metadata.url(""), type);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsNew(false);
      setModel(null);
      setIsOpen(true);
      getById(id, (res) => setModel(res));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredItems = useMemo(() => {
    if (!items) return null;

    const f = items.filter((x) => (itemsFilter ? itemsFilter(x) : true) && (filterFunction ? filterFunction(filterState, x) : true));

    const sorted = sortByFunction ? sortBy(f, sortByFunction) : f;

    if (reverse) return reversItems(sorted);
    else return sorted;
  }, [items, itemsFilter, filterFunction, filterState, sortByFunction, reverse]);

  const requireRefresh = () => {
    get();
    navigate(".");
  };

  const requireAdd = () => {
    setIsNew(true);
    setModel(type.metadata.create());
    setIsOpen(true);
  };
  const requireEdit = (x) => {
    setIsNew(false);
    setModel(null);
    setIsOpen(true);
    getById(x.id, (res) => setModel(res));
    navigate(x.id);
  };

  return (
    <div style={{ display: "grid", justifyContent: "center" }}>
      <PureBrowserPage
        title={type.metadata.name.plural}
        name={type.metadata.name.singular}
        items={filteredItems}
        emptyContent={emptyContent}
        loading={loading}
        filter={inject(Filter, { model: filterState, onChange: setFilterState })}
        filterState={filterState}
        cols={columns?.(filterState) ?? type.metadata.columns}
        addEnabled={canAdd || type.metadata.canAdd}
        editEnabled={canEdit || type.metadata.canEdit}
        onAddRequested={() => requireAdd()}
        onEditRequested={(x) => {
          requireEdit(x);
        }}
        onRefreshRequested={(x) => requireRefresh()}
        projectorsContext={projectorsContext}
        minWidth={minWidth}
        maxWidth={maxWidth}
        allowMultiSelect={allowMultiSelect}
        multipleSelection={multipleSelection}
        onMultipleSelectionChange={onMultipleSelectionChange}
        actions={actions}
      />
      {Modal && (
        <Modal
          loading={model == null}
          isOpen={isOpen}
          isNew={isNew}
          model={model}
          onChange={(x) => {
            if (typeof x === "function") setModel((y) => new type(x(y)));
            else setModel(new type(x));
          }}
          onRefreshRequested={requireRefresh}
          onRequestClose={() => {
            navigate(".");
            setIsOpen(false);
          }}
        />
      )}
      {modals && modals({ refresh: requireRefresh })}
    </div>
  );
}

export function MergeBrowserPage({
  typesCatalog,
  Filter,
  columns,
  title,
  canAdd,
  canEdit,
  itemsFilter,
  filterFunction,
  sortByFunction,
  initialFilterState,
  projectorsContext,
  minWidth,
  maxWidth,
  emptyContent,
  allowMultiSelect,
  multipleSelection,
  onMultipleSelectionChange,
  actions,
  modals,
}) {
  const [filterState, setFilterState] = useState(initialFilterState);
  const metadata = {};
  for (const t of typesCatalog) {
    const code = t.type.metadata.code;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [items, loading] = useItems(code);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const get = useGet(code, t.type);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getById = useGetById(code, t.type);
    metadata[code] = {
      code,
      type: t.type,
      projector: t.projector,
      items,
      loading,
      get,
      getById,
    };
  }

  const loading = Object.values(metadata).find((x) => x.loading) != null;
  const items = loading
    ? null
    : Object.values(metadata)
        .map((m) =>
          m.items.map((item) =>
            m.projector != null
              ? { id: item.id, _loading: item._loading, ...m.projector(item), _source: item, _type: m.code }
              : { ...item, _loading: item._loading, _source: item, _type: m.code }
          )
        )
        .flat();
  const [model, setModel] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [isNew, setIsNew] = useState(null);
  const navigate = useNavigate();
  const { id, type } = useParams();

  useEffect(() => {
    if (id) {
      setIsNew(false);
      setModel(null);
      setIsOpen(type);
      metadata[type].getById(id, (res) => setModel(res));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredItems = useMemo(() => {
    if (!items) return null;

    const f = items.filter((x) => (itemsFilter ? itemsFilter(x) : true) && (filterFunction ? filterFunction(filterState, x) : true));

    if (sortByFunction) return sortBy(f, sortByFunction);
    else return f;
  }, [items, itemsFilter, filterFunction, filterState, sortByFunction]);

  const requireRefresh = (code) => {
    if (code == null) for (const m of Object.values(metadata)) m.get();
    else metadata[code].get();
    navigate(".");
  };

  const requireEdit = (x) => {
    setIsNew(false);
    setModel(null);
    setIsOpen(x._type);
    metadata[x._type].getById(x._source.id, (res) => setModel(res));
    navigate(x._type + "/" + x._source.id);
  };

  return (
    <>
      <PureBrowserPage
        title={title}
        items={filteredItems}
        loading={loading}
        emptyContent={emptyContent}
        filter={inject(Filter, { model: filterState, onChange: setFilterState })}
        filterState={filterState}
        cols={columns(filterState)}
        addEnabled={false}
        editEnabled={canEdit}
        onEditRequested={(x) => {
          requireEdit(x);
        }}
        onRefreshRequested={(x) => requireRefresh()}
        projectorsContext={projectorsContext}
        minWidth={minWidth}
        maxWidth={maxWidth}
        allowMultiSelect={allowMultiSelect}
        multipleSelection={multipleSelection}
        onMultipleSelectionChange={onMultipleSelectionChange}
        actions={actions}
      />
      {typesCatalog.map((t) =>
        isOpen === t.code ? (
          <t.Modal
            key={t.code}
            loading={isOpen === t.code && model == null}
            isOpen={isOpen === t.code}
            isNew={isNew}
            model={isOpen === t.code ? model : null}
            onChange={(x) => {
              if (typeof x === "function") setModel((y) => new t.type(x(y)));
              else setModel(new t.type(x));
            }}
            onRefreshRequested={(x) => requireRefresh(t.code)}
            onRequestClose={() => {
              navigate(".");
              setIsOpen(null);
            }}
          />
        ) : null
      )}
      {modals && modals({ refresh: requireRefresh })}
    </>
  );
}

const Title = styled.h1`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 4em;
  justify-content: end;
  font-size: 0.5em;
  color: #777;
  padding: 1em;
`;

const Link = styled(Common.Link)`
  &&&& {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 0;
  }
`;
const Container = styled(Common.Col)`
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : "")}
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : "")}
  padding-top: 2.5em;
`;

const ActionsPanel = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  background: white;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  gap: 1em;
`;
const Times = styled.div`
  font-size: 2em;
  color: #aaa;
  margin-right: 1em;
  cursor: pointer;
`;
