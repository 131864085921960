import React from "react";
import BrowserPage from "../components/v2/BrowserPage";
import IndexValue from "../presentation/IndexValue";
import { useOptions } from "../lib/misc";
import Filter, { ButtonOptionFilter } from "../components/v2/Filter";

// CONTROL

export const IndexValuesPage = () => {
  const [indexesOptions, indexesOptionsLoading] = useOptions("indexes", [{ name: "All Types", shortName: "All" }], (x) => ({
    ...x,
    name: x.code,
    shortName: x.code,
  }));
  const columns = IndexValue.metadata.columns;

  return (
    <BrowserPage
      initialFilterState={{ index: 0 }}
      filterFunction={(filter, item) => {
        return filter.index === 0 || item?.index === filter.index;
      }}
      Filter={
        <Filter fontSize="0.5em">
          <div />
          <ButtonOptionFilter header="Index" name="index" options={indexesOptions} loading={indexesOptionsLoading} />
        </Filter>
      }
      columns={() => [columns.index, columns.date, columns.value]}
      type={IndexValue}
      minWidth="40em"
      maxWidth="60em"
    />
  );
};
