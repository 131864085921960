const sellOrderMilestone = {
  pending: {
    key: 1,
    name: "Pending",
    showMilestone: false,
    showInvoice: false,
    showSettle: false,
    nextStatus: "approve",
  },
  ok: {
    key: 2,
    name: "Approved",
    showMilestone: true,
    showInvoice: false,
    showSettle: false,
    nextStatus: "invoice",
  },
  invoice: {
    key: 3,
    name: "Invoiced",
    showMilestone: true,
    showInvoice: true,
    showSettle: false,
    nextStatus: "settle",
  },
  settled: {
    key: 4,
    name: "Settled",
    showMilestone: true,
    showInvoice: true,
    showSettle: true,
    nextStatus: "settled at",
  },
  canceled: {
    key: 5,
    name: "Canceled",
    showMilestone: false,
    showInvoice: false,
    showSettle: false,
    showCancel: true,
    nextStatus: "canceled",
  },
};

const sellOrder = {
  draft: { key: 1, name: "Draft" },
  pendingApproval: { key: 2, name: "Waiting\nApproval" },
  adminReview: { key: 3, name: "Admin Review" },
  inProgress: { key: 4, name: "In Progress" },
  closed: { key: 5, name: "Closed" },
  canceled: { key: 6, name: "Canceled" },
};

const quotation = {
  research: { key: "research", name: "Research", closed: false },
  firstContact: { key: "firstContact", name: "First Contact", closed: false },
  visit: { key: "visit", name: "Visit", closed: false },
  proposal: { key: "proposal", name: "Proposal", closed: false },
  validation: { key: "validation", name: "Validation", closed: false },
  quote: { key: "quote", name: "Quote", closed: false },
  quotationSend: { key: "quotationSend", name: "Quotation Send", closed: false },
  negotiation: { key: "negotiation", name: "Negotiation", closed: false },
  won: { key: "won", name: "Won", closed: true },
  onHoldd: { key: "onHold", name: "On Hold", closed: true },
  lost: { key: "lost", name: "Lost", closed: true },
};

module.exports = {
  sellOrderMilestone,
  sellOrder,
  quotation,
};
