const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.currency = document?.currency;
    model.type = document?.type;
    model.date = document?.date;
    model.buy = document?.buy;
    model.sell = document?.sell;
    model.gd30 = document?.gd30;
    model.gd30d = document?.gd30d;
    model.price = document?.price;
  },
  code: "prices",
  singular: "Price",
  plural: "Prices",
  sortBy: (x) => x.date,
  sortDirection: "desc",
});
