import React, { useState } from "react";
import { differenceInCalendarDays, differenceInDays, startOfToday } from "date-fns";
import { useCandidates } from "../app/candidatesSlice";
import Due, { DueBox } from "../components/Due";
import { FollowUpTag, FollowUpModal } from "../components/FollowUp";
import { MergeBrowserPage } from "../components/v2/BrowserPage";
import Filter, { ButtonOptionFilter } from "../components/v2/Filter";
import { useAuth } from "../lib/auth";
import { buildOptions, merge, useOptions } from "../lib/misc";
import { CrudSellOrderMilestonModal } from "../modals/SellOrderMilestoneModal";
import SellOrderModal from "../modals/SellOrderModal";
import SellOrder from "../presentation/SellOrder";
import SellOrderMilestone from "../presentation/SellOrderMilestone";
import { hasRole } from "../presentation/User";
import styled from "styled-components";
import inboxEmpty from "../_assets/inbox-empty.jpg";
import { icons } from "@lainco/react-toolbox/dist/lib/Icons";
import { Common } from "@lainco/react-toolbox";
import { useUpdateFollowUp } from "../lib/services";
import { uniq } from "lodash";
import Amount from "../components/Amount";
import { Tag } from "../modals/SellOrderModal";

const dues = buildOptions(["name"], ["Overdue", "1 w", "1 m", "All"]);

const columns = {
  type: {
    title: "Type",
    width: "5%",
    content: {
      projectors: (x) =>
        x.type === "Sell Order" ? (
          <>
            <span>{x.type}</span>
            <SellOrderNumber>{x.number}</SellOrderNumber>
          </>
        ) : (
          <>
            <span style={{ color: "#777" }}>{x.type}</span>{" "}
            {x.number && (
              <InvoiceNumber>
                <InvoiceName>invoice</InvoiceName>
                <div />
                {x.number}
              </InvoiceNumber>
            )}
          </>
        ),
    },
  },
  name: {
    title: "Desciption",
    width: "37%",
    content: {
      projectors: (x) => (
        <>
          {x.name && (
            <>
              <span style={{ color: "#777" }}>{x.name}</span>
              <br />
            </>
          )}
          <span>{x.description}</span>
          {x.flags &&
            x.flags.map((f) => (
              <Tag style={{ marginLeft: "5px", verticalAlign: "middle" }} severity={f.severity}>
                {f.message}
              </Tag>
            ))}
        </>
      ),
    },
  },
  company: {
    title: "Company",
    width: "4%",
    align: "center",
    content: {
      projectors: (x) => merge(x?.company?.code, x?.beneficiary?.code),
    },
  },
  customer: {
    title: "Customer",
    width: "9%",
    content: { name: "customer.shortName" },
  },
  followUp: {
    title: "Next Task",
    width: "20%",
    content: {
      projectors: (x) => <FollowUpTag {...x.followUp} dueDate={null} />,
    },
  },
  amount: {
    title: "Amount",
    align: "right",
    content: {
      projectors: (x) => <Amount amount={x?.amount?.amount} currency={x?.amount?.currency} precision={0} />,
    },
  },
  actions: {
    title: "Required Actions",
    width: "30%",
    content: {
      projectors: (x) =>
        x.actions.map((x) => (
          <div style={{ marginBottom: "3px", marginTop: "3px" }}>
            <FollowUpTag hasFollowup={true} {...x} dueDate={null} />
          </div>
        )),
    },
  },
  status: {
    title: "Next Action",
    align: "center",
    content: {
      projectors: (x) => {
        if (x.type === "Sell Order")
          return (
            <div style={{ fontSize: "1.3em" }}>
              <DueBox width="6.15em" color="black" label={x.status} />
            </div>
          );
        else if (x.actions.length === 0) return <DueBox width="8em" color="#aaa" label={x.status} date={x?.invoiceDate} />;
        else {
          const delta = x?.invoiceDate ? differenceInDays(x.invoiceDate, startOfToday()) : null;
          return <Due width="8em" delta={delta} date={x?.invoiceDate} action={x.status} />;
        }
      },
    },
  },
  due: {
    title: "Next Follow Up",
    align: "center",
    content: {
      projectors: (x) =>
        x.followUp.hasFollowup && x.due ? <Due delta={differenceInCalendarDays(x.due, startOfToday())} width="7em" date={x.due} /> : null,
    },
  },
};

const typesCatalog = [
  {
    code: "sellOrderMilestones",
    type: SellOrderMilestone,
    Modal: CrudSellOrderMilestonModal,
    projector: (m, c) => m.getInboxItem(),
  },
  {
    code: "sellOrders",
    type: SellOrder,
    Modal: SellOrderModal,
    projector: (s, c) => s.getInboxItem(),
  },
];

const today = startOfToday();

export const InboxPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const updateFollowUp = useUpdateFollowUp();
  const auth = useAuth();
  const [selectedRows, setSelectedRows] = useState([]);
  const [followUp, setFollowUp] = useState({});
  const [users] = useCandidates("users");
  const [responsibles, responsiblesLoading] = useOptions(
    "users",
    [{ name: "All", shortName: "All" }],
    (x) => ({
      ...x,
      shortName: x.initials,
      name: x.initials,
    }),
    (x) => hasRole(x, "Project Leader") || hasRole(x, "Account Manager") || hasRole(x, "Administrative")
  );

  const multipleFollowUp = (selected) => {
    setFollowUp({
      hasFollowup: true,
      user: users?.find((x) => x.id === auth.uid),
    });
    setIsOpen(true);
  };

  const multipleFollowUpSave = (refresh) => {
    Promise.all(selectedRows.map((item) => updateFollowUp(item.id, item._type, followUp))).then((x) =>
      uniq(selectedRows.map((i) => i._type)).map((t) => refresh(t))
    );
  };

  return (
    <>
      <MergeBrowserPage
        allowMultiSelect={true}
        multipleSelection={selectedRows}
        onMultipleSelectionChange={setSelectedRows}
        actions={[
          <Action onClick={(x) => multipleFollowUp(selectedRows)}>
            <ActionIcon>{icons.alarmClock()}</ActionIcon>
            Follow Up
          </Action>,
        ]}
        columns={() => [
          columns.type,
          columns.company,
          columns.customer,
          columns.name,
          columns.amount,
          columns.due,
          columns.followUp,
          columns.status,
          columns.actions,
        ]}
        emptyContent={
          <Container>
            <Image src={inboxEmpty} alt="Great Job" />
            <TextContainer>
              <Message line={1}>You have no pendings</Message>
              <Message line={2}>Great Job!</Message>
              <Sign>Many thanks from the admin team</Sign>
            </TextContainer>
          </Container>
        }
        typesCatalog={typesCatalog}
        initialFilterState={{
          due: 1,
          responsible: auth.uid,
        }}
        minWidth="40em"
        filterFunction={(filter, item) => {
          const responsible = users?.find((u) => u.id === filter.responsible);
          const isAdministrative = responsible?.roles.find((r) => r === "Administrative");
          const isAccountManager = responsible?.roles.find((r) => r === "Account Manager");
          const isProjectLeader = responsible?.roles.find((r) => r === "Project Leader");

          return (
            (item.followUp?.hasFollowup || item.actions.length > 0) &&
            (filter.due === 4 ||
              item.due == null ||
              (filter.due === 1 && differenceInCalendarDays(today, item.due) >= 0) ||
              (filter.due === 2 && differenceInCalendarDays(today, item.due) >= -7) ||
              (filter.due === 3 && differenceInCalendarDays(today, item.due) >= -30)) &&
            (filter.responsible === 0 ||
              (item.followUp?.hasFollowup && item.followUp?.user?.id === filter.responsible) ||
              (!item.followUp?.hasFollowup &&
                (item.actions.filter((a) => a.user?.id === filter.responsible).length > 0 ||
                  (isAdministrative && item.actions.filter((a) => a.user == null && a.role.initials === "ADM").length > 0) ||
                  (isAccountManager && item.actions.filter((a) => a.user == null && a.role.initials === "A.M.").length > 0) ||
                  (isProjectLeader && item.actions.filter((a) => a.user == null && a.role.initials === "P.L.").length > 0))))
          );
        }}
        sortByFunction={(item) => (item.due ? item.due.getTime() : 0)}
        canEdit={true}
        Filter={
          <Filter fontSize="0.5em">
            <div />
            <ButtonOptionFilter header="Duedate" name="due" options={dues} />
            <ButtonOptionFilter header="Responsible" name="responsible" options={responsibles} loading={responsiblesLoading} />
          </Filter>
        }
        modals={({ refresh }) => (
          <FollowUpModal
            isOpen={isOpen}
            onRequestClose={(x) => setIsOpen(false)}
            model={followUp}
            onChange={setFollowUp}
            onAccept={(x) => {
              multipleFollowUpSave(refresh);
              setIsOpen(false);
              setSelectedRows([]);
            }}
          />
        )}
      />
    </>
  );
};

const Container = styled.div`
  display: grid;
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100vh - 11em);
`;

const TextContainer = styled.div`
  position: absolute;
  height: calc(100vh - 11em);
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
`;

const Sign = styled.div`
  position: absolute;
  color: #842;
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: "Times New Roman", Times, serif;
  left: 2em;
  right: 2em;
  bottom: 0.5em;
`;

const Message = styled.div`
  position: relative;
  top: calc(calc(${(props) => props.line * 1} / 3) * calc(100vh - 11em));
  color: #4a2b18;
  font-size: 2em;
  font-family: "Times New Roman", Times, serif;
`;

const Action = styled(Common.Link)`
  display: flex;
  align-items: center;
  gap: 1em;
  margin-left: 0;
`;

const SellOrderNumber = styled.div`
  padding: 0.1em 0.3em;
  background: #000;
  color: white;
  font-size: 0.8em;
  border-radius: 0.3em;
`;

const InvoiceNumber = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.2em;
  padding: 0.1em 0.3em;
  background: #999;
  color: white;
  font-size: 0.7em;
  border-radius: 0.3em;
`;

const InvoiceName = styled.div`
  text-transform: uppercase;
  align-self: center;
`;

const ActionIcon = styled(Common.Link)`
  &&& {
    font-size: 1.7em;
    margin-left: 0;
  }
`;
