const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.name = document?.name;
    model.currency = document?.currency;
    model.priceType = document?.priceType;
    model.index = document?.index;
  },
  code: "adjustmentTypes",
  singular: "Adjustment Type",
  plural: "Adjustment Types",
});
