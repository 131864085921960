const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.code = document?.code || "";
    model.name = document?.name;
  },
  code: "priceTypes",
  singular: "Price Type",
  plural: "Price Types",
});
