import React, { useState } from "react";
import { Common, Input, Select, SelectButton, Label } from "@lainco/react-toolbox";
import { injectAll } from "../../lib/misc";
import styled from "styled-components";
import GroupByModal, { getNameOfModel } from "../../modals/GroupByModal";

export default function Filter({ model, onChange, children, fontSize }) {
  const _children = children.flat();
  return (
    <Common.Row
      gap="2em"
      fontSize={fontSize || "1em"}
      cols={_children && _children.length > 1 ? `1fr repeat(${_children.length - 1}, auto)` : "1fr"}
    >
      {injectAll(_children, { model, onChange })}
    </Common.Row>
  );
}

export function TextFilter({ header, model, name, onChange, width }) {
  return (
    <Label text={header}>
      <div style={{ zoom: 0.6, width: width ?? "23em" }}>
        <Input value={model[name]} placeholder="Search..." onChange={(x) => onChange && onChange({ ...model, [name]: x })} outline={true} />
      </div>
    </Label>
  );
}

export function GroupByFilter({ header, model, name, onChange, fields }) {
  const [isOpen, setIsOpen] = useState(false);
  const [editing, setEditing] = useState(null);

  return (
    <div className="SARAZA">
      <GroupByModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        model={editing}
        onChange={setEditing}
        favourites={[
          ["country"],
          ["country", "project"],
          ["country", "project", "company"],
          ["country", "project", "beneficiary"],
          ["country", "project", "category"],
          ["project"],
          ["project", "company"],
          ["project", "beneficiary"],
          ["project", "category"],
        ]}
        onAccept={(value) => {
          onChange?.({ ...model, [name]: { value, name: getNameOfModel(value, fields) } });
          setIsOpen(false);
        }}
        fields={fields}
      />
      <Label text={header}>
        <div style={{ display: "flex", gap: "0.5em", fontSize: "1.6em", alignItems: "baseline" }}>
          <Text>{model[name].name}</Text>
          <ShowModal
            onClick={() => {
              setEditing(model[name].value);
              setIsOpen(true);
            }}
          >
            ...
          </ShowModal>
        </div>
      </Label>
    </div>
  );
}

export function SelectOptionFilter({ header, model, name, onChange, options, loading, showValue }) {
  return (
    <Label text={header}>
      <Select
        value={model ? model[name] : null}
        options={options || []}
        loading={loading}
        showValue={showValue}
        onChange={(x) => onChange && onChange({ ...model, [name]: x })}
      />
    </Label>
  );
}

export function ButtonOptionFilter({ header, model, name, onChange, options, disabled, showValue }) {
  const _disabled = disabled ? (typeof disabled === "function" ? disabled(model) : disabled) : false;
  return (
    <Label text={header}>
      <SelectButton
        type="buttons"
        value={_disabled ? null : model ? model[name] : null}
        options={options || []}
        onChange={(x) => onChange && onChange({ ...model, [name]: x })}
        showValue={showValue}
        readOnly={_disabled}
      />
    </Label>
  );
}

const Text = styled.span`
  font-weight: 600;
  font-size: 0.9em;
`;

const ShowModal = styled.div`
  width: 1.5em;
  height: 1.5em;
  border-radius: 33%;
  border: 1px solid #ccc;
  padding: 0.3em;
  text-align: center;
  cursor: pointer;
`;
