import React, { useState, useEffect } from "react";
import { Common, Table } from "@lainco/react-toolbox";
import styled from "styled-components";
import { useGet, useGetById } from "../lib/services";

export function PureBrowserPage({
  title,
  name,
  items,
  loading,
  cols,
  selectedOption,
  onSelectedOptionChange,
  options,
  searchText,
  onSearchTextChange,
  stats,
  filters,
  addEnabled,
  editEnabled,
  onAddRequested,
  onEditRequested,
}) {
  return (
    <Common.Col rows="auto auto 1em auto auto 1fr">
      {stats ? stats : <div />}
      {filters || <div />}
      <div />
      <Title>
        {addEnabled && (
          <Link onClick={() => onAddRequested && onAddRequested()} fontSize="0.7em" space="medium">
            Add {name}
          </Link>
        )}
      </Title>
      {/* <Common.Row cols="1fr 20em" gap="1em">
        <Input value={searchText} onChange={onSearchTextChange} placeholder="Search..." />
        <Select value={selectedOption} options={options} onChange={onSelectedOptionChange} />
      </Common.Row> */}
      <Table
        loading={loading}
        items={items || (loading ? [null, null, null] : [])}
        columns={cols}
        onRowClick={editEnabled ? (x) => onEditRequested && onEditRequested(x) : null}
        overlayBackground="#eee"
      />
    </Common.Col>
  );
}

export default function BrowserPage({ Modal, type }) {
  const [tag, setTag] = useState(0);
  const [filter, setFilter] = useState({ search: "", option: null, ...type?.metadata?.filterTemplate });
  const [items, setItems] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [model, setModel] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(null);
  const code = type.metadata.url("");
  const get = useGet(type.metadata.url(""), type);
  const getById = useGetById(type.metadata.url(""));

  useEffect(() => {
    setItems(null);
    setLoading(true);
    get(type.metadata.queryParams(filter), (x) => {
      setItems(x);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, code, tag]);

  const requireRefresh = () => setTag(tag + 1);
  const requireAdd = () => {
    setIsNew(true);
    setModel(type.metadata.create());
    setIsOpen(true);
  };
  const requireEdit = (x) => {
    setIsNew(false);
    setModel(null);
    setIsOpen(true);
    getById(x.id, (res) => setModel(new type(res)));
  };

  return (
    <>
      <PureBrowserPage
        title={type.metadata.name.plural}
        name={type.metadata.name.singular}
        items={items}
        loading={loading}
        cols={type.metadata.columns}
        selectedOption={filter.option}
        onSelectedOptionChange={(x) => setFilter({ ...filter, option: x })}
        options={filter.options}
        searchText={filter.search}
        onSearchTextChange={(x) => setFilter({ ...filter, search: x })}
        addEnabled={type.metadata.canAdd}
        editEnabled={type.metadata.canEdit}
        onAddRequested={(x) => requireAdd()}
        onEditRequested={(x) => {
          requireEdit(x);
        }}
      />
      <Modal
        loading={model == null}
        isOpen={isOpen}
        isNew={isNew}
        model={model}
        onChange={(x) => {
          if (typeof x === "function") setModel((y) => new type(x(y)));
          else setModel(new type(x));
        }}
        onRefreshRequested={requireRefresh}
        onRequestClose={() => setIsOpen(false)}
      />
    </>
  );
}

const Title = styled.h1`
  display: grid;
  justify-content: end;
  font-size: 0.5em;
  color: #777;
  padding: 1em 1em 0 1em;
`;

const Link = styled(Common.Link)`
  &&&& {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 0;
  }
`;
