import React from "react";
import { ModalBody, Common, Label, Input } from "@lainco/react-toolbox";
import Currency from "../model/Currency";
import { withCrudModal } from "../components/Modal";
import { Checkbox } from "@lainco/react-toolbox/dist/components/Checkbox";
import { PureAmount } from "../components/Amount";

export function PureCurrencyModalContent({ model, onChange }) {
  return (
    <ModalBody>
      <Common.Col rows="auto auto auto auto" gap="1em">
        <Common.Row cols="1fr 1fr 1fr" gap="1em">
          <Label text="Code">
            <Input value={model.code} onChange={(x) => onChange({ ...model, code: x })} />
          </Label>
          <Label text="Symbol">
            <Input value={model.symbol} onChange={(x) => onChange({ ...model, symbol: x })} />
          </Label>
          <Label text="Id">{model.id}</Label>
        </Common.Row>
        <Common.Row cols="2fr 2fr 3fr" gap="1em">
          <Label text="Name">
            <Input value={model.name} onChange={(x) => onChange({ ...model, name: x })} />
          </Label>
          <Label text="Precision">
            <Input value={model.precision} onChange={(x) => onChange({ ...model, precision: x })} />
          </Label>
          <Label text="Color">
            <Input value={model.color} onChange={(x) => onChange({ ...model, color: x })} />
          </Label>{" "}
        </Common.Row>
        <Common.Row cols="1fr 1fr" gap="1em">
          <Label text="style">
            <Common.Row cols="auto auto" style={{ width: "fit-content" }}>
              <Checkbox checked={model.style === "tag"} onChange={(x) => onChange({ ...model, style: "tag" })} />
              <PureAmount
                isTag={false}
                currency={{ color: model.color, symbol: model.symbol, precision: model.precision, style: "tag" }}
                amount="10000.123456789"
                onlySymbol={true}
              />
            </Common.Row>
          </Label>
          <Label text="style">
            <Common.Row cols="auto auto" style={{ width: "fit-content" }}>
              <Checkbox checked={model.style === "no-tag"} onChange={(x) => onChange({ ...model, style: "no-tag" })} />
              <PureAmount
                isTag={false}
                currency={{ color: model.color, symbol: model.symbol, precision: model.precision, style: "no-tag" }}
                amount="10000.123456789"
                onlySymbol={true}
              />
            </Common.Row>
          </Label>
        </Common.Row>
      </Common.Col>
    </ModalBody>
  );
}

export default withCrudModal(PureCurrencyModalContent, Currency, "md", false);
