const buildType = require("./models");

const User = buildType({
  initializer: (model, document) => {
    model.name = document?.name;
    model.initials = document?.initials;
    model.mail = document?.mail;
    model.photo = document?.photo;
    model.roles = document?.roles;
    model.managerOf = document?.managerOf;
    model.enabled = document?.enabled;
    model.labs = document?.labs || [];
  },
  code: "users",
  singular: "User",
  plural: "Users",
});

User.prototype.hasRole = function (role) {
  return this.roles?.filter((r) => r === role)?.length > 0;
};

module.exports = User;
