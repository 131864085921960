import AdjustmentType from "../model/AdjustmentType";
import { addPresentation } from "./presentation";

export default addPresentation(AdjustmentType, {
  canAdd: true,
  canEdit: true,
  canRemove: true,
  columns: [
    "Name",
    { title: "Currency", width: "20%", align: "left", content: { name: "currency.name" } },
    { title: "Type", content: { name: "priceType.code" } },
    { title: "Index", width: "30%", align: "left", content: { name: "index.name" } },
  ],
});
