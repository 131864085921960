const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.code = document?.code || null;
    model.name = document?.name;
    model.symbol = document?.symbol;
    model.precision = document?.precision;
    model.color = document?.color;
    model.style = document?.style;
  },
  code: "currencies",
  singular: "Currency",
  plural: "Currencies",
});
