import { camelCase } from "lodash";

export const addPresentation = (Type, { sort, canAdd, canEdit, canRemove, columns, actions, afterGet }) => {
  Type.metadata = {
    ...Type.metadata,
    canAdd,
    sort: sort || ((a, b) => a?.name?.localeCompare(b.name)),
    canEdit,
    canRemove,
    title: (isNew) => (isNew ? `Create ${Type.metadata.name.singular}` : `Edit ${Type.metadata.name.singular}`),
    filterTemplate: { options: [] },
    columns: Array.isArray(columns)
      ? columns.map((x) => (typeof x === "string" ? { title: x, content: { name: camelCase(x) } } : x))
      : columns,
    afterGet,
    actions,
  };

  return Type;
};
