import React, { useEffect } from "react";
import { useConfidentiality } from "@lainco/react-toolbox/dist/components/confidentiality/confidentiality";
import styled from "styled-components";
import { icons } from "@lainco/react-toolbox/dist/lib/Icons";
import { formatCountdown } from "../lib/misc";

export default function ConfidentialContainer({ children }) {
  const { enabled, masked, focusLostCountdown, locked, unlock, gotFocus, lostFocus, hasActivity } = useConfidentiality();

  useEffect(() => {
    if (enabled) {
      window.addEventListener("blur", lostFocus);
      window.addEventListener("focus", gotFocus);
      document.addEventListener("visibilitychange", (e) => {
        if (document.visibilityState === "hidden") lostFocus();
      });
      /*
      document.onmousemove = function (e) {
        hasActivity();
      };*/
      document.onkeydown = function (e) {
        hasActivity();
      };
      document.onclick = function (e) {
        hasActivity();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {children}
      <Overlay visible={masked && !locked}>
        {focusLostCountdown < 60 && (
          <Countdown>
            <span style={{ width: "2.9em" }}>{formatCountdown(focusLostCountdown)}</span>
            <div className="normal" style={{ fontSize: "0.85em" }}>
              {icons.solid.unlock()}
            </div>
          </Countdown>
        )}
      </Overlay>
      <Overlay visible={locked}>
        <UnlockButton onClick={() => unlock()}>
          <div className="normal">{icons.solid.lock()}</div>
          <div className="hover">{icons.solid.unlock()}</div>
        </UnlockButton>
        <Text>Information has been masked for security reasons</Text>
        <Text>Press the lock button to show it</Text>
      </Overlay>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const UnlockButton = styled.div`
  font-size: 4em;
  color: #999;
  background: rgba(230, 230, 230, 0.5);
  display: flex;
  border-radius: 3em;
  align-content: center;
  width: 1.9em;
  height: 1.9em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  & > .normal,
  &:hover > .hover {
    display: grid;
  }

  &:hover > .normal,
  & > .hover {
    display: none;
    color: #555;
  }
`;

const Countdown = styled.div`
  font-size: 6em;
  color: #aaa;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  gap: 0.5em;
  border-radius: 3em;
  align-content: center;
  padding: 0.5em 0.7em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const Text = styled.div`
  font-size: 2em;
  color: #999;
  margin-bottom: 0.3em;
`;

const Overlay = styled.div`
  position: absolute;
  display: ${(props) => (props.visible ? "grid" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: -10px;
  backdrop-filter: blur(10px);
  z-index: 10000;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
`;
