import React from "react";
import { Common } from "@lainco/react-toolbox";
import { useCandidates } from "../app/candidatesSlice";
import Tag from "./Tag";
import styled from "styled-components";

export function PureAmount({ currency, amount, currencies, onlySymbol, precision }) {
  const _currency = (currencies || []).find((c) => c.id === currency?.id) || currency || {};
  const isTag = _currency.style === "tag";
  const currencyPrecision = Math.max(0, Math.min(8, isNaN(parseFloat(_currency.precision)) ? 0 : parseFloat(_currency.precision)));
  const _precision = precision == null ? currencyPrecision : precision;
  const _amount = isNaN(parseFloat(amount))
    ? "-"
    : parseFloat(amount).toLocaleString(undefined, {
        minimumFractionDigits: _precision,
        maximumFractionDigits: _precision,
      }) || "-";
  if (isTag && onlySymbol)
    return (
      <Common.Row cols="auto auto" gap="0.4em" style={{ width: "fit-content", alignItems: "center" }}>
        <Tag fontSize="1.2em" fontWeight="normal" color={_currency.color}>
          {_currency.symbol}
        </Tag>
        {_amount}
      </Common.Row>
    );
  else if (isTag && !onlySymbol)
    return (
      <Tag fontSize="1.7em" fontWeight="normal" color={_currency.color}>
        <div style={{ padding: "0 0.3em" }}>
          {_currency.symbol} {_amount}
        </div>
      </Tag>
    );
  else if (!isTag && onlySymbol)
    return (
      <Common.Row cols="auto auto" gap="0.4em" style={{ width: "fit-content", alignItems: "center" }}>
        <WithColor color={_currency.color}>{_currency.symbol}</WithColor>
        {_amount}
      </Common.Row>
    );
  else
    return (
      <WithColor color={_currency.color}>
        {_currency.symbol} {_amount}
      </WithColor>
    );
}

export default function Amount({ currency, amount, precision }) {
  const [currencies] = useCandidates("currencies");

  return <PureAmount {...{ currencies, currency, amount, precision, onlySymbol: true }} />;
}

const WithColor = styled.div`
  color: ${(props) => props.color};
  font-weight: 600;
`;
