import React from "react";
import BrowserPage from "../components/v2/BrowserPage";
import Filter, { SelectOptionFilter } from "../components/v2/Filter";
import { useOptions } from "../lib/misc";
import { NameCountryModal } from "../modals/NameCountryModal";
import Price from "../presentation/Price";

// CONTROL
export const PricesPage = () => {
  const [currencieOptions, currencieOptionsLoading] = useOptions("currencies", [{ name: "All Currencies", shortName: "All" }], (x) => ({
    ...x,
    shortName: x.symbol,
  }));
  const [priceTypeOptions, priceTypesOptionsLoading] = useOptions("priceTypes", [{ name: "All Types", shortName: "All" }], (x) => ({
    ...x,
    name: x.code,
    shortName: x.code,
  }));

  const columns = Price.metadata.columns;

  return (
    <BrowserPage
      Modal={NameCountryModal(Price, true)}
      columns={() => [columns.date, columns.currency, columns.type, columns.extra1, columns.extra2, columns.price]}
      type={Price}
      initialFilterState={{
        currency: 0,
        priceType: 0,
      }}
      minWidth="40em"
      filterFunction={(filter, item) => {
        return (
          (filter.priceType === 0 || item?.type?.id === filter.priceType) &&
          (filter.currency === 0 || item?.currency?.id === filter.currency)
        );
      }}
      Filter={
        <Filter fontSize="0.5em">
          <div />
          <SelectOptionFilter header="Currency" name="currency" options={currencieOptions} loading={currencieOptionsLoading} />
          <SelectOptionFilter
            header="Type"
            name="priceType"
            options={priceTypeOptions}
            loading={priceTypesOptionsLoading}
            showValue="shortName"
          />
        </Filter>
      }
    />
  );
};
