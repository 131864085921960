import React from "react";
import { ModalBody, Common, Label, Input } from "@lainco/react-toolbox";
import { withCrudModal } from "../components/Modal";
import List from "../components/List";

export function PureNameModalContent({ model, onChange, hasItems }) {
  return (
    <ModalBody>
      <Common.Col rows={model.items ? "auto 1fr" : "1fr"} gap="1em">
        <Common.Row cols={model.code !== undefined ? "1fr 6fr 2fr" : "4fr 1fr"} gap="1em">
          {model.code !== undefined && (
            <Label text="Code">
              <Input value={model.code} onChange={(x) => onChange({ ...model, code: x })} />
            </Label>
          )}
          <Label text="Name">
            <Input value={model.name} onChange={(x) => onChange({ ...model, name: x })} />
          </Label>
          <Label text="Id">{model.id}</Label>
        </Common.Row>
        {model.items && (
          <List
            items={model.items || []}
            onChange={(x) => onChange({ ...model, items: x })}
            label="Subcategories"
            initializer={() => ""}
            wrapper={(x, i) => (
              <Input
                value={x}
                onChange={(x) => onChange({ ...model, items: [...model.items.slice(0, i), x, ...model.items.slice(i + 1)] })}
              />
            )}
          />
        )}
      </Common.Col>
    </ModalBody>
  );
}

export default function NameModal(type) {
  return withCrudModal(PureNameModalContent, type, "lg", false);
}
