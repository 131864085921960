import React from "react";
import { ModalBody, Common, Label, Input } from "@lainco/react-toolbox";
import { withCrudModal } from "../components/Modal";
import Terms from "../model/Terms";

export function TermsModalContent({ model, onChange, hasItems }) {
  return (
    <ModalBody>
      <Common.Row cols="3fr 1fr 1fr" gap="1em">
        <Label text="Name">
          <Input value={model.name} onChange={(x) => onChange({ ...model, name: x })} />
        </Label>
        <Label text="Days">
          <Input value={model.days} onChange={(x) => onChange({ ...model, days: x })} />
        </Label>
        <Label text="Id">{model.id}</Label>
      </Common.Row>
    </ModalBody>
  );
}

export default withCrudModal(TermsModalContent, Terms, "md", false);
