import { createSlice } from "@reduxjs/toolkit";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGet } from "../lib/services";
import Base from "../model/Base";

export const browsersSlice = createSlice({
  name: "browsers",
  initialState: {},
  reducers: {
    // Si no hay datos cargados, pone en loading toda la lista, sino la deja como está
    startGet: {
      prepare: (code) => ({ payload: { code } }),
      reducer: (state, action) => {
        const { code } = action.payload;
        if (!state[code]?.fetching) {
          state[code] = { values: undefined, loading: true, fetching: false };
        }
      },
    },
    // Agrega en loadign el item a la lista mientras se recarga la misma
    startPost: {
      prepare: (code, model) => ({ payload: { code, model } }),
      reducer: (state, action) => {
        const { code, model } = action.payload;

        // Actualizo el estado para mostrar que está haciendo fetch al server
        if (!state[code]) state[code] = { values: [], loading: false, fetching: true };
        else state[code].fetching = true;

        // Hago un optimistic update, para mostrar el cambio hasta tanto el server se actualice
        if (state[code].values) state[code].values.push({ ...model, version: (model.version || 0) + 1, _loading: true });
        else state[code].values = [{ ...model, version: (model.version || 0) + 1, _loading: true }];
      },
    },
    startUpdateFollowUp: {
      prepare: (code, id, followUp) => ({ payload: { code, id, followUp } }),
      reducer: (state, action) => {
        const { code, id, followUp } = action.payload;

        if (!state[code]) state[code] = { values: [], loading: false, fetching: true };
        else state[code].fetching = true;

        const index = state[code].values.findIndex((x) => x.id === id);
        const model = state[code].values.find((x) => x.id === id);
        if (typeof followUp.dueDate === "object") followUp.dueDate = followUp.dueDate.toISOString();
        if (index !== -1) state[code].values[index] = { ...model, version: (model.version || 0) + 1, _loading: true, followUp };
      },
    },
    // Actualiza y deja en loading el item de la lista mientras se recarga la misma
    startPut: {
      prepare: (code, model) => ({ payload: { code, model } }),
      reducer: (state, action) => {
        const { code, model } = action.payload;

        if (!state[code]) state[code] = { values: [], loading: false, fetching: true };
        else state[code].fetching = true;

        const index = state[code].values.findIndex((x) => x.id === model.id);
        if (index !== -1)
          state[code].values[index] = {
            ...model,
            version: (model.version || 0) + 1,
            _loading: true,
          };
        else state[code].values.push({ ...model, _loading: true });
      },
    },
    startDelete: {
      // Elimna el item de la lista mientras se recarga la misma
      prepare: (code, id) => ({ payload: { code, id } }),
      reducer: (state, action) => {
        const { code, id } = action.payload;

        if (!state[code]) state[code] = { values: [], loading: false, fetching: true };
        else state[code].fetching = true;

        const index = state[code].values.findIndex((x) => x.id === id);
        if (index !== -1) state[code].values.splice(index, 1);
      },
    },
    // Actualizo la lista cuando obtengo los datos de la base
    get: {
      prepare: (code, values) => ({ payload: { code, values } }),
      reducer: (state, action) => {
        const { code, values } = action.payload;
        const oldValues = state[code]?.values;

        const items = values?.map((n) => {
          const oldItem = oldValues?.find((o) => n.id === o.id);
          if (oldItem != null && ((oldItem.version == null && n.version === 0) || oldItem.version > n.version)) {
            return { ...oldItem };
          } else return n;
        });

        state[code].values = items;
        state[code].fetching = false;
        state[code].loading = false;
      },
    },
  },
});

export const { startGet, startPost, startPut, startDelete, get, startUpdateFollowUp } = browsersSlice.actions;

export function useItems(code) {
  const state = useSelector((state) => state.browsers[code]);
  const { values, loading, fetching } = state || {
    values: null,
    loading: true,
    fecthing: false,
  };
  const Type = Base.types[code];
  const get = useGet(`${code}`, Type);
  if (Type == null) console.warn(`useItems call for unregistered type ${code}`);

  const items = useMemo(() => {
    if (loading) return null;

    return values.map((p) => {
      return Type != null ? new Type(Type.metadata.afterGet ? Type.metadata.afterGet({ ...p }) : p) : p;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, loading]);

  useEffect(() => {
    //Si no está cargado, veo de disparar el get

    if (state === undefined && Type !== undefined) {
      get({}, (x) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [Type?.metadata?.sort && items ? items.sort(Type.metadata.sort) : items, loading, fetching];
}

export default browsersSlice.reducer;
