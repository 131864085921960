import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@lainco/react-toolbox";
import { differenceInMilliseconds } from "date-fns";
import { useGet, usePut } from "../lib/services";
import SellOrder from "../model/SellOrder";
import FSellOrder from "../model/FSellOrder";

const useTest = (initialText, f) => {
  const [running, setRunning] = useState(false);
  const [text, setText] = useState(initialText);
  const [total, setTotal] = useState(null);
  const [value, setValue] = useState(null);

  const dispatch = async () => {
    const start = new Date();
    setRunning(true);
    const result = await f(setTotal, setValue);
    const diff = differenceInMilliseconds(new Date(), start);
    setRunning(false);
    setText(`${result} in ${diff.toLocaleString()}ms`);
  };

  return { text, running, value, total, dispatch };
};

const TestComponent = ({ name, initialText, f }) => {
  const { text, running, value, total, dispatch } = useTest(initialText, f);

  return (
    <Test>
      <Name>{name}</Name>
      <Button onClick={dispatch} disabled={running}>
        Start
      </Button>
      {running && total == null ? (
        <span>Running...</span>
      ) : running && total != null ? (
        <span>
          Running, {value} of {total}
        </span>
      ) : (
        <div>{text}</div>
      )}
    </Test>
  );
};

export function TestSpeedPage() {
  const getOld = useGet(FSellOrder.metadata.code, FSellOrder);
  const getNew = useGet(SellOrder.metadata.code, SellOrder);
  const putNew = usePut(SellOrder.metadata.code, SellOrder);

  const readOldModel = async (setTotal, setValue) => {
    return new Promise((resolve) => {
      getOld({}, (x) => resolve(`${x.length} records read`));
    });
  };

  const readNewModel = async (setTotal, setValue) => {
    return new Promise((resolve) => {
      getNew({}, (x) => resolve(`${x.length} records read`));
    });
  };

  const migrate = async (setTotal, setValue) => {
    return new Promise((resolve) => {
      getOld({}, (x) => {
        setValue(0);
        setTotal(x.length);
        let i = 0;
        x.forEach((item) => {
          const newItem = new FSellOrder(item);
          putNew(newItem.id, newItem, () => {
            setValue((v) => {
              if (v === x.length - 1) resolve(`${i} records migrated`);
              return v + 1;
            });
          });
        });
      });
    });
  };

  return (
    <Container>
      <TestComponent name="Read using old model" initialText="-" f={readOldModel} />
      <TestComponent name="Read using new model" initialText="-" f={readNewModel} />
      <TestComponent name="Migrate" initialText="-" f={migrate} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
  gap: 1em;
`;

const Test = styled.div`
  display: grid;
  gap: 1em;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  padding: 1em;
  border-radius: 1em;
  background-color: #eee;
`;

const Name = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #777;
`;
