import React from "react";
import styled from "styled-components";

const severities = {
  normal: "#ccc",
  info: "#44c",
  warning: "#ec8",
  critical: "red",
  error: "orange",
};

const addPx = (value) => (typeof value === "number" ? `${value}px` : value);

export default function Tag({ fontSize, allCaps, fontWeight, color, severity, width, label, children }) {
  let background = severities[severity];
  if (color) background = color;
  if (!background) background = severities.normal;
  const foreground = "white";
  const hasLabel = label && label !== "";

  return (
    <StyledTag fontSize={fontSize} background={background} foreground={foreground} severity={severity} width={width}>
      {hasLabel && <Label>{label}</Label>}
      <Content hasLabel={hasLabel} fontWeight={fontWeight}>
        {children}
      </Content>
    </StyledTag>
  );
}

const StyledTag = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: ${(props) => (typeof props.fontSize ? addPx(props.fontSize) : "1em")};
  border-radius: 0.4em;
  background: ${(props) => props.background};
  color: ${(props) => props.foreground};
  ${(props) => (props.width ? `width: ${addPx(props.width)};` : "")}
`;

const Content = styled.div`
  font-size: 0.6em;
  padding: 0.3em 0.5em;
  font-weight: ${(props) => props.fontWeight || "700"};
  text-transform: ${(props) => (props.allCaps ? "uppercase" : "unset")};
  line-height: 1;
  ${(props) => props.hasLabel && "padding-top: 0.1em;"}
  text-align: center;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 0.35em;
  padding-top: 0.5em;
  text-align: center;
`;
