import IncomeCategory from "../model/IncomeCategory";
import { addPresentation } from "./presentation";

export default addPresentation(IncomeCategory, {
  canAdd: true,
  canEdit: true,
  canRemove: false,
  singular: "Income Category",
  plural: "Income Categories",
  columns: ["Name"],
});
