import { configureStore } from "@reduxjs/toolkit";
import candidatesReducer from "./candidatesSlice";
import browsersSlice from "./browsersSlice";

export default configureStore({
  reducer: {
    candidates: candidatesReducer,
    browsers: browsersSlice,
  },
});
