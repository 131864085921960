const buildType = require("./models");
const { formatISO } = require("date-fns");

const ACTIVITY_TYPES = {
  CREATE: "create",
  UPDATE: "update",
  CANCEL: "cancel",
  CLOSE: "close",
  STATUS_CHANGE: "status-change",
  REMOVE: "remove",
  FOLLOWUP: "follow-up",
  ADD_ATTACHEMENT: "add-attachment",
  REMOVE_ATTACHMENT: "remove-attachment",
};

const Activity = buildType({
  initializer: (model, document) => {
    model.model_type = document?.model_type;
    model.activity_type = document?.activity_type;
    model.name = document?.name;
    model.summary = document?.summary;
    model.timestamp = document?.timestamp;
    model.user = document?.user;
    model.reference = document?.reference;
    model.comments = document?.comments;
    model.followUp = document?.followUp;
  },
  code: "activity",
});

Activity.ACTIVITY_TYPES = ACTIVITY_TYPES;

const build = (activityType, user, summary, metadata, model, args) => {
  const activity = new Activity({
    model_type: { code: model.$type, name: metadata.name },
    activity_type: activityType,
    timestamp: formatISO(Date.now()),
    name: model.name,
    summary,
    user: { id: user.user_id, name: user.name, photo: user.picture },
    reference: metadata.getLink(model.id),
    ...args,
  });
  return activity;
};

Activity.create = function (user, metadata, previous, newOne) {
  const comments = newOne.comments?.reverse();
  const followUp = { previous: null, newOne: newOne.followUp };
  const activity = build(ACTIVITY_TYPES.CREATE, user, `Created ${metadata.name.singular}`, metadata, newOne, {
    comments,
    followUp,
  });
  return activity;
};

Activity.modify = function (user, metadata, previous, newOne) {
  const comments =
    previous.comments?.length < newOne.comments?.length
      ? newOne.comments?.slice(0, newOne.comments.length - previous.comments.length).reverse()
      : null;
  const followUp = { previous: previous.followUp, newOne: newOne.followUp };

  if (newOne.status && newOne.status !== previous.status) {
    if (newOne.status === "canceled") {
      return build(ACTIVITY_TYPES.CANCEL, user, "Cancel", metadata, newOne, { comments, followUp });
    } else if (newOne.status === "closed") {
      return build(ACTIVITY_TYPES.CLOSE, user, "Closed", metadata, newOne, { comments, followUp });
    } else {
      return build(
        ACTIVITY_TYPES.STATUS_CHANGE,
        user,
        `Changed state from '${previous.getStatus()}' to '${newOne.getStatus()}'`,
        metadata,
        newOne,
        { comments, followUp }
      );
    }
  } else {
    return build(ACTIVITY_TYPES.UPDATE, user, `modified ${metadata.name.singular}`, metadata, newOne, {
      comments,
      followUp,
    });
  }
};

Activity.addAttachment = function (user, metadata, model, attachment) {
  return build(ACTIVITY_TYPES.ADD_ATTACHEMENT, user, "added attachment", metadata, model, { attachment });
};

Activity.addFollowUp = function (user, metadata, model, followUp, newComment) {
  const args = {
    comments: newComment ? [newComment] : null,
    followUp: { previous: model.followUp, newOne: followUp },
  };

  return build(ACTIVITY_TYPES.FOLLOWUP, user, newComment ? "logged activity" : "changed follow up", metadata, model, args);
};

module.exports = Activity;
