import React from "react";
import styled from "styled-components";

export default function ProgressBar({ percentage, min, max, value, color, background, animate }) {
  let p;
  if (percentage) p = percentage;
  else if (!min || !max) p = (value / max) * 100;
  else p = ((value - min) / (max - min)) * 100;

  if (p > 100) p = 100;
  else if (p < 0) p = 0;

  return (
    <Backbar color={background} animate={animate}>
      <Progress percentage={p} color={color} />
    </Backbar>
  );
}

ProgressBar.defaultProps = {
  min: 0,
  max: 100,
  color: "#6fb8cc",
  background: "#ccc",
  animate: true,
};

const Backbar = styled.div`
  width: 100%;
  height: 3px;
  background: ${(props) => props.color};
  ${(props) => props.animate && `animation: in-progress 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;`}
`;

const Progress = styled.div`
  width: ${(props) => props.percentage}%;
  height: 3px;
  background: ${(props) => props.color};
`;
