import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Common } from "@lainco/react-toolbox";
import styled from "styled-components";
import { usePost, usePut } from "../lib/services";
import { FirebaseStorageAttachments } from "./Attachments";
import toast from "react-hot-toast";
import { addComment, buildComment, ListOfComments } from "./Comments";
import { useUser } from "../lib/userContext";
import { stringify } from "flatted";

const SaveDiscardModal = ({ isOpen, onRequestClose, onDiscard, onSave }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} zoom={1} width="md">
      <ModalHeader title="Warning" onClosePressed={onRequestClose} />
      <ModalBody>
        <MessageContainer>
          <Message>There are unsaved changes.</Message>
          <Message>Do you want to discard them?</Message>
        </MessageContainer>
      </ModalBody>
      <ModalFooter>
        <Button onClick={(x) => onRequestClose && onRequestClose()} type="cancel">
          Cancel
        </Button>
        <Button onClick={(x) => onDiscard && onDiscard()} background="#7c3b3b" color="white">
          Discard
        </Button>
        <Button onClick={(x) => onSave && onSave()}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

const sizes = { sm: "20em", md: "35em", lg: "50em", xl: "76em" };

export const withCrudModal =
  (ModalContent, type, size, hasAttachments, props, hasComments, warningOnClose) =>
  ({ loading, model, isNew, onChange, isOpen, onRequestClose, onRefreshRequested }) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [initial, setInitial] = useState();
    const user = useUser();
    const width = sizes[size] || sizes["md"];
    const code = type.metadata.url("");
    const post = usePost(code, type);
    const put = usePut(code, type);

    useEffect(() => {
      setInitial(model != null ? stringify(model) : null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const save = () => {
      if (isNew) post(model, (x) => onRefreshRequested());
      else put(model.id, model, (x) => onRefreshRequested());
      onRequestClose();
    };

    const shareLink = () => {
      const url = `${window.origin}${type.metadata.getLink(model.id)}`;
      navigator.clipboard.writeText(url);
      toast.success("Link copied");
    };

    const tryClose = () => {
      const newOne = stringify(model);
      if (warningOnClose && newOne !== initial) setIsConfirmOpen(true);
      else onRequestClose && onRequestClose();
    };

    const operation = isNew ? "Add" : "Edit";
    const title = type.metadata?.title ? type.metadata.title(isNew) : `${operation} ${type.metadata.name.singular}`;

    const warning = typeof model?.getStatusWarning === "function" ? model.getStatusWarning() : null;

    return (
      <>
        <Modal isOpen={isOpen} onRequestClose={tryClose} zoom={1} width={width} maxWidth={width}>
          <ModalHeader title={title} onClosePressed={tryClose} />
          {loading ? (
            <Loading>Cargando...</Loading>
          ) : (
            <>
              <ModalContent
                model={model}
                onChange={(x) => {
                  onChange(x);
                }}
                {...props}
              />
              {(hasAttachments || hasComments) && (
                <ModalBody>
                  <Common.Grid cols="2fr minmax(1em, 1fr)" rows="auto auto" gap="2em">
                    {hasComments && model?.comments ? (
                      <Common.Col rows="auto" rowSpan={2} gap="1em">
                        <Common.Row cols="auto auto" gap="1em">
                          ACTIONS
                          <Link
                            onClick={(x) =>
                              onChange((m) => ({
                                ...m,
                                comments: addComment(m.comments, { ...buildComment({ user, text: "" }), isNew: true }),
                              }))
                            }
                          >
                            ADD
                          </Link>
                        </Common.Row>
                        <ListOfComments
                          model={model}
                          onChange={(x) => {
                            onChange(x);
                          }}
                        />
                      </Common.Col>
                    ) : (
                      <div />
                    )}
                    {hasAttachments && (
                      <Common.Col rows="auto" maxWidth="100%">
                        ATTACHMENTS
                        <div>&nbsp;</div>
                        <FirebaseStorageAttachments model={model} code={code} onChange={onChange} />
                      </Common.Col>
                    )}
                  </Common.Grid>
                </ModalBody>
              )}
            </>
          )}
          {!loading && (
            <ModalFooter>
              <Link onClick={shareLink}>Copy link to {type.metadata.name.singular}</Link>
              <Button onClick={(x) => tryClose()} type="secondary">
                Cancel
              </Button>
              {warning && warning.severity !== "warning" && (
                <Tag style={{ fontSize: "0.8em" }} severity={warning.severity}>
                  {" "}
                  {warning.message}{" "}
                </Tag>
              )}
              <Button onClick={(x) => save()} disabled={warning && warning.severity === "critical"}>
                Save
              </Button>
            </ModalFooter>
          )}
          <SaveDiscardModal
            isOpen={isConfirmOpen}
            onRequestClose={() => setIsConfirmOpen(false)}
            onDiscard={() => {
              setIsConfirmOpen(false);
              onRequestClose && onRequestClose();
            }}
            onSave={() => {
              setIsConfirmOpen(false);
              save();
            }}
          />
        </Modal>
      </>
    );
  };

export const withInMemoryModal =
  (ModalContent, type, size, args) =>
  ({ loading, model, isNew, onChange, isOpen, onRequestClose, onAccept, onAction, onRemove, ...props }) => {
    const width = sizes[size] || sizes["md"];

    const operation = isNew ? "Add" : "Edit";
    const title = type.metadata?.title ? type.metadata.title(isNew) : `${operation} ${type.metadata.name.singular}`;

    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} zoom={1} width={width} maxWidth={width}>
        <ModalHeader title={title} onClosePressed={onRequestClose} />
        {loading ? <Loading>Cargando...</Loading> : <ModalContent model={model} onChange={(x) => onChange?.(x)} {...args} {...props} />}
        {!loading && (
          <StyledModalFooter actions={type.metadata.actions.filter((x) => x.applies(model, isNew)) || []}>
            {type.metadata?.canRemove ? (
              <Button color="white" background="#800" onClick={(x) => onRemove?.()}>
                Delete
              </Button>
            ) : (
              <div />
            )}
            <div />
            <Button type="secondary" onClick={(x) => onRequestClose?.()}>
              Cancel
            </Button>

            {type.metadata?.actions &&
              type.metadata.actions
                .filter((x) => x.applies(model, isNew))
                .map((a) => (
                  <Button key={a.code} onClick={(x) => onAction?.(a.code)}>
                    {a.name}
                  </Button>
                ))}
            <Button onClick={(x) => onAccept?.()}>{isNew ? "Create" : "Update"}</Button>
          </StyledModalFooter>
        )}
      </Modal>
    );
  };

export const withModal =
  (ModalContent, title, buttonTitle, size, options, args) =>
  ({ loading, model, onChange, isOpen, onRequestClose, onAccept, onAction, onRemove, ...props }) => {
    const width = sizes[size] || sizes["md"];
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} zoom={1} width={width} maxWidth={width}>
        <ModalHeader title={title} onClosePressed={onRequestClose} />
        {loading ? <Loading>Cargando...</Loading> : <ModalContent model={model} onChange={(x) => onChange?.(x)} {...args} {...props} />}
        {!loading && (
          <StyledModalFooter actions={options?.actions?.filter((x) => x.applies(model)) || []}>
            {options?.canRemove ? (
              <Button color="white" background="#800" onClick={(x) => onRemove?.()}>
                Delete
              </Button>
            ) : (
              <div />
            )}
            <div />
            <Button type="secondary" onClick={(x) => onRequestClose?.()}>
              Cancel
            </Button>

            {options?.actions &&
              options?.actions
                .filter((x) => x.applies(model))
                .map((a) => (
                  <Button key={a.code} onClick={(x) => onAction?.(a.code)}>
                    {a.name}
                  </Button>
                ))}
            <Button onClick={(x) => onAccept?.(model)}>{buttonTitle ?? "Accept"}</Button>
          </StyledModalFooter>
        )}
      </Modal>
    );
  };

const Loading = styled.div`
  padding: 10em;
  color: #999;
`;

const MessageContainer = styled.div`
  padding: 1em 0;
`;

const Message = styled.div`
  padding: 0.3em 0;
  font-size: 1.2em;
  color: #444;
  text-align: center;
`;

const StyledModalFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr repeat(${(props) => props.actions.length + 2}, auto);
  padding: 0px 2.375em 1.25em 3.1875em;
  background: white;
  grid-gap: 1em;
`;

const Link = styled(Common.Link)`
  &&& {
    margin-right: 30px;
    font-size: 0.7em;
    text-transform: uppercase;
  }
`;

const Tag = styled.div`
  display: inline-block;
  font-size: 0.6em;
  font-weight: ${(props) => props.fontWeight || "700"};
  padding: 0.4em 0.6em;
  border-radius: 0.4em;
  background: ${(props) =>
    props.color ? props.color : props.severity === "warning" ? "#ec8" : props.severity === "critical" ? "red" : "orange"};
  color: white;
  text-transform: uppercase;
`;
