import Amount from "../components/Amount";
import { FollowUpTag } from "../components/FollowUp";
import { formatPercentage, merge } from "../lib/misc";
import { Common } from "@lainco/react-toolbox";
import Quotation from "../model/Quotation";
import { addPresentation } from "./presentation";

export default addPresentation(Quotation, {
  sort: (a, b) => a?.category?.name?.localeCompare(b?.category?.name) || a?.name?.localeCompare(b?.name),
  canAdd: true,
  canEdit: true,
  canRemove: false,
  singular: "Quotation",
  plural: "Quotations",
  columns: [
    {
      title: "Company",
      align: "center",
      content: { projectors: (x) => merge(x?.company?.code, x?.beneficiary?.code) },
    },
    { title: "Customer", content: { name: "customer.shortName" } },

    { title: "Category", content: { name: "category.name" } },
    { title: "ID", content: { projectors: (x) => <div style={{ minWidth: "7em" }}>{x.quotationId}</div> } },
    {
      title: "Name",
      content: {
        projectors: (x) => (
          <Common.Col>
            <span
              style={{
                color: "#fafafa",
                background: "#bbb",
                width: "fit-content",
                padding: "0.1em 0.5em",
                marginBottom: "0.3em",
                borderRadius: "0.3em",
                marginLeft: "-0.5em",
              }}
            >
              {x.project?.name}
            </span>
            <span>{x.name}</span>
          </Common.Col>
        ),
      },
    },
    { title: "Status", content: { projectors: (x) => (x?.getStatus ? x.getStatus() : "-") } },
    {
      title: "Amount",
      align: "right",
      content: {
        projectors: (x, c) => {
          return <Amount precision={0} currency={x.amount?.currency} amount={x.getFinalAmount()} />;
        },
      },
    },
    {
      title: "Discount",
      align: "right",
      content: {
        projectors: (x, c) => {
          return x.getFinalDiscount() > 0 ? formatPercentage(x.getFinalDiscount()) : "-";
        },
      },
    },
    {
      title: "Follow Up",
      width: "15%",
      content: {
        projectors: (x) =>
          x?.followUp?.hasFollowup && x?.followUp?.dueDate ? (
            <FollowUpTag
              style={{ fontSize: "0.7em" }}
              hasFollowup={true}
              dueDate={x.followUp.dueDate}
              maxLen={50}
              nextTask={x.followUp.nextTask}
            />
          ) : (
            "-"
          ),
      },
    },
    /*
    {
      title: "Adjustment Clause",
      align: "center",
      content: {
        projectors: (x, c) => {
          const value = x?.getShownAdjustementClause();
          if (value === "-" || c.prices == null || c.clauses == null) return value;
          if (x.adjustmentClauseTrigger?.code === "AUTO")
            return (
              <Tag fontSize="1.5em" color="#999">
                {value}
              </Tag>
            );

          const clause = c.clauses.find((t) => x.adjustmentClause.id === t.id);
          const [price] = getPrice(c.prices, clause?.currency, clause?.priceType);
          const capOk = price == null || x?.adjustmentClauseCap == null || parseFloat(x.adjustmentClauseCap) >= price;
          const floorOk = price == null || x?.adjustmentClauseFloor == null || parseFloat(x.adjustmentClauseFloor) <= price;
          if (!capOk || !floorOk)
            return (
              <Tag fontSize="1.5em" color={floorOk ? "red" : "orange"}>
                {value}
              </Tag>
            );
          return (
            <Tag fontSize="1.5em" color="#8ca">
              {value}
            </Tag>
          );
        },
      },
    },*/
  ],
});
