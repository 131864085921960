import React, { useState } from "react";
import { Common, icons, TextArea, Button } from "@lainco/react-toolbox";
import styled from "styled-components";
import { format, formatISO, parseISO } from "date-fns";
import { uid } from "../lib/id";

export function buildComment({ user, text }) {
  return { id: uid(), text, user, timestamp: formatISO(new Date()) };
}

export function addComment(comments, comment) {
  return [comment, ...(comments || [])];
}

export function removeComment(comments, id) {
  const index = comments?.findIndex((c) => c.id === id);
  if (index != null) return [...comments.slice(0, index), ...comments.slice(index + 1)];
  else return comments;
}

export function updateComment(comments, id, text) {
  const index = comments?.findIndex((c) => c.id === id);
  if (index != null) return [...comments.slice(0, index), { ...comments[index], isNew: false, text }, ...comments.slice(index + 1)];
  else return comments;
}

export function PureComments({ id, user, timestamp, text, isNew, onEdit, onRemove, onCancel }) {
  const [editing, setEditing] = useState(isNew ? true : false);
  const [editedText, setEditedText] = useState();

  const t = typeof timestamp === "object" ? timestamp : parseISO(timestamp);

  return (
    <Common.Grid
      cols="auto 1fr"
      rows="auto 1fr"
      gap="1em"
      align="center"
      style={{ borderBottom: editing ? "none" : "1px solid #eee", paddingBottom: "0.5em" }}
    >
      <Icon>
        <Image src={user?.photo} />
      </Icon>
      <Common.Row cols="1fr auto auto" gap="1em" alignItems="center">
        <div>
          <Name>{user?.name}</Name> <Timestamp>{format(t, "dd/MM/yyyy hh:mm")}</Timestamp>
        </div>
        {!editing ? (
          <Action
            onClick={(x) => {
              setEditedText(text);
              setEditing(true);
            }}
          >
            {icons.pencilAlt({ style: { color: "#1489a2" } })}
          </Action>
        ) : (
          <Button
            type="cancelDisabled"
            onClick={(x) => {
              setEditing(false);
              onCancel && onCancel(id);
            }}
          >
            Cancel
          </Button>
        )}
        {!editing ? (
          <Action
            onClick={(x) => {
              onRemove && onRemove(id);
            }}
          >
            {icons.trash({ style: { color: "#1489a2" } })}
          </Action>
        ) : (
          <Button
            type="cancel"
            onClick={(x) => {
              onEdit && onEdit(editedText);
              setEditing(false);
            }}
          >
            Save
          </Button>
        )}
      </Common.Row>
      <div />
      {editing ? (
        <Content>
          <TextArea
            onBlur={(x) => {
              onEdit && onEdit(editedText);
              setEditing(false);
            }}
            style={{ fonSize: "0.8em" }}
            value={editedText}
            onChange={setEditedText}
          />
        </Content>
      ) : (
        <Content>
          {text?.split("\n")?.map((x) => (x != null && x.trim() !== "" ? <p>{x}</p> : <p style={{ fontSize: "0.5em" }}>&nbsp;</p>))}
        </Content>
      )}
    </Common.Grid>
  );
}

export const Comment = PureComments;

export function ListOfComments({ model, onChange }) {
  return (
    <Common.Col rows="auto" rowSpan={2} gap="1em">
      {model?.comments?.map((c) => {
        return (
          <Comment
            key={c.id}
            {...c}
            onCancel={(id) => c.isNew && onChange && onChange((m) => ({ ...m, comments: removeComment(m.comments, id) }))}
            onEdit={(t) => onChange && onChange((m) => ({ ...m, comments: updateComment(m.comments, c.id, t) }))}
            onRemove={(id) => onChange && onChange((m) => ({ ...m, comments: removeComment(m.comments, id) }))}
          />
        );
      })}
    </Common.Col>
  );
}

const Icon = styled.div``;

const Image = styled.img`
  height: 1.5em;
  border-radius: 1em;
`;

const Name = styled.span`
  color: #444;
`;

const Content = styled.div`
  font-size: 0.8em;
  font-weight: 400;
  color: #777;
  line-height: 1.2;
`;

const Timestamp = styled.span`
  margin-left: 1em;
`;

const Action = styled.div`
  & {
    display: none;
    font-size: 0.9em;
  }
  ${Common.Grid}:hover & {
    display: block;
    cursor: pointer;
  }
`;
