const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.index = document?.index;
    model.date = document?.date;
    model.value = document?.value;
  },
  code: "indexValues",
  singular: "Index Value",
  plural: "Index Values",
  sortBy: (x) => x.date,
  sortDirection: "desc",
});
