import { Common, Table, daimlerPalette } from "@lainco/react-toolbox";
import { format, startOfToday } from "date-fns";
import React, { useState } from "react";
import { useItems } from "../app/browsersSlice";
import { useCandidates } from "../app/candidatesSlice";
import { sellOrderMilestone } from "../model/status";
import Amount from "../components/Amount";
import { FilteredContainer } from "../components/FilteredContainer";
import Filter, { ButtonOptionFilter, SelectOptionFilter } from "../components/v2/Filter";
import { buildOptions } from "../lib/misc";
import {
  useAccountManagerFilter,
  useAdjustmentClauseFilter,
  useBeneficiaryFilter,
  useCategoryFilter,
  useCompanyFilter,
  useCurrencyFilter,
  useCustomerFilter,
  useFilter,
  useFlagsFilter,
  useProjectLeaderFilter,
} from "./SellOrderMilestonesPage";
import styled from "styled-components";
import { Bar, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { addDays, startOfMonth } from "date-fns/esm";
import { buildBuckets, getBucket } from "../lib/buckets";

const buckets = buildBuckets({
  today: startOfToday(),
  days: 5,
  weeks: 3,
  months: 3,
  quarters: 2,
  years: 1,
});

const useFilterOn = ({ title, name, state, filter, type, options, optionsLoading }) => {
  const component =
    type === "button" ? (
      <ButtonOptionFilter header={title} name={name} options={options} optionsLoading={optionsLoading} />
    ) : (
      <SelectOptionFilter header={title} name={name} options={options} optionsLoading={optionsLoading} />
    );
  return { state: { [name]: state }, filter, component };
};

export default function CashflowPage() {
  const start = startOfMonth(addDays(startOfToday(), -7)).getTime();
  const [items, itemsLoading] = useItems("sellOrderMilestones");
  const [currencies] = useCandidates("currencies");
  const [prices] = useCandidates("prices");
  const ars = currencies?.find((c) => c.code === "ARS");
  const groupBy = buildOptions(["name"], ["Impediment", "Status", "Category", "Company", "Beneficiary", "Customer", "Currency"]);
  const ranges = buildOptions(["name"], ["Current", "Historic"]);
  const dates = buildOptions(["name"], ["Exp. Ok", "Real Ok", "Settlement", "Settlment - 5"]);
  const periods = buildOptions(["name"], ["Month", "Custom"]);
  const valuesIn = [
    { id: "ars", name: "ARS" },
    { id: "usd", name: "USD" },
  ];
  const {
    initialState: row1InitialState,
    filterComponents: row1FilterComponents,
    filterFunction: row1FilterFunction,
  } = useFilter(
    useFilterOn({
      title: "Range",
      name: "range",
      state: 1,
      filter: (filter, item) => filter.range === 2 || getDate(item)?.getTime() >= start,
      type: "button",
      options: ranges,
      optionsLoading: false,
    }),

    useFilterOn({
      title: "Group Periods by",
      name: "period",
      state: 1,
      filter: (filter, item) => true,
      type: "button",
      options: periods,
      optionsLoading: false,
    }),
    useCompanyFilter("button"),
    useBeneficiaryFilter("button"),
    useCategoryFilter(),
    useCustomerFilter(),
    useAccountManagerFilter(),
    useProjectLeaderFilter(),
    useAdjustmentClauseFilter(),
    useFlagsFilter(),
    useCurrencyFilter()
  );

  const {
    initialState: row2InitialState,
    filterFunction: row2FilterFunction,
    filterComponents: row2FilterComponents,
  } = useFilter(
    useFilterOn({
      title: "By Date",
      name: "date",
      state: 3,
      filter: (filter, item) => true,
      type: "button",
      options: dates,
      optionsLoading: false,
    }),
    useFilterOn({
      title: "Group By",
      name: "groupBy",
      state: 1,
      filter: (filter, item) => true,
      type: "button",
      options: groupBy,
      optionsLoading: false,
    }),
    useFilterOn({
      title: "Show As",
      name: "asCurrency",
      state: "ars",
      filter: (filter, item) => true,
      type: "button",
      options: valuesIn,
      optionsLoading: false,
    })
  );

  const [filterState, setFilterState] = useState({
    ...row1InitialState,
    ...row2InitialState,
  });
  const group = groupBy.find((g) => g.id === filterState.groupBy);
  const currency = currencies?.find((c) => c.code === valuesIn.find((g) => g.id === filterState.asCurrency).name);
  const getDate =
    filterState.date === 1
      ? // Expected Ok
        (item) => item.expectedOk
      : filterState.date === 2
      ? // Real Ok
        (item) => item.ok || item.expectedOk
      : filterState.date === 3
      ? // Settlement
        (item) => {
          const eta = item?.getExpectedSettlementDate();
          return eta == null || (eta.getTime() < startOfToday() && item.status !== "settled") ? startOfToday() : eta;
        }
      : // Settlement - 5
        (item) => {
          const eta = item?.getExpectedSettlementDate();
          const settlement = eta == null || (eta.getTime() < startOfToday() && item.status !== "settled") ? startOfToday() : eta;
          const ret = addDays(settlement, -5);
          return ret;
        };

  return (
    <FilteredContainer
      title={`By ${group.name} (${currency?.code})`}
      items={items}
      filterState={filterState}
      itemsLoading={itemsLoading}
      filter={(filter, item) => {
        return item.status !== "canceled" && row1FilterFunction(filter, item) && row2FilterFunction(filter, item);
      }}
      FiltersRow1={
        <Filter fontSize="0.45em" model={filterState} onChange={setFilterState}>
          <div />
          {row2FilterComponents}
        </Filter>
      }
      FiltersRow2={
        <Filter fontSize="0.45em" model={filterState} onChange={setFilterState}>
          <div />
          {row1FilterComponents}
        </Filter>
      }
      rowFunction={(item) => {
        const date = getDate(item);

        if (date == null) return { key: "-", name: "-" };

        if (filterState.period === 1)
          return {
            key: format(date, "yyyyMM"),
            name: format(date, "MMM yyyy"),
          };
        else if (filterState.period === 2) return getBucket(buckets, date);
      }}
      columnFunction={(item) =>
        group.id === 1
          ? {
              key: item?.status === "settled" ? 3 : item?.hasFlag ? 2 : 1,
              name: item?.status === "settled" ? "Settled" : item?.hasFlag ? "Pending w/impediment" : "Pending Ok",
              color: item?.status !== "settled" && item?.hasFlag ? "red" : null,
            }
          : group.id === 2
          ? {
              key: sellOrderMilestone[item?.status]?.key,
              name: sellOrderMilestone[item?.status]?.name,
            }
          : group.id === 3
          ? {
              key: item?.sellOrder?.category?.name,
              name: item?.sellOrder?.category?.name,
            }
          : group.id === 4
          ? {
              key: item?.sellOrder?.company?.code,
              name: item?.sellOrder?.company?.code,
            }
          : group.id === 5
          ? {
              key: item?.sellOrder?.beneficiary?.code,
              name: item?.sellOrder?.beneficiary?.code,
            }
          : group.id === 6
          ? {
              key: item?.sellOrder?.customer?.id,
              name: item?.sellOrder?.customer?.shortName,
            }
          : {
              key: item?.sellOrder?.amount?.currency?.code,
              name: item?.sellOrder?.amount?.currency?.name,
              color: item?.sellOrder?.amount?.currency?.color,
            }
      }
      ars={ars}
      prices={prices}
      asCurrency={currency}
      getDate={(item) => {
        const eta = item?.getExpectedSettlementDate();
        return eta == null || (eta.getTime() < startOfToday() && item.status !== "settled") ? startOfToday() : eta;
      }}
      getValue={(item) => item?.getAmount()}
      getCurrency={(item) => item?.sellOrder?.amount?.currency}
      content={({ matrix, totals }) => {
        return (
          <Common.Col rows="auto auto">
            <ChartContainer>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={400}
                  data={totals?.portait.filter((x) => x.name !== "Total")}
                  margin={{
                    top: 20,
                    right: 10,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <XAxis dataKey="name" fontSize="0.7em" />
                  <YAxis tickFormatter={(x) => (x > 1000000 ? (x / 1000).toLocaleString() + "k" : x.toLocaleString())} fontSize="0.7em" />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend fontSize="0.7em" />
                  {matrix?.columnHeaders
                    ?.filter((x) => x.key !== "_total")
                    .map((x, i) => (
                      <Bar
                        displayName={x.name}
                        key={x.key}
                        dataKey={x.key + ".amount"}
                        name={x.name}
                        stackId="a"
                        barSize={30}
                        fill={x.color || daimlerPalette.values[i]}
                      />
                    ))}
                </ComposedChart>
              </ResponsiveContainer>
            </ChartContainer>

            <div style={{ marginLeft: "5em", marginRight: "5em" }}>
              <Table
                items={totals?.portait}
                columns={[
                  {
                    title: "",
                    content: { name: "name" },
                    width: `${100 / matrix?.columnHeaders?.length}%`,
                  },
                  ...(matrix
                    ? matrix?.columnHeaders?.map((col) => ({
                        title: col.name,
                        width: `${100 / matrix?.columnHeaders?.length}%`,
                        content: {
                          projectors: (x) => <Amount precision={0} amount={x[col.key]?.amount} currency={x[col.key]?.currency} />,
                        },
                      }))
                    : []),
                ]}
              />
            </div>
          </Common.Col>
        );
      }}
    />
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "#fff",
          border: "1px solid black",
          color: "#444",
          opacity: "1",
          padding: "1em",
          fontSize: "0.8em",
        }}
      >
        <div
          style={{
            marginBottom: "0.5em",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {payload[0].payload.period}
        </div>
        {payload.map((x, i) => (
          <div key={i} style={{ marginBottom: "0.5em", textAlign: "right" }}>
            {x.name}:{" "}
            {x.value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const ChartContainer = styled.div`
  width: 100%;
  height: 27em;
  padding-right: 5em;
  padding-top: 2em;
  padding-bottom: 2em;
`;
