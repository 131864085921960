const buildType = require("./models");

module.exports = buildType({
  initializer: (model, document) => {
    model.code = document?.code ?? "";
    model.name = document?.name;
  },
  code: "adjustmentTypeTriggers",
  singular: "Adjustment Type Trigger",
  plural: "Adjustment Type Triggers",
});
